import React, { useEffect } from "react";

// ----> Imports for navigations
import { Navigate, Outlet, useLocation } from "react-router-dom";


// ----> Components importations
// import { SideBarMenu } from "../../components/SideBarMenu/SideBarMenu";
import { TopNavbarMobile } from "../../components/top-searchbar-mobile/TopNavbarMobile";
import { FooterComp } from "../../components/serviceComponents/FooterComp";

export const ProtectedRoutes = () => {

  // const navigate = useNavigate();

  // GET useLocation
  const location = useLocation();

  // rutas en las que no se ve la navegacion
  const REPORTS_VIEWS = [
    "/administration/report-consumption-request",
    "/commercial/reports-view/open-calls-report"
  ];


  // rutas en las que se puede visualizar el footer comp
  // const FOOTER_VIEWS = [
  //   "/service",
  //   "/logistic-principal"
  // ];


  const isLogged = localStorage.getItem("userInfo") === null ? false : true; //luego colocarlo con un localStorage.getItem("clave");
  // console.log(isLogged)
  // !isLogged


  if (!isLogged) {
    return <Navigate to="/" />;
  }
  // const isLogged = localStorage.getItem("userInfo") === null ? true : false;

  // if (!isLogged) {
  //   return navigate("/");
  // }

  // useEffect(() => {


  // }, [navigate]);

  return (
    <>
      <div className="grid-wrapper">
        {
          !REPORTS_VIEWS.includes(location.pathname) && (
            <TopNavbarMobile />
          )
        }
        {/* {
          FOOTER_VIEWS.includes(location.pathname) && (<FooterComp />)
        } */}
        <Outlet />
        {/* <SideBarMenu /> */}
      </div>
      {/* <BottonBarMenu /> */}
    </>
  )

};