// componente para cargar documentos de una herramienta, foto y certificados de calibracion

import { useState, useContext } from "react";



// import notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions.js";
import { useTranslation } from "react-i18next";



export const UploadDocsTools = ({ onClose, onReload, CodHerr = "", lang = "" }) => {

  // state definition
  const [file, setFile] = useState(null); // estado para el archivo
  const [form, setForm] = useState({ nombre: "", doc: "" }); // formulario


  // get the react translation
  const [t] = useTranslation("global");


  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);


  // funcion para modificar el formulario
  const handleChangeForm = ({ target }) => {
    const { value, name } = target;
    setForm((val) => ({ ...val, [name]: value }));
  };


  // funcion para cargar el archivo en el estado
  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    setFile(file);
  };



  // funcion para enviar la informacion al servidor
  const handleUpload = async (event) => {
    event.preventDefault();


    try {

      if (file) {

        if (!form.doc) {
          handleNotify(true, t("serviceCard.indicar_archivo"), true);
        } else {

          const formData = new FormData();

          formData.append("file", file);

          const response = await fetch(`${urls.tools}/${CodHerr}/search?language=${lang}&action=crt-3&doc=${form.doc}&nom_doc=${form.nombre}`, {
            method: "POST",
            headers: {
              "Authorization": getTheToken().token
            },
            body: formData
          });

          const data = await response.json();

          if ([201, 200].includes(response.status)) {
            handleNotify(true, data?.msg, false, true);
            if (form.doc === "phot") {
              // recargar data
              onReload();
            }
            setFile(null);
            onClose();
          } else if (response.status === 404) {
            handleNotify(true, data?.msg, true);
          }

        } // end else

      } else {
        // no hay archivo
        handleNotify(true, t("serviceCard.no_archivo"), true);
      }

    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div className="background-modal">
      <div className="container-modal-upload-docs">
        <form onSubmit={handleUpload}>
          <div className="row-flex">
            <div className="col-6">
              <strong>{t("ToolsView.doc_soport")}</strong>
            </div>
            <div className="col-6">
              <span className="txt-muted">PDF | JPG</span>
            </div>
          </div>
          <div className="row-flex">
            <div className="col-6">
              <strong>{t("ToolsView.doc_subir")}</strong>
            </div>
            <div className="col-6 text-hidden-over">
              <input
                type="file"
                name="file"
                accept=".pdf, .jpg"
                onChange={handleFileChange} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-6">
              <strong>{t("ToolsView.doc")}</strong>
            </div>
            <div className="col-6">
              <select
                name="doc"
                value={form.doc}
                onChange={handleChangeForm}>
                <option value=""></option>
                <option value="phot">{t("ToolsView.foto")}</option>
                <option value="cert">{t("ToolsView.certi_cali")}</option>
              </select>
            </div>
          </div>

          {
            form.doc === "cert" && (
              <div className="row-flex fade-animation">
                <div className="col-6">
                  <strong>{t("ToolsView.nombre")}</strong>
                </div>
                <div className="col-6">
                  <input
                    className="input-text-control"
                    type="text"
                    name="nombre"
                    value={form.nombre}
                    onChange={handleChangeForm} />
                </div>
              </div>
            )
          }

          <div className="row-flex j-content-space-around m-y-small">
            <button type="submit" className="btn-primary">{t("ToolsView.subir")}</button>
            <button type="reset" className="btn-secondary" onClick={onClose}>{t("RequestPartsView.cerrar")}</button>
          </div>
        </form>
      </div>
    </div>
  );
};