// componente para mostrar los contactos de un cliente

// react imports
import { useEffect, useState } from "react";


// components import
import { ModifyContactCustomer } from "./ModifyContactCustomer";
import { ToastNotification } from "../toast-notification/ToastNotification";
import { DeleteContactComp } from "./DeleteContactComp";
import { CreateContactComp } from "./CreateContactComp";



import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";
import { Link, useNavigate } from "react-router-dom";

// react translation import
import { useTranslation } from "react-i18next";







export const ContactsComp = ({ idCus, lang }) => {

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: "", codContac: 0, codCli: "", modalModi: false, modalDelete: false, modalCreate: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, success: false, great: false });


  // destructuring data server
  const { contacts, empPerm } = dataServ && dataServ;



  // funcion para abrir el modal de modificar los contactos
  const onHandleModify = (codContac = 0, codCli = "") => {
    setState((val) => ({ ...val, codContac, codCli, modalModi: true }));
  };


  // funcion para cerrar el modal de modificar contactos
  const onHandleCloseModify = () => {
    setState((val) => ({ ...val, modalModi: false }));
  };

  // funcion para cerrar el modal de delete
  const onCloseDelete = () => {
    setState((val) => ({ ...val, modalDelete: false, codContac: 0 }));
  };

  // funcion para cerrar el modal de crear
  const onCloseCreate = () => {
    setState((val) => ({ ...val, modalCreate: false }));
  };


  // funcion para notificar desde los componentes hijos a los padres
  const onNotifyComp = (msg = "", error = false, success = false, great = false) => {
    setNotification((val) => ({ ...val, show: true, msg, error, success, great }));
  };



  // funcion para traer los contactos del cliente
  const fetchDataContacts = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.getCustomer}/contacts/customer/${idCus}/search?language=${lang}`;

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true, dataVisible: false }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true, msg: "" }));
        setDataServ(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setDataServ(data);
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {
    fetchDataContacts();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idCus, lang]);


  // useEffect para recargar la data del servidor
  useEffect(() => {
    if (notification.success) {

      fetchDataContacts();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [notification.success]);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, success: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [notification.show]);



  return (
    <>
      {
        notification.show && (
          <ToastNotification success={notification.success} error={notification.error} msg={notification.msg} />
        )
      }
      <div className="row-flex">
        <div className="col-7">
          <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">Contactos</h4>
        </div>
        <div className="col-9" style={{ display: "flex", alignItems: "center" }}>
          <button
            style={{ padding: "2px", cursor: "pointer" }}
            title="Agregar contacto"
            type="button"
            onClick={() => setState((val) => ({ ...val, modalCreate: true }))}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="var(--first-color)" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
              <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
              <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
            </svg>
          </button>
        </div>
        <h5 className="row-flex align-c">{contacts && contacts.length} {t("customerView.contacts")}</h5>
      </div>

      {
        // modal para crear un contacto
        state.modalCreate && (
          <CreateContactComp onClose={onCloseCreate} idCus={idCus} lang={lang} token={getTheToken().token} empPerm={empPerm} onNotify={onNotifyComp} />
        )
      }

      {
        // modal para eliminar un contacto
        state.modalDelete && (
          <DeleteContactComp onClose={onCloseDelete} onNotify={onNotifyComp} codCon={state.codContac} lang={lang} token={getTheToken().token} />
        )
      }

      {
        // msg error
        state.msg && (<h5 style={{ color: "var(--danger-color)" }}>{state.msg}</h5>)
      }

      {
        // abrir el modal de modificar los contactos
        state.modalModi && (<ModifyContactCustomer onClose={onHandleCloseModify} codContacto={state.codContac} codCliente={state.codCli} />)
      }

      {
        state.isLoading && (<h5>{t("customerView.cargando_contac")}</h5>)
      }

      {
        state.dataVisible && (
          <div className="table-container-mobile">
            <table className="table">
              <thead className="table-head">
                <tr>
                  {
                    empPerm && [3, 6].includes(empPerm[0]?.CodPerfil) && (
                      <th></th>
                    )
                  }
                  <th>{t("customerView.nombre_contac")}</th>
                  <th>{t("customerView.cargo")}</th>
                  <th>{t("customerView.telephone")}</th>
                  <th>Principal</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  contacts && contacts.map((con) => (
                    <tr key={con.CodContacto}>
                      {
                        empPerm && [3, 6].includes(empPerm[0]?.CodPerfil) && (
                          <td className="txt-center">
                            <button type="button" className="btn-delete" onClick={() => setState((val) => ({ ...val, codContac: con.CodContacto, modalDelete: true }))}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                              </svg>
                            </button>
                          </td>
                        )
                      }
                      <td>
                        <Link className="link-tag" onClick={() => onHandleModify(con.CodContacto, idCus)}>{con.NomContacto}</Link>
                      </td>
                      <td className="txt-center">{con.Cargo}</td>
                      <td style={{ textAlign: 'center', color: 'blue', textDecoration: 'underline' }}>{con.CodCelular} {con.Celular}</td>
                      <td className="txt-center">{con.principalLabel}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )
      }
    </>
  );
};