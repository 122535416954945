
import React, { useEffect, useState, useContext } from "react";


// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";

// REACT ROUTER IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls.js";

// global functions
import { getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions.js";

// react translations
import { useTranslation } from "react-i18next";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";



export const OrdTransitWaitingDispatch = () => {


  // GET the language context
  const { lang } = useContext(LangContext);

  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);


  // GET the react translation
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // get params
  const { codSolRepuesto } = useParams();


  // STATE DEFINITION
  const [stateSol, setStateSol] = useState([]); // estado cargar el solRepuesto del servidor
  const [stateDetSolRep, setStateDetSolRep] = useState([]); // estado para cargar las partes
  const [controller, setController] = useState(null); // estado para cargar el controlador para abortar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, unauthorized: false, showModalConfir: false, questionAction: "", sending: false, dataVisible: false });
  const [form, setForm] = useState({
    tecEnviar: "", enviarPor: "", contactoEnv: "", nomContacto: "", telefono: "", enviarAl: "", fecEntregaRequerida: "",
    turnoEntrega: "", comentario: "", primaryAction: 0
  });


  // funcion para cambiar los valores del formulario
  const handleChangeForm = (event) => {
    const { value, name } = event.target;

    setForm((val) => ({ ...val, [name]: value }));

  };

  // funcion para cerrar el componente de confirmar
  const handleOpenClose = () => {
    setState((val) => ({ ...val, showModalConfir: !state.showModalConfir }));
  };


  // funcion para abrir el modal al dar click en guardar
  const onHandleOpenModalSave = (act = 0) => {

    if (form.enviarPor === "" && act === 0) {
      return handleNotify(true, "Hola", true);
    }

    const Msg = {
      0: t("WareHouseView.seguro_enviar_not_entr"),
      1: "Está seguro que desea enviar a stock"
    };

    console.log("action: ", act);

    setForm((val) => ({ ...val, primaryAction: act }));
    setState((val) => ({ ...val, showModalConfir: true, questionAction: Msg[act] }));
  };



  // funcion para modificar los valores de las piezas
  const onHandleChangeVal = (newVal = "", codDet = 0, codSol = 0) => {
    if (isNaN(newVal) || Number(newVal) < 0) {
      return;
    }

    setStateDetSolRep((val) => {
      return val.map((det) => {
        if (codDet === det.CodDetSolRepuesto && codSol === det.CodSolRepuesto) {
          return { ...det, Despachar: newVal };
        }
        return det;
      });
    });
  };




  // funcion para realizar la peticion al servidor
  const fetchDataServer = async () => {

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.solDespacho}/${codSolRepuesto}/search?language=${lang}`, {
        headers: { "Authorization": getTheToken().token },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setForm((val) => ({ ...val, tecEnviar: data?.solRepuesto[0]?.CodEmpIngreso }));
        setStateSol(data?.solRepuesto);
        setStateDetSolRep(data?.detSolRepuesto);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }))
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [codSolRepuesto, lang]);





  // useEffect para buscar el contacto cuando se seleccione
  useEffect(() => {
    if (stateSol && stateSol[0]?.contactos.length > 0) {
      const foundContact = stateSol[0]?.contactos.find((con) => con.CodContacto.toString() === form.contactoEnv);

      let numContact = "";

      if (foundContact) {
        if (foundContact?.Celular.trim() !== "") {
          numContact = foundContact?.Celular.trim();
        } else {
          numContact = foundContact?.Telefono.trim();
        }

        if (form.enviarPor === "3") {
          let accion = stateSol[0]?.CodZona === 1 ? `Se solicita enviar piezas al cliente, atención ${foundContact?.Nombre}, Nro. Teléfono ${numContact}` : `Requesting to send parts to the client, attention to ${foundContact?.Nombre}, Phone number ${numContact}`;
          setForm((val) => ({ ...val, comentario: accion }));
        }

        setForm((val) => ({ ...val, nomContacto: foundContact?.Nombre, telefono: numContact }));

      } else {
        setForm((val) => ({ ...val, nomContacto: "", telefono: "" }));
      }

    }
  }, [form.contactoEnv]);

  // useEffect para cambiar el comentario cuando se seleccione enviar por
  useEffect(() => {
    const LABEL_TRANSPORTE_ES = form.contactoEnv ? `Se solicita enviar piezas al cliente, atención ${form.nomContacto.trim()}, Nro. Teléfono ${form.telefono.trim()}` : "Se solicita enviar piezas al cliente, atención , Nro";
    const LABEL_TRANSPORTE_EN = form.contactoEnv ? `Requesting to send parts to the client, attention to ${form.nomContacto.trim()}, Phone number ${form.telefono.trim()}` : "Requesting to send parts to the client, attention to , Phone number";

    const ENVIAR_POR = {
      0: stateSol[0]?.CodZona === 1 ? "Enviar por DHL " : "Ship by DHL ",
      1: stateSol[0]?.CodZona === 1 ? "Enviar por MRW " : "Ship by MRW ",
      2: stateSol[0]?.CodZona === 1 ? "Enviar por Fedex " : "Ship by Fedex ",
      3: stateSol[0]?.CodZona === 1 ? LABEL_TRANSPORTE_ES : LABEL_TRANSPORTE_EN
    };

    setForm((val) => ({ ...val, comentario: ENVIAR_POR[form.enviarPor] ?? "" }));
  }, [form.enviarPor]);





  // HANDLE SUBMIT
  const handleSubmit = async (event) => {
    event.preventDefault();

    const finalObj = {
      form,
      parts: stateDetSolRep
    };

    try {
      setState((val) => ({ ...val, sending: true }));

      const response = await fetch(`${urls.solDespacho}/${codSolRepuesto}/search?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true);
      } else if (response.status === 401) {
        return navigate("/");
      }

      setState((val) => ({ ...val, sending: false }));

    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="container display-data">
      {
        state.unauthorized && (<Error403View />)
      }
      {
        state.isLoading && (<LoadingComp />)
      }
      {/* FILA 1 */}
      {
        state.dataVisible && (
          <form onSubmit={handleSubmit}>
            <div className="row-flex">
              {/* NUM ORDEN */}
              <div className="col-8">
                <strong>Orden</strong>
              </div>
              <div className="col-8">
                <h5>{stateSol[0]?.NumOrden}</h5>
              </div>
              {/* ID SERVIDIO */}
              <div className="col-8">
                <strong>Id. Servicio</strong>
              </div>
              <div className="col-8">
                <Link className="link-tag" to={`/service/service-call/${stateSol[0]?.CodServicio}`}>
                  {stateSol[0]?.CodServicio}
                </Link>
              </div>
              {/* MODELO */}
              <div className="col-8">
                <strong>Modelo</strong>
              </div>
              <div className="col-8">
                <span>{ }</span>
              </div>
            </div>

            {/* FILA 2 */}
            <div className="row-flex">
              {/* SERIAL */}
              <div className="col-8">
                <strong>Serial</strong>
              </div>
              <div className="col-8">
                <span>{stateSol[0]?.Serial}</span>
              </div>
              {/* FECHA */}
              <div className="col-8">
                <strong>Fecha</strong>
              </div>
              <div className="col-8">
                <span>{renderDate(stateSol[0]?.FecIngreso, lang)}</span>
              </div>
              {/* SUPLIDOR */}
              <div className="col-8">
                <strong>Suplidor</strong>
              </div>
              <div className="col-3">
                <span>{stateSol[0]?.NomProveedor}</span>
              </div>
            </div>

            {/* FILA 3 */}
            <div className="row-flex">
              {/* INSTALACION */}
              <div className="col-8">
                <strong>Instalación</strong>
              </div>
              <div className="col-8">
                <span>{renderDate(stateSol[0]?.FecInstalacion, lang)}</span>
              </div>
              {/* FDO */}
              <div className="col-8">
                <strong>FDO</strong>
              </div>
              <div className="col-8">
                <span>{stateSol[0]?.FDO}</span>
              </div>
              {/* O/C */}
              <div className="col-8">
                <strong>O/C</strong>
              </div>
              <div className="col-8">
                <span>{stateSol[0]?.OCG}</span>
              </div>
            </div>

            {/* FILA 4 */}
            <div className="row-flex">
              {/* CLIENTE */}
              <div className="col-8">
                <strong>Cliente</strong>
              </div>
              <div className="col-82">
                <Link className="link-tag" to={`/customers/customer-detail/${stateSol[0]?.CodCliente}`}>{stateSol[0]?.NomCliente}</Link>
              </div>
            </div>

            {/* FILA 5 */}
            <div className="row-flex">
              {/* EQUIPO */}
              <div className="col-8">
                <strong>Equipo</strong>
              </div>
              <div className="col-82">
                <Link className="link-tag" to={`/equipments/equipment-detail/${stateSol[0]?.CodEquipo}`}>{stateSol[0]?.Equipo}</Link>
              </div>
            </div>

            {/* FILA 6 */}
            <div className="row-flex">
              {/* CONTRATO */}
              <div className="col-8">
                <strong>Contrato</strong>
              </div>
              <div className="col-4">
                <span>No Posee Contrato</span>
              </div>
              {/* PRIORIDAD */}
              <div className="col-8">
                <strong>Prioridad</strong>
              </div>
              <div className="col-8 row-flex align-c">
                <span className={renderTemperature(stateSol[0]?.Prioridad)}></span>
              </div>
            </div>

            {/* FILA 7 */}
            <div className="row-flex">
              {/* TECNICO */}
              <div className="col-8">
                <strong>Técnico</strong>
              </div>
              <div className="col-4">
                <span>{stateSol[0]?.NomEmpIngreso}</span>
              </div>
              {/* FALLA */}
              <div className="col-8">
                <strong>Falla</strong>
              </div>
              <div className="flex-auto">
                <span style={{ textTransform: 'capitalize', color: 'var(--danger-color)', textDecoration: 'underline' }}>
                  {stateSol[0]?.Falla}
                </span>
              </div>
            </div>

            {/* FILA 8 */}
            <div className="row-flex">
              {/* ULTIMA ACCION */}
              <div className="col-8">
                <strong>Ultima Acción</strong>
              </div>
              <div className="col-87">
                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', color: 'var(--green-color)', textDecoration: 'underline' }}>
                  {stateSol[0]?.LastComment?.toLowerCase()}
                </span>
              </div>
            </div>

            <hr className="m-y-small" />

            {/* FILA 9 */}
            <div className="row-flex">
              {/* TECNICO A ENVIAR */}
              <div className="col-8">
                <strong>Técnico a Enviar</strong>
              </div>
              <div className="col-4">
                <select
                  name="tecEnviar"
                  value={form.tecEnviar}
                  onChange={handleChangeForm}>
                  {
                    stateSol && stateSol[0]?.employees.map((emp) => (
                      <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            {/* FILA 10 */}
            <div className="row-flex">
              <div className="col-8">
                <strong>Enviar por</strong>
              </div>
              <div className="col-4">
                <select
                  name="enviarPor"
                  value={form.enviarPor}
                  onChange={handleChangeForm}>
                  <option value=""></option>
                  <option value="0">DHL</option>
                  <option value="1">MRW</option>
                  <option value="2">Fedex</option>
                  <option value="3">Por Transporte</option>
                </select>
              </div>
              {/* AL */}
              <div className="col-8">
                <strong>Al</strong>
              </div>
              <div className="col-4">
                <select
                  name="enviarAl"
                  className={form.enviarPor === "" ? "wrapper" : ""}
                  disabled={form.enviarPor === ""}
                  value={form.enviarAl}
                  onChange={handleChangeForm}>
                  <option value=""></option>
                  <option value="0">Técnico</option>
                  <option value="1">Cliente</option>
                  <option value="2">Otro</option>
                </select>
              </div>
            </div>

            {/* FILA 11 */}
            <div className="row-flex">
              {/* SELECCIONAR EL CONTACTO */}
              <div className="col-8">
                <strong>Seleccionar Contacto</strong>
              </div>
              <div className="col-4">
                <select
                  name="contactoEnv"
                  value={form.contactoEnv}
                  onChange={handleChangeForm}>
                  <option value=""></option>
                  {
                    stateSol && stateSol[0]?.contactos.map((cont) => (
                      <option key={cont.CodContacto} value={cont.CodContacto}>{cont.Nombre}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            {/* FILA 12 */}
            <div className="row-flex">
              {/* PERSONA CONTACTO */}
              <div className="col-8">
                <strong>Persona Contacto</strong>
              </div>
              <div className="col-4">
                <input
                  className="input-text-control"
                  type="text"
                  name="nomContacto"
                  value={form.nomContacto}
                  onChange={handleChangeForm}
                  autoComplete="off" />
              </div>
              {/* TELEFONO CONTACTO */}
              <div className="col-8">
                <strong>Teléfono</strong>
              </div>
              <div className="col-4">
                <input
                  className="input-text-control"
                  type="text"
                  name="telefono"
                  value={form.telefono}
                  onChange={handleChangeForm} />
              </div>
            </div>

            {/* FILA 13 */}
            <div className="row-flex">
              {/* FECHA DE ENTREGA REQUERIDA */}
              <div className="col-8">
                <strong>Fecha de entrega Requerida</strong>
              </div>
              <div className="col-4">
                <input
                  className="input-date-styles"
                  type="date"
                  name="fecEntregaRequerida"
                  value={form.fecEntregaRequerida}
                  onChange={handleChangeForm} />
              </div>
              {/* TURNO DE ENTREGA */}
              <div className="col-8">
                <strong>Turno de Entrega</strong>
              </div>
              <div className="col-4">
                <select
                  name="turnoEntrega"
                  value={form.turnoEntrega}
                  onChange={handleChangeForm}>
                  <option value=""></option>
                  <option value="0">Mañana</option>
                  <option value="1">Tarde</option>
                  <option value="2">Noche</option>
                </select>
              </div>
            </div>

            {/* FILA 14 */}
            <div className="row-flex">
              {/* COMENTARIO */}
              <div className="col-8">
                <strong>Comentario</strong>
              </div>
              <div className="flex-auto">
                <textarea
                  className="textarea-description"
                  name="comentario"
                  value={form.comentario}
                  onChange={handleChangeForm}
                  placeholder={lang === "en" ? "Comments..." : "Comentarios..."} />
              </div>
            </div>

            {/* BOTONES DE ACCION */}
            <div className="row-flex m-y-small j-content-space-around">
              {
                stateDetSolRep && stateDetSolRep.length > 0 && (
                  <>
                    <button type="button" className="btn-primary" onClick={() => onHandleOpenModalSave()}>Solicitar</button>
                    <button type="button" className="btn-success" onClick={() => onHandleOpenModalSave(1)}>Stock</button>
                  </>
                )
              }
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
            </div>

            {/* TABLA PARA MOSTRAR LAS PARTES */}
            {
              stateDetSolRep && stateDetSolRep.length > 0 && (
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Cantidad</th>
                        <th>Nro. Parte</th>
                        <th>Descripción</th>
                        <th>Despachar</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        stateDetSolRep && stateDetSolRep.map((part, index) => (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="txt-center">{index + 1}</td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td>
                              <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>
                                {part.NumParte} {part.Alternativo ? (part.Alternativo) : null}
                              </Link>
                            </td>
                            <td>{part.Descripcion}</td>
                            <td className="col-9">
                              <input
                                className="input-text-control txt-center"
                                type="text"
                                name={`dispatch-${part.CodDetSolRepuesto}`}
                                value={part.Despachar}
                                onChange={({ target }) => onHandleChangeVal(target.value, part.CodDetSolRepuesto, part.CodSolRepuesto)}
                                autoComplete="off" />
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            }


            {
              state.showModalConfir && (
                <ModalConfirm onClose={handleOpenClose} question={state.questionAction} sending={state.sending} />
              )
            }
          </form>
        )
      }

    </div>
  );
};