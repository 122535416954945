// custom hook para los fmi

import { useState, useEffect, useContext } from "react";


import { urls } from "../../../api-urls/api-urls.js";
import { getTheToken } from "../../../global-functions/globalFunctions.js";

// react router dom import
import { useNavigate } from "react-router-dom";

// context importation
import { NotificationContext } from "../../../context/NotificationContext.jsx";


import { useTranslation } from "react-i18next";



export const useFetchFMI = (Id = "", lang = "", modal = "") => {

  const [form, setForm] = useState({ reviewBy: "0" });
  const [state, setState] = useState({ isLoading: false, dataVisible: false, reload: false, modalEqp: false, sending: false, modalQuest: false, question: "", action: "", equip: "" });
  const [controller, setController] = useState(null); // controlador para cancelar la peticion
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data
  const [detFMIEqp, setDetFMIEqp] = useState([]); // estado para colocar el detalle de los FMI
  const [values] = useState(["fmi", "fecTop", "division", "linea", "modalidad", "description"]);

  const navigate = useNavigate();

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // destructuring data
  const { fmi, detFMI } = dataServ && dataServ;

  // GET the react translation
  const [t] = useTranslation("global");


  // funcion para cambiar el estado
  const handleChangeState = (name = "", newVal) => {
    setState((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para cerrar el modal de confirmar
  const handleCloseConfirm = () => {
    setState((val) => ({ ...val, modalQuest: !state.modalQuest, action: "", question: "" }));
  };

  // modificar los valores del detFMI
  const handleChangeDetFMI = (CodEqp = 0, newVal = "", name = "") => {
    setDetFMIEqp((val) => {
      return val.map((det) => {
        if (det.CodEquipo === CodEqp) {
          return {
            ...det,
            [name]: newVal
          }
        }
        return det;
      });
    });
  };


  // funcion para manejar el formulario
  const handleChangeForm = (event) => {
    const { name, value, checked } = event.target;
    let newVal = value;
    if (name === "viewKit") {
      newVal = checked;
    }
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para abrir el modal de confirmar crear
  const handleCreateFMI = (Act = "", CodEqp = "") => {
    const IsValid = values.every((key) => form[key] && form[key].trim() !== "");

    if (!IsValid && Act === "c-1") {
      return handleNotify(true, "Debe completar todos los campos", true);
    }

    if (Act === "c-3") {
      const EqpChecked = detFMIEqp.filter((eqp) => eqp.Selected);
      if (!EqpChecked.length) {
        return handleNotify(true, "Debe seleccionar los equipos que se abrirá la llamada de servicio.", true);
      }
    }

    const Msg = {
      "c-1": "Está seguro que desea crear este nuevo FMI?",
      "c-3": "Está seguro que desea abrir las llamadas de servicio para los equipos seleccionados?",
      "d-1": "Está seguro que desea eliminar este equipo?",
      "up-1": t("Viaticos.seguro_guardar")
    };

    setState((val) => ({ ...val, modalQuest: true, question: Msg[Act], action: Act, equip: CodEqp }));
  };


  // funcion para realizar la peticion
  const fetchDataServ = async () => {

    const abortContr = new AbortController();

    let Act = "1";
    if (!Id) {
      Act = "0";
    }

    setController(abortContr);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.fmi}/${Id || "10"}/search?language=${lang}&action=${Act}&modality=${modal}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": getTheToken().token
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      setState((val) => ({ ...val, reload: false }));

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    }
  };


  // useEffect para solicitar la data
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para recargar la data
  useEffect(() => {

    if (state.reload) {
      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }

  }, [state.reload]);


  // useEffect para cargar la informacion del fmi en el estado del form
  useEffect(() => {
    if (fmi && fmi.length > 0) {
      const { NumFMI, CodDivision, CodLinea, CodModalidad, Estatus, IdTipo, FecTope, Descripcion } = fmi[0];
      setForm((val) => ({ ...val, fmi: NumFMI, division: CodDivision, linea: CodLinea, modalidad: CodModalidad, estatus: Estatus, reviewBy: IdTipo, fecTop: FecTope.split("T")[0] ?? "", description: Descripcion }));
    }
  }, [fmi]);

  // useEffect para cargar el detalle del FMI
  useEffect(() => {
    if (detFMI && detFMI.length > 0) {
      setDetFMIEqp(detFMI);
    }
  }, [detFMI]);


  return {
    form,
    state,
    dataServ,
    detFMIEqp,
    handleChangeForm,
    handleCreateFMI,
    handleChangeState,
    handleCloseConfirm,
    handleChangeDetFMI
  };
};