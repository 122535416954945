import React, { useEffect, useState } from "react";

// components importation
import { Close } from "../icons/AppIcons.jsx";

// REACT ROUTER DOM IMPORTATION
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// GLOBAL FUNCTIONS
import { renderDate, renderIdTipSolicitud, renderTemperature } from "../../global-functions/globalFunctions.js";
import { urls } from "../../api-urls/api-urls.js";


import { useTranslation } from "react-i18next";



export const RenderPendingLogistic = ({ company = "", area = "", lang = "", token = "", nameComp = "", name = "", onClose }) => {

  // GET the translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // params
  const [params] = useSearchParams();

  const ToolList = name === "HerrPendCal";


  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, msg: "", dataVisible: false });

  // destructuring dataServer
  const { DataRece } = dataServ && dataServ;


  // funcion para realizar la peticion al servidor
  const fetchDataLogistic = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true, dataVisible: false }));

      const response = await fetch(`${urls.logisticPrincipal}?empresa=${company ?? ""}&zona=${area ?? ""}&language=${lang}&filt=${params.get("filt") ?? "0"}&action=${name}&num_ord=`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();
      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true, msg: "" }));
        setDataServ(data);
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, dataVisible: false, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataLogistic();

    return () => {
      if (controller) {
        controller.abort();
      }
    }

  }, [company, lang, area]);

  // console.log("Es el tool list? ", ToolList);


  return (
    <div className="background-modal">
      <div className="container-select-customer container-warehouse">
        <div className="modal-header">
          <h4>{nameComp}</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            state.isLoading && (<h4 style={{ color: "var(--green-color)" }}>{t("PartModify.cargando_info")}</h4>)
          }
          {
            state.msg && (
              <h5 style={{ color: 'var(--green-color)' }} className="m-l-small m-y-small">{state.msg} &#128561;</h5>
            )
          }
          {
            state.dataVisible && (
              <div className="table-container-mobile">
                <table className="table fade-animation">
                  <thead className="table-head">
                    <tr>
                      {
                        ToolList ?
                          (
                            <>
                              <th>{t("Tools.codigo")}</th>
                              <th>{t("Tools.partNum")}</th>
                              <th>{t("Tools.name")}</th>
                              <th>{t("Tools.empre")}</th>
                              <th>{t("Tools.lastCalibration")}n</th>
                              <th>{t("Tools.prox_cal")}</th>
                              <th>{t("Tools.location")}</th>
                            </>
                          ) :
                          (
                            <>
                              <th>#</th>
                              <th>Temp.</th>
                              {
                                name !== "ReqstPric" && (
                                  <th>{t("LogisticView.order")}</th>
                                )
                              }
                              <th>{t("LogisticView.request")}</th>
                              {
                                name === "FollwUp" && (
                                  <th>{t("LogisticView.step")}</th>
                                )
                              }
                              {
                                name === "ReqstPric" && (
                                  <th>{t("LogisticView.type")}</th>
                                )
                              }
                              <th>{t("LogisticView.date")}</th>
                              <th>{t("LogisticView.area")}</th>
                              <th>{t("LogisticView.customer")}</th>
                              <th>{t("LogisticView.equipment")}</th>
                              <th>{t("LogisticView.comment")}</th>
                            </>
                          )
                      }
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      DataRece && DataRece.map((item) => (
                        <tr key={item.CodIndex}>
                          {
                            ToolList ?
                              (
                                <>
                                  <td>{item.CodHerramienta}</td>
                                  <td>{item.NumParte}</td>
                                  <td>
                                    <Link className="link-tag" to={`/warehouse/tools-detail/${item.CodHerramienta}`}>{item.Nombre}</Link>
                                  </td>
                                  <td>{item.NomEmpresa}</td>
                                  <td>{renderDate(item.FecCalibracion, lang)}</td>
                                  <td>{renderDate(item.NextCalDate, lang)}</td>
                                  <td>{item.Ubicacion}</td>
                                </>
                              ) :
                              (
                                <>
                                  <td>{item.CodIndex}</td>
                                  <td>
                                    <span className={renderTemperature(item.Prioridad)}></span>
                                  </td>
                                  {
                                    name !== "ReqstPric" && (
                                      <td>
                                        <Link className="link-tag" to={item.Route}>{item.Num}</Link>
                                      </td>
                                    )
                                  }
                                  <td>
                                    <Link className="link-tag" to={item.RouteSol}>{item.CodSolRepuesto}</Link>
                                  </td>
                                  {
                                    name === "FollwUp" && (
                                      <td>{item.NomEtapa}</td>
                                    )
                                  }
                                  {
                                    name === "ReqstPric" && (
                                      <td>{renderIdTipSolicitud(item.IdTipSolicitud, lang)}</td>
                                    )
                                  }
                                  <td>{renderDate(item.FecIngreso, lang)}</td>
                                  <td style={{ textTransform: 'capitalize' }}>{item.Zona.toLowerCase()}</td>
                                  <td>
                                    <Link className="link-tag" to={`/customers/customer-detail/${item.CodCliente}`}>{item.NomCliente}</Link>
                                  </td>
                                  <td>
                                    <Link className="link-tag" to={`/equipments/equipment-detail/${item.CodEquipo}`}>{item.Equipo}</Link>
                                  </td>
                                  <td>
                                    <div>
                                      <strong>({renderDate(item.FecComment, lang)})</strong> <strong>{item.NomEmpComment}:</strong>
                                    </div>
                                    {item.Accion}
                                  </td>
                                </>
                              )
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
      </div >
    </div >
  )
};