import React from "react";

//React Router DOM importation
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "../auth/protected-route/ProtectedRoutes.jsx";


// context importation
import { UserProvider } from "../context/UserProvider.jsx";
import { LangProvider } from "../context/LangProvider.jsx";
import { NotificationProvider } from "../context/NotificationProvider.jsx";


//Views Importation
import { LoginView } from "../auth/views/LoginView.jsx";
import { CreateCustomerComp, CustomerDetailsComp } from "../components/customer-components";
import { ServiceCallsDetails, NewCall } from "../components/serviceComponents";
import { CustomersView } from "../views/customers/CustomersView.jsx";
import { NewEmployee } from "../views/employees/NewEmployee.jsx";
// import { EmployeesView } from "../views/employees/EmployeesView";
import { EquipmentView } from "../views/equipment/EquipmentView.jsx";
import { HomeView } from "../views/home/HomeView.jsx";
import { HumanReView } from "../views/human-resources/HumanReView.jsx";
import { PageNotFound } from "../views/page-not-fund/PageNotFound.jsx";
import { ReportsView } from "../views/reports/ReportsView.jsx";
import { ServiceView } from "../views/service/ServiceView.jsx";
import { LogisticView } from "../views/logistic/LogisticView.jsx";
import { SolPrecioView } from "../views/sol-precio-view/SolPrecioView.jsx";
import { PartView } from "../views/part-view/PartView.jsx";
import { OrderDetailView } from "../views/order-detail/OrderDetailView.jsx";
import { SolRepuestoDetailView } from "../views/sol-repuesto-detail/SolRepuestoDetailView.jsx";
import { OrderTracker } from "../views/order-tracker/OrderTracker.jsx";
import { DataSupplierView } from "../views/data-suppliers/DataSupplierView.jsx";
import { ShipToLogisticView } from "../views/ship-to-logistic-view/ShipToLogisticView.jsx";
import { ModifyEmployee } from "../views/employees/ModifyEmployee.jsx";
import { SeguimientoLogistica } from "../views/seguimiento-logistica/SeguimientoLogistica.jsx";
import { LogisticReportsView } from "../views/logistic-reports-view/LogisticReportsView.jsx";
import { LogisticProjectionOrdersView } from "../views/logistic-reports-view/LogisticProjectionOrdersView.jsx";
import { VacacionesPermisosDetail } from "../views/vacaciones-permisos/VacacionesPermisosDetail.jsx";
import { SolicitudesVacaPerm } from "../views/vacaciones-permisos/SolicitudesVacaPerm.jsx";
import { AdministrationPending } from "../views/administration/AdministrationPending.jsx";
import { PropuestasPostVenta } from "../views/post-sales/PropuestasPostVenta.jsx";
import { PropuestaDetail } from "../views/post-sales/PropuestaDetail.jsx";
import { WareHouseViewPrincipal } from "../views/warehouse/WareHouseViewPrincipal.jsx";
import { QuotationViewPrincipal } from "../views/post-sales/QuotationViewPrincipal.jsx";
import { QuotationDetail } from "../views/post-sales/QuotationDetail.jsx";
import { CommercialPurchaseOrder } from "../views/commercial-view/CommercialPurchaseOrder.jsx";
import { CommePurchaseOrderDetail } from "../views/commercial-view/CommePurchaseOrderDetail.jsx";
import { CommercialReportsView } from "../views/commercial-reports/CommercialReportsView.jsx";
import { EquipmentDetailView } from "../views/equipment/EquipmentDetailView.jsx";
import { OrdTransitWaitingDispatch } from "../views/warehouse/OrdTransitWaitingDispatch.jsx";
import { TechnicalApprovalView } from "../views/warehouse/TechnicalApprovalsView.jsx";
import { TechnicalApprovalsDetail } from "../views/warehouse/TechnicalApprovalsDetailView.jsx";
import { SchedulingServiceView } from "../views/service/SchedulingServiceView.jsx";
import { ProjectsView } from "../views/commercial-view/ProjectsView.jsx";
import { ProjectsDetailView } from "../views/commercial-view/ProjectsDetailView.jsx";
import { FMIView } from "../views/service/FMIView.jsx";
import { FMIDetail } from "../views/service/FMIDetail.jsx";
import { NotaEntregaDetailView } from "../views/warehouse/NotaEntregaDetailView.jsx";
import { CommercialRequestOrdersView } from "../views/commercial-view/CommercialRequestOrdersView.jsx";
import { ToolsView } from "../views/warehouse/ToolsView.jsx";
import { ServiceReport } from "../components/serviceComponents/ServiceReport.jsx";
import { RequestPartsView } from "../views/service/RequestPartsView.jsx";
import { AssetRequestConsumablesView } from "../views/service/AssetRequestConsumablesView.jsx";
import { ServiceReportsDetailView } from "../views/service/ServiceReportsDetailView.jsx";
import { ToolsDetailView } from "../views/warehouse/ToolsDetailView.jsx";
import { EquipmentModifyView } from "../views/equipment/EquipmentModifyView.jsx";
import { HistoryExpensesView } from "../views/administration/HistoryExpensesView.jsx";
import { CustomerModifyView } from "../views/customers/CustomerModifyView.jsx";
import { DivisionDataView } from "../views/data/DivisionDataView.jsx";
import { CiudadDataView } from "../views/data/CiudadDataView.jsx";
import { LineaDataView } from "../views/data/LineaDataView.jsx";
import { ModalidadDataView } from "../views/data/ModalidadDataView.jsx";
import { TipEquipDataView } from "../views/data/TipEquipDataView.jsx";
import { ModeloDataView } from "../views/data/ModeloDataView.jsx";
import { TargetDataView } from "../views/data/TargetDataView.jsx";
import { ZonaDataView } from "../views/data/ZonaDataView.jsx";
import { RegionIslandDataView } from "../views/data/RegionIslandDataView.jsx";
import { ServiceReportsPrincipal } from "../views/service-reports-view/ServiceReportsPrincipal.jsx";
import { AdministrativeApprovals } from "../views/warehouse/AdministrativeApprovals.jsx";
import { AproSoporteDetail } from "../views/warehouse/AproSoporteDetail.jsx";
import { AdministrativeApprovalsDetail } from "../views/warehouse/AdministrativeApprovalsDetail.jsx";
import { LlegadaPartesDetail } from "../views/warehouse/LlegadaPartesDetail.jsx";
import { ShipToLogisticViewDetail } from "../views/ship-to-logistic-view/ShipToLogisticViewDetail.jsx";
import { NotaRetiroDetail } from "../views/warehouse/NotaRetiroDetail.jsx";
import { PendingCommercialView } from "../views/commercial-view/PendingCommercialView.jsx";
import { CreateOrderCommercial } from "../views/commercial-view/CreateOrderCommercial.jsx";
import { OrderDetailCommercialView } from "../views/commercial-view/OrderDetailCommercialView.jsx";
import { NotaEntregaDetailPrint } from "../views/warehouse/NotaEntregaDetailPrint.jsx";
import { ConfirRetPartesDetail } from "../views/warehouse/ConfirRetPartesDetail.jsx";
import { AnticipoPrincipal } from "../views/travel-expenses/AnticipoPrincipal.jsx";
import { MyTasksPending } from "../views/my-tasks/MyTasksPending.jsx";
import { AnticipoDetailView } from "../views/travel-expenses/AnticipoDetailView.jsx";
import { AnticipoDetalleDetail } from "../views/travel-expenses/AnticipoDetalleDetail.jsx";
import { CreateAnticipo } from "../views/travel-expenses/CreateAnticipo.jsx";
import { CreateDetalleAnticipo } from "../views/travel-expenses/CreateDetalleAnticipo.jsx";
import { AprobacionPrincipal } from "../views/travel-expenses/AprobacionPrincipal.jsx";
import { AprobacionDetail } from "../views/travel-expenses/AprobacionDetail.jsx";
import { AprobacionDetalleDetail } from "../views/travel-expenses/AprobacionDetalleDetail.jsx";
import { DevolucionView } from "../views/service/DevolucionView.jsx";
import { RelacionGastoPrincipal } from "../views/travel-expenses/RelacionGastoPrincipal.jsx";
import { RelacionGastoDetail } from "../views/travel-expenses/RelacionGastoDetail.jsx";
import { RelacionGastoDetailDetalle } from "../views/travel-expenses/RelacionGastoDetailDetalle.jsx";
import { RelacionGastoNueva } from "../views/travel-expenses/RelacionGastoNueva.jsx";
import { CreateDetalleRelacion } from "../views/travel-expenses/CreateDetalleRelacion.jsx";
import { OrderDetailViewRead } from "../views/order-detail/OrderDetailViewRead.jsx";
import { LogisticPiezasParaExportView } from "../views/logistic-reports-view/LogisticPiezasParaExportView.jsx";
import { ToolsHistory } from "../views/warehouse/ToolsHistory.jsx";
import { CommercialBackLogDetail } from "../views/commercial-view/CommercialBackLogDetail.jsx";
import { ServiceEquipComponents } from "../views/service/ServiceEquipComponents.jsx";
import { CreateEquipment } from "../views/equipment/CreateEquipment.jsx";
import { PartDevolucion } from "../views/service/PartDevolucion.jsx";
import { AuthorizationsView } from "../views/administration/AuthorizationsView.jsx";
import { CreateSolConsumoView } from "../views/administration/CreateSolConsumoView.jsx";
import { SolConsumoDetailView } from "../views/administration/SolConsumoDetailView.jsx";
import { SolConsumpViewPrin } from "../views/administration/SolConsumpViewPrin.jsx";
import { ReportsConsumRequest } from "../views/administration/ReportsConsumRequest.jsx";
import { CreateBackLogView } from "../views/commercial-view/CreateBackLogView.jsx";
import { BackLogDetail } from "../views/commercial-view/BackLogDetail.jsx";
import { ApplicationListView } from "../views/commercial-view/ApplicationListView.jsx";
import { CommercialProfile } from "../components/my-task-pending-comp/CommercialProfile.jsx";
import { LlamadasAbiertas } from "../views/commercial-reports/LlamadasAbiertas.jsx";
import { ListPagosViat } from "../views/administration/ListPagosViat.jsx";
import { ServiceProfile } from "../components/my-task-pending-comp/ServiceProfile.jsx";
import { CreateSupplierView } from "../views/data-suppliers/CreateSupplierView.jsx";
import { AssetRequestDetail } from "../views/service/AssetRequestDetail.jsx";
import { AproHerraDetail } from "../views/service/AproHerraDetail.jsx";
import { AssetRequestHerraDetail } from "../views/service/AssetRequestHerraDetail.jsx";
import { SalidaHerramientaView } from "../views/warehouse/SalidaHerramientaView.jsx";
import { PendingsRH } from "../views/human-resources/PendingsRH.jsx";
import { SitePlanningProfile } from "../components/my-task-pending-comp/SitePlanningProfile.jsx";
import { ReservationsView } from "../views/reservations/ReservationsView.jsx";
import { OperationProfile } from "../components/my-task-pending-comp/OperationProfile.jsx";
import { RequestItList } from "../views/it/RequestItList.jsx";
import { CheckListUL } from "../views/check-list-service/ultrasound/CheckListUL.jsx";






export const AppRouter = () => {

  return (
    <>
      <UserProvider>
        <LangProvider>
          <NotificationProvider>
            <Routes>
              <Route path="login" element={<LoginView />} />
              <Route path="/" element={<Navigate to="login" />} />
              <Route path="*" element={<PageNotFound />} />

              {/* ----> Protected Routes <---- */}
              <Route element={<ProtectedRoutes />}>
                <Route path="home" element={<HomeView />} />

                {/* ----> CUSTOMERS ROUTES <---- */}
                <Route path="customers" element={<CustomersView />} />
                <Route path="customers/customer-detail/:id" element={<CustomerDetailsComp />} />
                <Route path="customers/customer-modify/:codCliente" element={<CustomerModifyView />} />
                <Route path="customers/new-customer" element={<CreateCustomerComp />} />

                {/* ----> EQUIPMENTS ROUTES <---- */}
                <Route path="equipments" element={<EquipmentView />} />
                <Route path="equipments/create-equipment/:codCliente" element={<CreateEquipment />} />
                <Route path="equipments/equipment-detail/:codEquipo" element={<EquipmentDetailView />} />
                <Route path="equipments/equipment-modify/:codEquipo" element={<EquipmentModifyView />} />

                {/* ----> SERVICE ROUTES <---- */}
                <Route path="service" element={<ServiceView grupo={1} />} />
                <Route path="service/service-call/:id" element={<ServiceCallsDetails grupo={1} />} />
                <Route path="service/service-report/:idCall" element={<ServiceReport grupo={1} />} />
                <Route path="service/service-report-detail/:codRepServ" element={<ServiceReportsDetailView grupo={1} />} />
                <Route path="service/request-part/:idCall" element={<RequestPartsView />} />
                <Route path="service/asset-request/:idCall" element={<AssetRequestConsumablesView />} />
                <Route path="service/asset-request-detail/:reqId" element={<AssetRequestDetail />} />
                <Route path="service/asset-approval-detail/:reqId" element={<AproHerraDetail />} />
                <Route path="service/asset-request-detail/:reqId/:reqDet" element={<AssetRequestHerraDetail />} />
                <Route path="new-call" element={<NewCall grupo={1} />} />
                <Route path="service/scheduling" element={<SchedulingServiceView />} />
                <Route path="service/fmi" element={<FMIView />} />
                <Route path="service/fmi/fmi-detail/:codFMI" element={<FMIDetail />} />
                <Route path="service/fmi/new-fmi" element={<FMIDetail />} />
                <Route path="service/service-call/part-return/:codServ" element={<DevolucionView />} />
                <Route path="service/service-equipment-components/:codServ" element={<ServiceEquipComponents />} />
                <Route path="service/return-part/:codNotEntr" element={<PartDevolucion />} />
                {/* RUTA PRINCIPAL PARA LOS REPORTES DE SERVICIO */}
                <Route path="service/reports" element={<ServiceReportsPrincipal />} />
                <Route path="service/service-report/ul/:codServ" element={<CheckListUL />} />


                {/* ----> LOGISTIC ROUTE <---- */}
                <Route path="logistic-principal" element={<LogisticView />} />
                <Route path="price-request/:id" element={<SolPrecioView />} />
                <Route path="part-detail/:codDetSolR/:solRepuesto" element={<PartView />} />
                <Route path="order-detail/:id" element={<OrderDetailView />} />
                <Route path="request-part-detail/:solRepuestoId" element={<SolRepuestoDetailView />} />
                <Route path="order-tracking/:orderNum/:idEtapa" element={<OrderTracker />} /> {/* ESTA RUTA ES PARA CAMBIAR LAS ETAPAS DE LAS ORDENES LUEGO DE QUE SON ENVIADAS AL SISTEMA ADMINISTRATIVO */}
                <Route path="supplier-data" element={<DataSupplierView />} />
                <Route path="supplier-data/create-supplier" element={<CreateSupplierView />} />
                <Route path="ship-to" element={<ShipToLogisticView />} />
                <Route path="ship-to/detail/:codShipTo" element={<ShipToLogisticViewDetail />} />
                <Route path="logistic-follow-up" element={<SeguimientoLogistica />} />
                <Route path="logistic/zone-support-approval/approval-detail/:codSolRep" element={<AproSoporteDetail />} />
                <Route path="logistic-reports-view" element={<LogisticReportsView />} />
                <Route path="logistic/order-detail-reader/:orderNum" element={<OrderDetailViewRead />} />
                {/* REPORTES DE LOGISTICA */}
                <Route path="logistic-reports-projections/search" element={<LogisticProjectionOrdersView />} />
                <Route path="logistic-reports-parts-export/search" element={<LogisticPiezasParaExportView />} />

                {/* ----> HUMAN RESOURCE ROUTES <---- */}
                <Route path="human-capital/employees" element={<HumanReView />} />
                <Route path="human-capital/employees/new-employee" element={<NewEmployee />} />
                <Route path="human-capital/employee/modify/:id" element={<ModifyEmployee />} />
                <Route path="human-capital/pendings" element={<PendingsRH />} />
                <Route path="human-capital/vacations/request" element={<SolicitudesVacaPerm />} />
                <Route path="human-capital/vacations/request/detail/:id" element={<VacacionesPermisosDetail />} />

                {/* ----> Mis Pendientes <---- */}
                {/* <Route path="my-tasks" element={<MyTasksPending />} /> */}
                <Route path="my-tasks/commercial" element={<CommercialProfile />} />
                <Route path="my-tasks/service" element={<ServiceProfile />} />
                <Route path="my-tasks/site-planning" element={<SitePlanningProfile />} />
                <Route path="my-tasks/operation" element={<OperationProfile />} />


                {/* ----> VIATICOS <---- */}
                <Route path="travel-expenses" element={<AnticipoPrincipal />} />
                <Route path="travel-expenses/approvals" element={<AprobacionPrincipal />} />
                <Route path="travel-expenses/expense-detail/:codSolG" element={<AnticipoDetailView />} />
                <Route path="travel-expenses/approve-detail/:codSolG" element={<AprobacionDetail />} />
                <Route path="travel-expenses/approve-detail/:codSolG/:codDet" element={<AprobacionDetalleDetail />} />
                <Route path="travel-expenses/expense-detail/:codSolG/:codDet" element={<AnticipoDetalleDetail />} />
                <Route path="travel-expenses/create-detail-advance/:codSolG" element={<CreateDetalleAnticipo />} />
                <Route path="travel-expenses/expense-report-detail/:codSolG" element={<RelacionGastoDetail />} />
                <Route path="travel-expenses/expense-report-detail/:codSolG/:codDet" element={<RelacionGastoDetailDetalle />} />
                <Route path="travel-expenses/create-advance" element={<CreateAnticipo />} />
                <Route path="travel-expenses/create-expense-report" element={<RelacionGastoNueva />} />
                <Route path="travel-expenses/create-detail-expense-report/:codSolG" element={<CreateDetalleRelacion />} />
                <Route path="travel-expenses/expense-report" element={<RelacionGastoPrincipal />} />

                {/* ----> COMMERCIAL <---- */}
                <Route path="commercial/purchase-order" element={<CommercialPurchaseOrder />} />
                <Route path="commercial/purchase-order/order-detail/:codOrden" element={<CommePurchaseOrderDetail />} />
                <Route path="commercial/purchase-order/order-detail/:codOrdenDet" element={<CommercialBackLogDetail />} />
                <Route path="commercial/projects" element={<ProjectsView />} />
                <Route path="commercial/projects/projects-detail/:idService" element={<ProjectsDetailView />} />
                <Route path="commercial/request-orders" element={<CommercialRequestOrdersView />} />
                <Route path="commercial/request-new-order" element={<CreateBackLogView />} />
                <Route path="commercial/request-order-detail/:codOrdenDet" element={<BackLogDetail />} />
                <Route path="commercial/principal" element={<PendingCommercialView />} />
                <Route path="commercial/service" element={<ServiceView grupo={3} />} />
                <Route path="commercial/service/new-call" element={<NewCall grupo={3} />} />
                <Route path="commercial/service/service-report/:idCall" element={<ServiceReport grupo={3} />} />
                <Route path="commercial/service/service-call/:id" element={<ServiceCallsDetails grupo={3} />} />
                <Route path="commercial/service-report-detail/:codRepServ" element={<ServiceReportsDetailView grupo={3} />} />
                <Route path="commercial/create-order" element={<CreateOrderCommercial />} /> {/* Esta ruta es para las ordenes de comercial (modulo nuevo) para darle el siguimiento a los proyectos */}
                <Route path="commercial/order-detail/:codSol" element={<OrderDetailCommercialView />} />
                <Route path="commercial/reports-view" element={<CommercialReportsView />} />
                <Route path="commercial/service/app-list" element={<ApplicationListView />} />
                <Route path="commercial/reports-view/open-calls-report" element={<LlamadasAbiertas />} />


                {/* POST VENTA */}
                <Route path="post-sales/principal" />
                <Route path="post-sales/proposal" element={<PropuestasPostVenta />} />
                <Route path="/post-sales/proposal/proposal-detail/:codPropuesta" element={<PropuestaDetail />} />
                <Route path="/post-sales/quotations" element={<QuotationViewPrincipal />} />
                <Route path="/post-sales/quotation-detail/:codSolRepuesto/:cotNum" element={<QuotationDetail />} />

                {/* ----> ALMACEN RUTAS <---- */}
                <Route path="warehouse/principal" element={<WareHouseViewPrincipal />} />
                <Route path="warehouse/dispatch-instructions/:codSolRepuesto" element={<OrdTransitWaitingDispatch />} />
                <Route path="warehouse/technical-approval" element={<TechnicalApprovalView />} />
                <Route path="warehouse/technical-approval/technical-detail/:codSolRepuesto" element={<TechnicalApprovalsDetail />} />
                <Route path="warehouse/administrative-approval" element={<AdministrativeApprovals />} />
                <Route path="warehouse/administrative-approval/approval-detail/:codSolRep" element={<AdministrativeApprovalsDetail />} />
                <Route path="warehouse/delivery-notes/:codSolRepuesto" element={<NotaEntregaDetailView />} />
                <Route path="warehouse/delivery-notes-print/:notEntr" element={<NotaEntregaDetailPrint />} />
                <Route path="warehouse/withdrawal-part-detail/:codNotRet" element={<ConfirRetPartesDetail />} />
                <Route path="warehouse/tools" element={<ToolsView />} />
                <Route path="warehouse/tools-detail/:codHerr" element={<ToolsDetailView />} />
                <Route path="warehouse/new-tool" element={<ToolsDetailView />} />
                <Route path="warehouse/tools-detail/history/:codHerr" element={<ToolsHistory />} />
                <Route path="warehouse/parts-arrival-detail/:codSolRep" element={<LlegadaPartesDetail />} />
                <Route path="warehouse/withdrawal-detail/:codSolRep" element={<NotaRetiroDetail />} />
                <Route path="warehouse/tool-exit/:reqId" element={<SalidaHerramientaView />} />

                {/* ----> ADMINISTRATION <---- */}
                <Route path="administration/pending" element={<AdministrationPending />} />
                <Route path="administration/expenses/history" element={<HistoryExpensesView />} />
                <Route path="administration/payment-expenses" element={<ListPagosViat />} />
                <Route path="administration/authorizations" element={<AuthorizationsView />} />
                <Route path="administration/create-consumption-request" element={<CreateSolConsumoView />} />
                <Route path="administration/consumption-request" element={<SolConsumpViewPrin />} />
                <Route path="administration/consumption-request-detail/:codSol" element={<SolConsumoDetailView />} />
                <Route path="administration/report-consumption-request" element={<ReportsConsumRequest />} />

                {/* ----> DATA <---- */}
                <Route path="data/division" element={<DivisionDataView />} />
                <Route path="data/line" element={<LineaDataView />} />
                <Route path="data/modality" element={<ModalidadDataView />} />
                <Route path="data/equipment-type" element={<TipEquipDataView />} />
                <Route path="data/model" element={<ModeloDataView />} />
                <Route path="data/target" element={<TargetDataView />} />
                <Route path="data/area" element={<ZonaDataView />} />
                <Route path="data/region" element={<RegionIslandDataView />} />
                <Route path="data/city" element={<CiudadDataView />} />

                {/* Rutas para los reportes */}
                <Route path="reports" element={<ReportsView />} />

                {/* Ruta para las reservaciones de los salones de conferencia */}
                <Route path="reservations" element={<ReservationsView />} />

                {/* rutas para el departamento de it */}
                <Route path="request-it" element={<RequestItList />} />


              </Route>
            </Routes>
          </NotificationProvider>
        </LangProvider>
      </UserProvider>
    </>
  );
};