// componente para agregar componentes a una herramienta


// components importation
import { useState, useContext } from "react";

// components importation
import { Close } from "../icons/AppIcons.jsx";


// notification context import
import { NotificationContext } from "../../context/NotificationContext.jsx";


import { urls } from "../../api-urls/api-urls.js";
import { getTheToken } from "../../global-functions/globalFunctions.js";


import { useTranslation } from "react-i18next";





export const AddCompTool = ({ onClose, CodHerr = "", lang = "", onReload }) => {

  // state definition
  const [form, setForm] = useState({}); // estado para definir el formulario
  const [state, setState] = useState({ sending: false });


  // GET notification context
  const { handleNotify } = useContext(NotificationContext);

  // GET the react translation
  const [t] = useTranslation("global");


  // funcion para manejar el cambio de formulario
  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    if (["qty", "minQty"].includes(name) && isNaN(value)) {
      return;
    }
    setForm((val) => ({ ...val, [name]: value }));
  };

  // funcion para enviar el formulario
  const handleSendForm = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, sending: true }));
      const response = await fetch(`${urls.tools}/${CodHerr}/search?language=${lang}&action=crt-2`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.status === 201) {
        handleNotify(true, data?.msg, false, true);
        onReload();
        onClose();
      }

      setState((val) => ({ ...val, sending: false }));

    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="background-modal">
      <form className="container-select-customer" onSubmit={handleSendForm}>
        <div className="modal-header">
          <h4>{t("ToolsView.agregar_comp")}</h4>
          <button type="reset" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("ToolsView.tipo")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <select
                name="tipComp"
                value={form.tipComp || ""}
                onChange={handleChangeForm}>
                <option value=""></option>
                <option value="1">{t("ToolsView.suministro")}</option>
                <option value="0">{t("ToolsView.herramienta")}</option>
              </select>
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Parte</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="parte"
                value={form.parte || ""}
                onChange={handleChangeForm} />
            </div>
            <div className="col-7">
              <strong>{t("ToolsView.name")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="name"
                value={form.name || ""}
                onChange={handleChangeForm} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("ToolsView.cantidad")}</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="qty"
                value={form.qty || ""}
                onChange={handleChangeForm} />
            </div>
            {
              (form.tipComp && form.tipComp === "1") && (
                <>
                  <div className="col-7 fade-animation">
                    <strong>Mínimo</strong>
                  </div>
                  <div className="col-3 flex-grow fade-animation">
                    <input
                      className="input-text-control"
                      type="text"
                      name="minQty"
                      value={form.minQty || ""}
                      onChange={handleChangeForm} />
                  </div>
                </>
              )
            }
          </div>
        </div>
        <div className="modal-footer j-content-space-around">
          <button type={state.sending ? "button" : "submit"} className={`btn-primary${state.sending ? " disabled" : ""}`}>{state.sending ? t("serviceCard.guardando") : t("ToolsView.guardar")}</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>{t("LogisticViewComp.cerrar")}</button>
        </div>
      </form>
    </div>
  );
};