import React, { useEffect, useState, useContext } from "react";

// COMPONENTS
import { RenderPendingLogistic } from "../../components/logistic-components/RenderPendingLogistic.jsx";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { TruckIcon, CurrencyDollarIcon, CardCheckList, SearchIcon, SpinnerIcon, WrenchToolIcon } from "../../components/icons/AppIcons.jsx";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls.js";

// STYLES IMPORTATION
import "../../views/logistic/LogisticView.css";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions.js";

// IMPORT TRANSLATATION FROM REACT
import { useTranslation } from "react-i18next";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";



export const LogisticView = () => {

  // GET the language
  const { lang } = useContext(LangContext);

  // GET the notification
  const { handleNotify } = useContext(NotificationContext);

  const token = getTheToken().token;

  // REACT TRANSLATION
  const [t] = useTranslation("global");

  // navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [orden, setOrden] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // para cuando este cargando la data
  const [controller, setController] = useState(null); // estado para cargar el controlador para abortar la peticion al servidor
  const [params, setParams] = useSearchParams(); // params de la url
  const [state, setState] = useState({ showModal: false, name: "", nameComp: "", sending: false });


  const { empresa, zona, empPerm, TotalCount } = dataServer;


  const IconMap = {
    TruckIcon: <TruckIcon />,
    CurrencyDollarIcon: <CurrencyDollarIcon />,
    CardCheckList: <CardCheckList />,
    WrenchToolIcon: <WrenchToolIcon />
  };

  const handleOpenCloseModal = (nom = "", nomCom = "", Total = 0) => {
    if (Total) {
      setState((val) => ({ ...val, showModal: !state.showModal, name: nom, nameComp: nomCom }));
      params.set("name_view", nom);
      setParams(params);
    } else {
      setState((val) => ({ ...val, showModal: false }));
      params.set("name_view", "");
      setParams(params);
    }
  };


  // const codEmpresa = empresaPertenece && empresaPertenece.length > 0 ? empresaPertenece[0].CodDYN : undefined;

  // ONCHANGE PARA CAMBIAR LA COMPANIA
  const onChangeForm = (event) => {
    const { name, value } = event.target;
    if (name !== "numOrd") {
      params.set("filt", 1);
      switch (name) {
        case "area":
          params.set("area", value);
          params.set("emp", form.company);
          setForm((val) => ({ ...val, area: value }));
          break;
        case "company":
          params.set("emp", value);
          params.set("area", "");
          setForm((val) => ({ ...val, area: "", company: value }));
          break;
      }
      setParams(params);
    } else {
      params.set("num_ord", value);
      setForm((val) => ({ ...val, numOrd: value }));
      setParams(params);
    }
  };


  // state definition
  const [form, setForm] = useState({ company: params.get("emp") ?? "", area: params.get("area") ?? "", numOrd: params.get("num_ord") ?? "" });

  // funcion para manejar el formulario
  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      setState((val) => ({ ...val, sending: true }));
      const response = await fetch(`${urls.logisticPrincipal}?empresa=${form.company}&zona=${form.area}&language=${lang}&filt=${params.get("filt") ?? "0"}&num_ord=${params.get("num_ord") ?? ""}&action=consult`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setOrden(data?.Orden);
      } else if (response.status === 404) {
        setOrden([]);
        handleNotify(true, data?.msg, true);
      }

      setState((val) => ({ ...val, sending: false }));

      console.log("Se envió el formulario ", form.numOrd);

    } catch (err) {
      console.log(err);
    }
  };



  // funcion para realizar la peticion al servidor
  const fetchDataLogistic = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setIsLoading(true);
      const response = await fetch(`${urls.logisticPrincipal}?empresa=${form.company}&zona=${form.area}&language=${lang}&filt=${params.get("filt") ?? "0"}&num_ord=${params.get("num_ord") ?? ""}&action=init`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();
      if (response.status === 200) {
        setDataServer(data);
        setOrden(data?.Orden);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }
      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect PARA TRAER LA DATA
  useEffect(() => {

    // PETICION AL BACKEND
    fetchDataLogistic();

    // CAMBIAR EL TITUTLO DEL DOCUMENTO
    document.title = lang === "es" ? "Logística - Principal" : "Logistic - Principal";

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang, form.area, form.company]);



  useEffect(() => {
    if (empPerm && params.get("filt") === null) {
      const Area = empPerm[0]?.CodZona ?? "";
      setForm((val) => ({ ...val, company: empPerm[0]?.CodDYN, area: Area }));
    }
  }, [empPerm]);

  // useEffect para verificar si hay un nameview
  useEffect(() => {
    if (TotalCount && TotalCount.length > 0) {
      if (params.get("name_view")?.trim()) {
        const FindName = TotalCount.find((val) => val.Nombre === params.get("name_view"));

        setState((val) => ({ ...val, showModal: true, name: FindName?.Nombre, nameComp: FindName?.NomCompleto }));
      }
    }
  }, [TotalCount]);






  return (
    <div className="container display-data">
      {isLoading && (<LoadingComp />)}
      {/* CAMBIO DE LAS ZONAS */}
      <div className="row-flex">
        {/* CAMBIAR EMPRESA */}
        <div className="col-7 col-sm-35">
          <strong style={{ color: "var(--first-color)" }}>{t("LogisticView.changeCompany")}</strong>
        </div>
        <div className="col-3 col-sm-50">
          <select
            name="company"
            value={form.company}
            onChange={onChangeForm}>
            <option value=""></option>
            {
              empresa && empresa.map((empr) => (
                <option key={empr.CodEmpresa} value={empr.CodDYN}>{empr.Nombre}</option>
              ))
            }
          </select>
        </div>
        {/* CAMBIAR LA ZONA */}
        <div className="col-7 col-sm-35">
          <strong style={{ color: "var(--first-color)" }}>{t("LogisticView.changeArea")}</strong>
        </div>
        <div className="col-38 col-sm-50">
          <select
            name="area"
            value={form.area}
            onChange={onChangeForm}>
            <option></option>
            {
              zona && zona.filter((zon) => zon.CodDYN === form.company).map((element) => (
                <option key={element.CodZona} value={element.CodZona}>{element.NomZona}</option>
              ))
            }
          </select>
        </div>
      </div>

      <form onSubmit={handleSearch}>
        <div className="row-flex">
          <div className="col-7">
            <strong style={{ color: "var(--first-color)" }}>Buscar orden/solicitud</strong>
          </div>
          <div className="col-3">
            <input
              className="input-text-control"
              type="text"
              name="numOrd"
              value={form.numOrd}
              onChange={onChangeForm} />
          </div>
          <button type="submit" className="btn-success row-flex align-c gap-small">
            {
              state.sending ? (
                <>
                  {t("PartModify.buscando")}
                  <SpinnerIcon fillP="currentColor" />
                </>
              ) : (
                <>
                  Buscar
                  <SearchIcon />
                </>
              )
            }
          </button>
        </div>
      </form>

      {
        TotalCount && TotalCount.length > 0 && (
          <div className="row-flex gap-small m-y-small">
            {
              TotalCount && TotalCount.map((items) => (
                <div key={items.Nombre} className="flex-grow cont-icons">
                  {/* Renderizar iconos aca items.Icon */}
                  <div className="counter-not">{items.Total}</div>
                  <button type="button" onClick={() => handleOpenCloseModal(items.Nombre, items.NomCompleto, items.Total)} className={items.ClassValue}>
                    {IconMap[items.Icon]}
                  </button>
                  <h5>{items.NomCompleto}</h5>
                </div>
              ))
            }
          </div>
        )
      }
      {
        state.showModal && (
          <RenderPendingLogistic company={form.company} area={form.area} lang={lang} token={token} nameComp={state.nameComp} name={state.name} onClose={handleOpenCloseModal} />
        )
      }

      {
        orden && orden.length > 0 && (
          <div className="m-y-small">
            <h4>{orden.length} {orden.length > 1 ? "Resultados" : "Resultado"}</h4>

            <div className="m-y-small table-container-mobile">
              <table className="table fade-animation">
                <thead className="table-head">
                  <tr>
                    <th>#</th>
                    <th>Temp.</th>
                    <th>{t("LogisticView.order")}</th>
                    <th>{t("LogisticView.request")}</th>
                    <th>{t("LogisticView.step")}</th>
                    <th>{t("LogisticView.date")}</th>
                    <th>{t("LogisticView.area")}</th>
                    <th>{t("LogisticView.customer")}</th>
                    <th>{t("LogisticView.equipment")}</th>
                    <th>{t("LogisticView.comment")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    orden && orden.map((item) => (
                      <tr key={item.CodIndex}>
                        <td></td>
                        <td>
                          <span className={renderTemperature(item.Prioridad)}></span>
                        </td>
                        <td>
                          <Link className="link-tag" to={item.Route}>{item.Num}</Link>
                        </td>
                        <td>
                          <Link className="link-tag" to={item.RouteSol}>{item.CodSolRepuesto}</Link>
                        </td>
                        <td style={{ color: item.IdEtapa === 99 && "var(--green-color)", fontWeight: item.IdEtapa === 99 && "bold" }}>{item.NomEtapa}</td>
                        <td>{renderDate(item.FecIngreso, lang)}</td>
                        <td>{item.Zona}</td>
                        <td>
                          <Link className="link-tag" to={`/customers/customer-detail/${item.CodCliente}`}>{item.NomCliente}</Link>
                        </td>
                        <td>
                          <Link className="link-tag" to={`/equipments/equipment-detail/${item.CodEquipo}`}>{item.Equipo}</Link>
                        </td>
                        <td>
                          <div>
                            <strong>({renderDate(item.FecComment, lang)})</strong> <strong>{item.NomEmpComment}:</strong>
                          </div>
                          {item.Accion}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </div>
  );
};