// componente para las tarjetas

import { Link, useNavigate, useSearchParams } from "react-router-dom";


import { renderTempCard, renderDate, renderTempCardTool } from "../../global-functions/globalFunctions.js";


import { useTranslation } from "react-i18next";



export const CardInformation = ({ ArrData = [], lang = "es" }) => {

  const navigate = useNavigate();

  const [params] = useSearchParams();

  const ViewHerr = params.get("name_view") === "HerrPendCal";

  // GET the react translation
  const [t] = useTranslation("global");


  return (
    <>
      {
        ArrData && ArrData.map((data) => (
          <div key={data.KeyId} className={`row-flex card-shadow m-y-small ${ViewHerr ? renderTempCardTool(data.Prioridad) : renderTempCard(data.Prioridad)}`}>
            <div className="col-5">
              <strong>
                {ViewHerr ? t("ToolsView.code") : t("LogisticViewComp.serviceID")}
              </strong>
            </div>
            <div className="col-4">
              {ViewHerr ? <Link className="link-tag">{data.CodHerramienta}</Link> : <Link className="link-tag" to={`/service/service-call/${data.CodServicio}`}>{data.CodServicio}</Link>}
            </div>
            <div className="col-5">
              <strong>
                {ViewHerr ? t("ToolsView.partNum") : t("LogisticViewComp.request")}
              </strong>
            </div>
            <div className="col-4">
              <strong>
                {ViewHerr ? data.NumParte : data.CodSolRepuesto}
              </strong>
            </div>
            <div className="col-5">
              <strong>
                {ViewHerr ? t("ToolsView.name") : t("LogisticViewComp.customer")}
              </strong>
            </div>
            <div className="col-80">
              {ViewHerr ? <span>{data.Nombre}</span> : <Link className="link-tag">{data.NomCliente}</Link>}
            </div>
            <div className="col-5">
              <strong>
                {ViewHerr ? t("ToolsView.location") : t("LogisticViewComp.equipment")}
              </strong>
            </div>
            <div className="col-80">
              {ViewHerr ? <span>{data.Ubicacion}</span> : <Link className="link-tag">{data.Equipo}</Link>}
            </div>
            <div className="col-5">
              <strong>
                {ViewHerr ? t("ToolsView.fec_cali") : t("LogisticViewComp.solic_por")}
              </strong>
            </div>
            <div className="col-80">
              {ViewHerr ? <span>{renderDate(data.FecCalibracion, lang)}</span> : <span className="txt-muted">{data.EmpSolicita} - {renderDate(data.Fecha, lang)}</span>}
            </div>
            {
              !ViewHerr && (
                <>
                  <div className="col-5">
                    <strong>{t("LogisticView.area")}</strong>
                  </div>
                  <div className="col-3">
                    <span className="txt-muted">{data.Zona}</span>
                  </div>
                  <div className="col-3 row-flex flex-end">
                    <button type="button" className="btn-review" onClick={() => navigate(data.URL)}>Ver</button>
                  </div>
                </>
              )
            }
          </div>
        ))
      }
    </>
  );
};