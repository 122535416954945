// este componente es para descargar documentos del servidor

// react imports
import { useState, useContext } from "react";

// global functions imports
import { getTheToken } from "../../global-functions/globalFunctions.js";

// react router dom import
import { useNavigate } from "react-router-dom";


// import notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { useTranslation } from "react-i18next";





export const useDownloadDocs = () => {

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [stateS, setStateS] = useState({ isLoading: false, errorMsg: "" });

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);


  // funcion para descargar el documento
  const downLoadFile = async (url = "", fileName = "", accept = "") => {


    try {
      setStateS((val) => ({ ...val, isLoading: true }));
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Authorization": getTheToken().token,
          "Accept": accept
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear enlace oculto
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobURL;
        a.download = fileName;
        document.body.appendChild(a);

        a.click();

        handleNotify(true, t("newCallComp.descarga_archivo"), false, true);

        // limpiar la url
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);

      } else {
        // ocurrio algun error
        const data = await response.json();

        if (response.status === 401) {
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 404) {
          setStateS((val) => ({ ...val, errorMsg: data?.msg }));
          handleNotify(true, data?.msg, true);
        }
      }

      setStateS((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }

  };

  return {
    stateS,
    downLoadFile
  }

};