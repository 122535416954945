// componente para seleccionar la herramienta


// useTranslation import
import { useEffect, useState } from "react";


// components importation
import { ToastNotification } from "../toast-notification/ToastNotification";


// react router importation
import { useNavigate } from "react-router-dom";


import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";
import { Close } from "../icons/AppIcons";





export const SelectHerramienta = ({ onClose, lang, codSol, token, onNotify }) => {

  // GET the react translation
  const [t] = useTranslation("global");


  // GET the useNavigate
  const navigate = useNavigate();



  // state definition
  const [stateHerra, setStateHerra] = useState([]);
  const [controller, setController] = useState(null);// estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, nombre: "", loading: false });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false });



  // buscar el nombre de la herramienta
  const filtHerramienta = stateHerra && stateHerra.filter((herr) => herr.NomHerra.toLowerCase().includes(state.nombre.toLowerCase()));



  // funcion para cambiar los valores del estado
  const onHandleChangeVal = (newVal = false, cod = "") => {
    setStateHerra((val) => {
      return val.map((herr) => {
        if (herr.CodHerramienta === cod) {
          return { ...herr, Add: newVal };
        }
        return herr;
      });
    });
  };



  // funcion para enviar el formulario al servidor
  const onHandleSubmitPart = async (event) => {
    event.preventDefault();

    const filtPartsSave = stateHerra && stateHerra.filter((herr) => herr.Add);

    if (filtPartsSave.length === 0) {
      return setNotification((val) => ({ ...val, show: true, msg: t("serviceCard.select_herra"), error: true }));
    }

    try {
      setState((val) => ({ ...val, loading: true }));
      const response = await fetch(`${urls.requestTools}/${codSol}/search?language=${lang}&action=2`, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(filtPartsSave)
      });

      const data = await response.json();

      if (response.status === 201) {
        onNotify(false, data?.msg);
        onclose();
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 201) {
        onNotify(true, data?.msg);
      }

      setState((val) => ({ ...val, loading: false }));

    } catch (error) {
      console.log(error);
    }
  };




  // funcion para traer los datos del servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.requestTools}/${codSol}/search?language=${lang}&action=3`, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });


      const data = await response.json();

      if (response.status === 200) {
        setStateHerra(data?.parts);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar los datos al servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para limpiar las notificaciones del estado
  useEffect(() => {

    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);





  return (
    <div className="background-modal">
      {
        notification.show && (<ToastNotification success={notification.great} error={notification.error} msg={notification.msg} />)
      }
      <form onSubmit={onHandleSubmitPart} className="container-select-customer">
        <div className="modal-header">
          <h4>{t("ToolsView.selec_herr")}</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            state.isLoading && (<h3>{t("LoginView.cargando")}</h3>)
          }

          <div className="row-flex">
            <div className="col-7">
              <strong>{t("Viaticos.buscar")}</strong>
            </div>
            <div className="col-85-71">
              <input
                className="input-text-control"
                type="text"
                name="searchInpu"
                value={state.nombre}
                onChange={({ target }) => setState((val) => ({ ...val, nombre: target.value }))} />
            </div>
          </div>

          {
            filtHerramienta && filtHerramienta.length > 0 && (
              <>
                {
                  filtHerramienta && filtHerramienta.map((herr) => (
                    <div className="row-flex hover-class border-bottom" key={herr.CodHerramienta}>
                      <div className="col-85-71">
                        <strong>{herr.NomHerra}</strong>
                      </div>
                      <div className="col-7 row-flex flex-end align-c">
                        <input
                          className="input-control-checkbox"
                          type="checkbox"
                          name={herr.CodHerramienta}
                          checked={herr.Add}
                          onChange={({ target }) => onHandleChangeVal(target.checked, herr.CodHerramienta)} />
                      </div>
                    </div>
                  ))
                }
              </>
            )
          }
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-primary" disabled={state.loading} >
            {state.loading ? t("newCallComp.loading") : t("RequestPartsView.guar")}
          </button>
          <button type="reset" onClick={onClose} className="btn-secondary">{t("RequestPartsView.cerrar")}</button>
        </div>
      </form>
    </div>
  );
};