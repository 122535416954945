// reporte de gasto de piezas

import { Link } from "react-router-dom";
import { formatCurrency, renderDate } from "../../global-functions/globalFunctions";





export const ReporteGastoPiezas = ({ report = [], lang = "" }) => {

  let TotalGe = 0;

  return (
    <table className="table fade-animation">
      <thead className="table-head">
        <tr>
          <th>Solicitud</th>
          <th>No. Parte</th>
          <th>Descripción</th>
          <th>No. Orden</th>
          <th>Cantidad</th>
          <th>Precio de Lista</th>
          <th>Total</th>
          <th>Fecha de solicitud</th>
          <th>Cliente</th>
          <th>Flete importación</th>
          <th>Flete exportación</th>
          <th>Costo manejo de carga</th>
          <th>Gestión aduanal</th>
          <th>DUA</th>
          <th>Entrega de documentos</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody className="table-body">
        {
          report && report.map((part) => {

            let Total = part.FleteImport + part.FleteExport + part.HandlingCost + part.GesAduanal + part.DUA + part.EntrDocumentos;

            TotalGe += Total;

            return (
              <tr key={`${part.CodSolRepuesto}-${part.CodDetSolRepuesto}`}>
                <td>
                  <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>
                    {part.CodSolRepuesto}
                  </Link>
                </td>
                <td>{part.NumParte}</td>
                <td>{part.Descripcion}</td>
                <td>{part.NumOrden}</td>
                <td>{part.Cantidad}</td>
                <td>{formatCurrency(part.Lista)}</td>
                <td>{formatCurrency(part.Cantidad * part.Lista)}</td>
                <td>{renderDate(part.FecSolicitud, lang)}</td>
                <td>{part.NomCliente}</td>
                <td className="txt-right">{formatCurrency(part.FleteImport)}</td>
                <td className="txt-right">{formatCurrency(part.FleteExport)}</td>
                <td className="txt-right">{formatCurrency(part.HandlingCost)}</td>
                <td className="txt-right">{formatCurrency(part.GesAduanal)}</td>
                <td className="txt-right">{formatCurrency(part.DUA, "USD")}</td>
                <td className="txt-right">{formatCurrency(part.EntrDocumentos)}</td>
                <td className="txt-right" style={{ color: "var(--money-color)", fontWeight: "bold" }}>
                  {formatCurrency(Total)}
                </td>
              </tr>
            )
          })
        }
        <tr>
          <td colSpan={15}>
            <strong>Total</strong>
          </td>
          <td className="link-tag" style={{ fontWeight: "bold" }}>{formatCurrency(TotalGe)}</td>
        </tr>
      </tbody>
    </table>
  );
};