import React, { useEffect, useState, useContext } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error403View } from "../../views/error-403-view/Error403View.jsx";
import { TruckIcon, WrenchAdjustableIcon, JournalCheckIcon, ClipBoardCheckIcon, BoxSeamIcon, ToolIcon, FileUploadIcon } from "../../components/icons/AppIcons.jsx";
import { RenderPendings } from "../../components/warehouse/RenderPendings.jsx";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS
import { getTheToken } from "../../global-functions/globalFunctions.js";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// REACT TRANSLATION
import { useTranslation } from "react-i18next";


// language context
import { LangContext } from "../../context/LangContext.jsx";




export const WareHouseViewPrincipal = () => {

  // language context
  const { lang } = useContext(LangContext);

  // navigation
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // REACT TRANSLATION
  const [t] = useTranslation("global");

  // useSearchparams
  const [params, setParams] = useSearchParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]);
  const [controller, setController] = useState(null); // Estado para colocar el abortcontroller
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false, act: "", title: "" });
  const [area, setChangeArea] = useState(params.get("area") ?? "");

  // DESTRUCTURING DATA SERVER
  // const { ordersInTransit, empPerm, conducesPendDesp, partesLlegarAlma, notaRetiro, confirRetiroPartes, salidaHerraDespa } = dataServer && dataServer;
  const { TotalCount, empPerm } = dataServer && dataServer;


  const IconMap = {
    TruckIcon: <TruckIcon />,
    WrenchAdjustableIcon: <WrenchAdjustableIcon />,
    JournalCheckIcon: <JournalCheckIcon />,
    ClipBoardCheckIcon: <ClipBoardCheckIcon />,
    BoxSeamIcon: <BoxSeamIcon />,
    ToolIcon: <ToolIcon />,
    FileUploadIcon: <FileUploadIcon />
  };


  // funcion para abrir el modal y pasarle el parametro para consultar la data
  const handleOpenCloseModal = (newVal = "", nom = "") => {
    setState((val) => ({ ...val, showModal: !state.showModal, act: newVal, title: nom }));
    params.set("name_view", newVal);
    setParams(params);
  };


  // funcion para solicitar la data del servidor
  const fetchDataServer = async (areaParams = "") => {
    const abortController = new AbortController();


    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getWarehousePrincipal}?language=${lang}&zone=${params.get("area") ?? areaParams}&filt=${params.get("filt") ?? "0"}&act=1`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      // console.clear();

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        return setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        // error interno en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };




  // Cambiar la zona y guardar en localStorage
  const onChangeArea = (newVal) => {
    params.set("area", newVal);
    params.set("filt", 1);
    setParams(params);
    setChangeArea(newVal);
    fetchDataServer(newVal);
  };


  // useEffect declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);


  // useEffect para cargar la zona en el estad
  useEffect(() => {
    if (empPerm && params.get("area") === null) {
      setChangeArea(empPerm[0]?.CodZona);
    }
  }, [empPerm]);


  // useEffect para verificar si hay un nameview
  useEffect(() => {

    if (TotalCount && TotalCount.length > 0) {
      if (params.get("name_view")) {
        if (params.get("name_view") !== "") {
          const FindName = TotalCount.find((val) => val.Nombre === params.get("name_view"));

          setState((val) => ({ ...val, showModal: true, act: FindName?.Nombre, title: FindName?.NomCompleto }));
        }
      }
    }

  }, [TotalCount]);




  return (
    <>
      {/* Component Error 404 not authorized */}
      {state.unauthorized && (<Error403View />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      {
        state.dataVisible && (
          <div className="container display-data">
            <div className="row-flex m-y-small">
              <div className="col-7">
                <strong>Cambiar zona</strong>
              </div>
              <div className="col-5">
                <select
                  name="changeZon"
                  value={area}
                  onChange={({ target }) => onChangeArea(target.value)}>
                  <option value=""></option>
                  <option value="1">República Dominicana</option>
                  <option value="6">Caribe</option>
                  <option value="7">Trinidad & Tobago</option>
                </select>
              </div>
            </div>

            {
              TotalCount && TotalCount.length > 0 && (
                <div className="row-flex gap-small">
                  {
                    TotalCount && TotalCount.map((items) => (
                      <div key={items.Nombre} className="flex-grow cont-icons">
                        {/* Renderizar iconos aca items.Icon */}
                        <div className="counter-not">{items.Total}</div>
                        <button type="button" onClick={() => handleOpenCloseModal(items.Nombre, items.NomCompleto)}>
                          {IconMap[items.Icon]}
                        </button>
                        <h5>{items.NomCompleto}</h5>
                      </div>
                    ))
                  }
                </div>
              )
            }

            {
              state.showModal && (
                <RenderPendings
                  action={state.act}
                  title={state.title}
                  onClose={handleOpenCloseModal}
                  lang={lang}
                  zone={area ?? ""}
                  filt={params.get("filt") ?? "0"}
                  token={getTheToken().token} />
              )
            }

          </div>
        )
      }
    </>
  );
};