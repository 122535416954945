

import React, { useEffect, useState, useContext } from "react";

// COMPONENTS IMPORTATION

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { SearchFMI } from "../../components/fmi/SearchFMI.jsx";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch.jsx";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";


// context importation
import { LangContext } from "../../context/LangContext.jsx";




export const FMIView = () => {

  // context language
  const { lang } = useContext(LangContext);

  // navigate
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // STATE DEFINITION
  const [controller, setController] = useState(null);
  const [listFMI, setListFMI] = useState([]); // state para cargar la data del servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, modalSearch: false, msgNotF: null });

  // search params
  const [params] = useSearchParams();


  // funcion para setear el nuevo estado
  const handleChangeList = (newList = []) => {
    setListFMI(newList);
  };


  // funcion para abrir y cerrar modal de busqueda
  const handleOpenCloseSearch = () => {
    setState((val) => ({ ...val, modalSearch: !state.modalSearch }));
  };


  const fetchDataServer = async () => {

    const abortCont = new AbortController();

    setController(abortCont);

    try {

      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.fmi}?status=${params.get("status") ?? "0"}&fmi=${params.get("fmi") ?? ""}&close=${params.get("close") ?? "-1"}&language=${lang}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortCont.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setListFMI(data?.FMI);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        // ocurrio un error en el servidor
        setState((val) => ({ ...val, msgNotF: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    }
  };


  // useEffect
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  return (
    <>
      {
        state.msgNotF && (<h4 style={{ color: "red" }}>{state.msgNotF}</h4>)
      }
      {
        state.isLoading && (<LoadingComp />)
      }
      {
        state.modalSearch && (<SearchFMI onClose={handleOpenCloseSearch} setNewList={handleChangeList} />)
      }
      <div className="container display-data">

        <div className="table-container-mobile">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>Nombre</th>
                <th>Descripcion</th>
                <th># Equipos</th>
                <th># ID</th>
                <th>Fecha Tope</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {
                listFMI && listFMI.map((items) => (
                  <tr key={items.CodFMI}>
                    <td style={{ textAlign: 'center' }}><Link className="link-tag" to={`/service/fmi/fmi-detail/${items.CodFMI}`}>{items.NumFMI}</Link></td>
                    <td style={{ textTransform: 'capitalize' }}>{items.Descripcion}</td>
                    <td style={{ textAlign: 'center' }}>{items.Total}</td>
                    <td style={{ textAlign: 'center' }}>{items.Llamada}</td>
                    <td style={{ textAlign: 'center' }}>{renderDate(items.FecTope, lang)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        <ButtonSearch onOpenFilter={handleOpenCloseSearch} />
      </div>
    </>
  );
};