// este componente es para renderizar los pendientes de operaciones, servicio 
// y que el admin los pueda visualizar

// react importation
import { useEffect, useState, useContext } from "react";

// global functions imports
import { getTheToken } from "../../global-functions/globalFunctions.js";

// components import
import { LoadingComp } from "../Loading-comp/LoadingComp.jsx";
import { ServiceStatistics } from "./ServiceStatistics.jsx";
import { TruckIcon, WrenchAdjustableIcon, JournalCheckIcon, AirplaneIcon, ClipBoardCheckIcon, ToolIcon, GearWideConnIcon, EnvelopeCheckIcon, WrenchToolIcon } from "../../components/icons/AppIcons.jsx";


// react router dom import
import { useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls.js";


// language context import
import { LangContext } from "../../context/LangContext.jsx";
import { RendingPendingService } from "./RendingPendingService.jsx";




export const ServiceProfile = () => {

  const { lang } = useContext(LangContext);


  // GET the useNavigate()
  const navigate = useNavigate();



  // params definition
  const [params, setParams] = useSearchParams();



  // estado para programacion de visita
  // const [progVisita, setProgVisita] = useState([]); // estado para colocar la programacion de visita
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data que viene del server
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, zona: params.get("area") ?? "", grupo: params.get("grp") ?? "", showModal: false, name: "", title: "" });


  // destructuring data server
  const { TotalCount, empPerm } = dataServ && dataServ;

  const IconMap = {
    ToolIcon: <ToolIcon />,
    WrenchAdjustableIcon: <WrenchAdjustableIcon />,
    GearWideConnIcon: <GearWideConnIcon />,
    EnvelopeCheckIcon: <EnvelopeCheckIcon />,
    JournalCheckIcon: <JournalCheckIcon />,
    TruckIcon: <TruckIcon />,
    ClipBoardCheckIcon: <ClipBoardCheckIcon />,
    AirplaneIcon: <AirplaneIcon />,
    WrenchToolIcon: <WrenchToolIcon />
  };


  // funcion para abrir el modal
  const handleOpenCloseModal = (KeyId = "", NomCompl = "") => {
    setState((val) => ({ ...val, showModal: !state.showModal, name: KeyId, title: NomCompl }));
    params.set("name_view", KeyId);
    setParams(params);
  };


  // funcion para cambiar la zona
  const onHandleChangeVal = (newVal = "", name = "", para = "") => {
    params.set("filt", 1);
    params.set(para, newVal);
    setParams(params);
    setState((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para solicitar la informacion al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.myTasks}?language=${lang}&filtro=${params.get("filt") ?? "0"}&zona=${params.get("area") ?? ""}&action=init&grup=${state.grupo}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        return navigate(-1, { replace: true });
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang, state.zona, state.grupo]);


  // useEffect para cargar la zona en el estado
  useEffect(() => {
    if (empPerm && empPerm.length > 0) {

      if (params.get("area") === null) {
        if (empPerm[0]?.CodZona) {
          setState((val) => ({ ...val, zona: empPerm[0]?.CodZona }));
        }
      }

      if (params.get("grp") === null) {
        setState((val) => ({ ...val, grupo: empPerm[0]?.CodGrupo ?? "" }));
      }

      if (![1, 3].includes(empPerm[0]?.CodPerfil)) {
        setState((val) => ({ ...val, dataVisible: false }));
        navigate("/home");
      }

    }

  }, [empPerm]);


  // useEffect para verificar si hay un nameView
  useEffect(() => {
    if (TotalCount && TotalCount.length > 0) {
      if (params.get("name_view")?.trim()) {
        const FindName = TotalCount.find((val) => val.KeyId === params.get("name_view"));

        setState((val) => ({ ...val, showModal: true, name: FindName?.KeyId, title: FindName?.Etapa }));
      }
    }
  }, [TotalCount]);




  return (
    <div className="container display-data">
      {
        state.isLoading && (<LoadingComp />)
      }
      {
        state.dataVisible && (
          <>
            <div className="row-flex gap-medium m-y-medium d-col-rever">
              <ServiceStatistics area={state.zona} lang={lang} token={getTheToken().token} grup={state.grupo} />

              {/* Card para los filtros */}
              <div className="card-shadow card-info-filter">
                <h5>Filtros</h5>
                <form>
                  <div className="row-flex">
                    <div className="col-3">
                      <strong>Zona</strong>
                    </div>
                    <div className="col-61-58">
                      <select
                        name="zona"
                        value={state.zona}
                        onChange={({ target }) => onHandleChangeVal(target.value, "zona", "area")}>
                        <option value=""></option>
                        <option value="1">República Dominicana</option>
                        <option value="6">Caribe</option>
                        <option value="7">Trinidad y Tobago</option>
                      </select>
                    </div>
                  </div>
                  <div className="row-flex">
                    <div className="col-3">
                      <strong>Grupo</strong>
                    </div>
                    <div className="col-61-58">
                      <select
                        name="grp"
                        value={state.grupo}
                        onChange={({ target }) => onHandleChangeVal(target.value, "grupo", "grp")}>
                        <option value=""></option>
                        <option value="1">Servicio</option>
                        <option value="3">Ventas</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {
              TotalCount && TotalCount.length > 0 && (
                <div className="row-flex gap-small m-y-small">
                  {
                    TotalCount && TotalCount.map((items) => (
                      <div key={items.KeyId} className="flex-grow cont-icons">
                        {/* Renderizar iconos aca items.Icon */}
                        <div className="counter-not">{items.Total}</div>
                        <button type="button" onClick={() => handleOpenCloseModal(items.KeyId, items.Etapa)}>
                          {IconMap[items.Icon]}
                        </button>
                        <h5>{items.Etapa}</h5>
                      </div>
                    ))
                  }
                </div>
              )
            }


            {
              state.showModal && (<RendingPendingService grup={state.grupo} area={state.zona} title={state.title} lang={lang} onClose={handleOpenCloseModal} />)
            }
          </>
        )
      }
    </div>
  );
};