// componente para consultar las herramientas

import { useEffect, useState } from "react";

// urls importation
import { urls } from "../../../api-urls/api-urls.js";


import { useNavigate } from "react-router-dom";
import { getTheToken } from "../../../global-functions/globalFunctions.js";



export const useFetchTools = (lang = "", CodHerr = "") => {

  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, showModalSave: false, sending: false, unauthorized: false, dataVisible: false, questionAction: "", sending: false, reload: false, modalComp: false, modalList: false, modalPhoto: false, modalDocs: false, listDocs: false });
  const [form, setForm] = useState({ activo: true }); // estado para el formulario

  // get useNavigate
  const navigate = useNavigate();


  // funcion para actualizar el estado de enviando el formulario
  const handleSending = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };

  // funcion para cerrar abrir modal de lista de certificados
  const handleCloseOpenListDocs = () => {
    setState((val) => ({ ...val, listDocs: !state.listDocs }));
  };

  // funcion para abrir el modal de la foto
  const handleOpenClosePhoto = () => {
    setState((val) => ({ ...val, modalPhoto: !state.modalPhoto }));
  };

  // funcion para abrir y cerrar el modal de cargar documentos
  const handleOpenCloseDocs = () => {
    setState((val) => ({ ...val, modalDocs: !state.modalDocs }));
  };

  // funcion para abrir cerrar el modal de agregar componentes
  const handleOpenCloseAddComp = () => {
    setState((val) => ({ ...val, modalComp: !state.modalComp }));
  };

  // funcion para abrir cerrar el modal de listar componentes
  const handleOpenCloseListComp = () => {
    setState((val) => ({ ...val, modalList: !state.modalList }));
  };

  // funcion para cerrar abrir el modal
  const handleOpenClose = () => {
    setState((val) => ({ ...val, showModalSave: !state.showModalSave }));
  };

  // modal para crear y guardar
  const handleSave = () => {
    setState((val) => ({ ...val, showModalSave: true, questionAction: "Esta seguro desea guardar" }));
  };

  // funcion para cargar multiples datos en el form
  const handleMultiValsForm = (newVals = {}) => {
    setForm((val) => ({ ...val, ...newVals }));
  };

  // funcion para recargar la data de ser necesario
  const handleReloadData = () => {
    setState((val) => ({ ...val, reload: true }));
  };


  // funcion para modificar los valores del form
  const handleChangeForm = (event) => {
    const { name, value, checked } = event.target;

    if (name === "valor" && isNaN(value)) {
      return;
    }

    if (name === "codigo" && CodHerr) {
      return;
    }
    let newVal = value
    if (name === "activo") {
      newVal = checked;
    }
    setForm((val) => ({ ...val, [name]: newVal }));
  };



  // funcion para realizar la solicitud al servidor
  const fetchDataServ = async () => {
    const abortContr = new AbortController();

    setController(abortContr);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.tools}/${CodHerr || "init"}/search?language=${lang}&historial=no&action=${CodHerr ? "all" : "init"}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      setState((val) => ({ ...val, reload: false }));

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodHerr, lang]);

  // useEffect para recargar la data
  useEffect(() => {
    if (state.reload) {
      fetchDataServ();
      console.log("Se recargó la data")
      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [state.reload]);

  return {
    form,
    state,
    dataServ,
    handleChangeForm,
    handleSending,
    handleOpenClose,
    handleSave,
    handleMultiValsForm,
    handleOpenCloseAddComp,
    handleReloadData,
    handleOpenCloseListComp,
    handleOpenClosePhoto,
    handleOpenCloseDocs,
    handleCloseOpenListDocs
  };
};