// componente para mostrar la tabla de comentarios en las llamadas

// react import
import { useEffect, useState } from "react";


import { urls } from "../../api-urls/api-urls";

// global function import
import { renderDate } from "../../global-functions/globalFunctions";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";






export const SegPartsTable = ({ lang, token, idCall }) => {

  // GET the react translation
  const [t] = useTranslation("global");


  // state definition
  const [segParts, setSegParts] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: true, msg: "" });


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);
    const END_POINT = `${urls.serviceCallObj}/parts/${idCall}/search?language=${lang}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      console.clear()

      const data = await response.json();

      if (response.status === 200) {
        setSegParts(data?.segParts);
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para consultar la data
  useEffect(() => {

    fetchDataServ();

    // console.log("Se solicito la data al servidor")

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [idCall, lang]);



  return (
    <>
      <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>{t("serviceCard.parts")}</h4>

      {
        state.isLoading && (<h5 className="m-y-small" style={{ color: "var(--green-color)" }}>{t("PartModify.cargando_info")}</h5>)
      }

      {
        !state.isLoading && (
          <>
            {
              segParts && segParts.length > 0 && (


                <div className="table-container-mobile m-y-small seg-service-table fade-animation">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th style={{ borderRadius: '5px 0 0 0' }}>{t("serviceCard.request")}</th>
                        <th>{t("serviceCard.quotation")}</th>
                        <th>{t("serviceCard.purchaseOrder")}</th>
                        <th>{t("serviceCard.deliveryNote")}</th>
                        <th>{t("serviceCard.date")}</th>
                        <th>{t("serviceCard.part")}</th>
                        <th>{t("serviceCard.description")}</th>
                        <th>{t("serviceCard.step")}</th>
                        <th>{t("serviceCard.availabilityInfo")}</th>
                        <th>{t("serviceCard.comment")}</th>
                        <th>{t("serviceCard.qty")}</th>
                        <th>Sal.</th>
                        <th>Util.</th>
                        <th>Por Dev</th>
                        <th style={{ borderRadius: '0 5px 0 0' }}>Dev.</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        segParts && segParts.map((part) => (
                          <tr key={part.codeIndex}>
                            <td className="txt-center">
                              <Link className="link-tag" to={`/request-part-detail/${part.CodSolRepuesto}`}>{part.CodSolRepuesto}</Link>
                            </td>
                            <td className="txt-center">
                              <div className="row-flex d-column">
                                {part.CodCotizacion ? <Link className="link-tag" to={`/post-sales/quotation-detail/${part.CodSolRepuesto}/${part.CodCotizacion}`}>{part.CodCotizacion}</Link> : "S/N"}
                                {part.IdEstatus === 2 ? t("newCallComp.no_se_factura") : part.IdEstatus === 0 ? t("newCallComp.oport_venta") : ""}
                              </div>
                            </td>
                            <td className="txt-center">
                              {part.NumOrden ? <Link className="link-tag" to={`/logistic/order-detail-reader/${part.NumOrden}`}>{part.NumOrden}</Link> : "S/N"}
                            </td>
                            <td className="txt-center">
                              {part.CodNotEntrega ? <Link className="link-tag">{part.CodNotEntrega}</Link> : <><span>S/N</span><br /></>}
                            </td>
                            <td>{renderDate(part.FecIngreso, lang)}</td>
                            <td>
                              <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte}</Link>
                            </td>
                            <td>{part.Descripcion}</td>
                            <td>{part.NomEtapa}</td>
                            <td>{part.BackOrder}</td>
                            <td>{part.Accion}</td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td className="txt-center">{part.CanSalida}</td>
                            <td className="txt-center">{part.usadoLabel}</td>
                            <td className="txt-center">{part.devolverLabel}</td>
                            <td className="txt-center"></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              )
            }
          </>
        )
      }
    </>
  );
};