import { useState } from "react";
import { Link, useParams } from "react-router-dom";




export const CheckListUL = () => {

  const { codServ } = useParams();

  const [stateSonda, setStateSonda] = useState([{ id: 1, name: "", model: "", numSr: "", tierrAbier: "", tierrCerra: "", visual: false, funcional: false, valAsis: false, result: "", comentarios: "" }]);

  // agregar una nueva sonda
  const handleAddSonda = () => {
    // obtener el ultimo id del array
    const NewId = stateSonda.length > 0 ? stateSonda[stateSonda.length - 1].id + 1 : 1;

    // new obj
    const newSond = {
      id: NewId,
      name: "",
      model: "",
      numSr: "",
      tierrAbier: "",
      tierrCerra: "",
      visual: false,
      funcional: false,
      valAsis: false,
      result: "",
      comentarios: ""
    };

    setStateSonda([...stateSonda, newSond]);
  };

  console.log(codServ)

  return (
    <div className="container display-data">

      <div className="row-flex">
        <div className="col-7">
          <strong>Cliente</strong>
        </div>
        <div className="col-3">
          <span>Centro Medico Moderno</span>
        </div>
        <div className="col-7">
          <strong>Llamada de servicio</strong>
        </div>
        <div className="col-3">
          <Link className="link-tag">{codServ}</Link>
        </div>
      </div>

      <div className="row-flex">
        <div className="col-7">
          <strong>Equipo</strong>
        </div>
        <div className="col-3">
          <Link className="link-tag">GE - Ultrasonido - General Imaging - Laptop - Logiq e - 6326091WX0 - GE HEALTHCARE </Link>
        </div>
      </div>

      <div className="table-container-mobile m-y-small">
        <table className="table">
          <thead>
            <tr style={{ borderBottom: "1px solid gray" }}>
              <th>Tarea de MP</th>
              <th>Procedimiento</th>
              <th>Resultados</th>
              <th>Comentarios</th>
            </tr>
          </thead>
          <tbody className="table-body">
            <tr>
              <td>Consultar/escuchar</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar etiquetado</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Verificar puesta en marcha</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Copia de seguridad de red y preajustes.</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Revise los registros de errores</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Copia de seguridad del software (si procede)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Realizar diagnóstico (opcional)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Configurar y/o verificar configuración de agente de Insite.</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar y validad el número de campos coincidentes de CRM de la configuración de Insite</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Configurar usuario predeterminado para Insite RFS/Auto-RFS</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Iniciar un RFS de prueba</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h3 style={{ textDecoration: "underline" }}>Limpieza</h3>
              </td>
            </tr>
            <tr>
              <td>Limpiar consola</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Limpiar panel de control</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Limpiar monitor</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Limpiar pantalla táctil</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Limpiar portasondas</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Limpiar filtro (s)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h3 style={{ textDecoration: "underline" }}>Inspección física</h3>
              </td>
            </tr>
            <tr>
              <td>Comprobar potencia de entrada</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar E/S externa</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar ruedas y frenos</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar movimiento del panel de control</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Si procede, comprobar movimiento del monitor</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar cables y conectores</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar blindajes y cubiertas</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar consola</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar panel de control</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Observe si la batería está deteriorada</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h3 style={{ textDecoration: "underline" }}>Comprobaciones funcionales</h3>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del monitor</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del panel de control</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del panel táctil</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del teclado</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del modo B</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del modo M</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del modo CF</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del modo 3D</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del modo 4D</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento del modos Doppler</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Comprobar funcionamiento de los periféricos</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Inspeccionar funcionamiento de la batería</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Inspeccionar funcionamiento del portasondas</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h3 style={{ textDecoration: "underline" }}>Pruebas de seguridad eléctrica</h3>
              </td>
            </tr>
            <tr>
              <td>Realizar prueba de continuidad de tierra (obms)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Realizar prueba de fuga de chasis (ua)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Realizar prueba de derivación a tierra de ECG (ua)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Realizar prueba de continuidad de derivación a derivación de ECG (ua)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
            <tr>
              <td>Realizar prueba de ISO de ECG (ua)</td>
              <td className="txt-center">PSM/BUM</td>
              <td className="txt-center">
                <select name="" id="">
                  <option value=""></option>
                  <option value="">Aprobado</option>
                  <option value="">No aprobado</option>
                  <option value="">Realizado</option>
                  <option value="">No realizado</option>
                  <option value="">N/A</option>
                </select>
              </td>
              <td className="txt-center">
                <textarea
                  className="textarea-description" name="" id=""></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>Comprobaciones de sonda</h4>

      <div className="row-flex">
        <button className="btn-primary" onClick={handleAddSonda}>
          Agregar
        </button>
      </div>
      <div className="table-container-mobile m-y-small">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th className="col-3">Identificación de sonda</th>
              <th>Fuga</th>
              <th>Inspección</th>
              <th>Resultados</th>
              <th>Comentarios</th>
            </tr>
          </thead>
          <tbody className="t-report-body">
            {
              stateSonda && stateSonda.map((son) => (
                <tr key={son.id} style={{ borderBottom: "1px solid gray" }}>
                  <td>{son.id}</td>
                  <td>
                    <div className="row-flex gap-small">
                      <input
                        className="input-text-control"
                        type="text"
                        placeholder="Nombre" />
                      <input
                        className="input-text-control"
                        type="text"
                        placeholder="Modelo" />
                      <input
                        className="input-text-control"
                        type="text"
                        placeholder="No. de serie" />
                    </div>
                  </td>
                  <td>
                    <div className="row-flex gap-small">
                      <input
                        className="input-text-control"
                        type="text"
                        placeholder="Tierra abierto" />
                      <input
                        className="input-text-control"
                        type="text"
                        placeholder="Tierra cerrado" />
                    </div>
                  </td>
                  <td>
                    <div className="row-flex j-content-space-b p-small-y">
                      <strong>Visual</strong>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="" />
                    </div>
                    <div className="row-flex j-content-space-b p-small-y">
                      <strong>Funcional</strong>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="" />
                    </div>
                    <div className="row-flex j-content-space-b">
                      <strong>Valoración asistencial</strong>
                      <input
                        className="input-control-checkbox"
                        type="checkbox"
                        name="" />
                    </div>
                  </td>
                  <td>
                    <select name="">
                      <option value=""></option>
                      <option value="">Aprobado</option>
                      <option value="">No aprobado</option>
                      <option value="">Realizado</option>
                      <option value="">No realizado</option>
                      <option value="">N/A</option>
                    </select>
                  </td>
                  <td>
                    <textarea
                      className="textarea-description"
                      name=""
                      placeholder="Comentarios" />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      <div className="m-y-small">
        <textarea
          className="textarea-description"
          name=""
          placeholder="Comentario" />
      </div>

    </div>
  );
};