// componente para el filtrado de las herramientas

import { useEffect, useState } from "react";

// components importation
import { Close } from "../icons/AppIcons.jsx";


import { useNavigate, useSearchParams } from "react-router-dom";

// url import
import { urls } from "../../api-urls/api-urls.js";
import { getTheToken } from "../../global-functions/globalFunctions.js";

// 
import { useTranslation } from "react-i18next";



export const FilterTools = ({ onClose, lang = "", onSetNewState }) => {

  // params
  const [params, setParams] = useSearchParams();

  // state definition
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [dataServ, setDataServ] = useState([]); // estado para la data del servidor
  const [form, setForm] = useState({ nom_comp: params.get("nom_comp") ?? "" }); // estado para el formulario
  const [state, setState] = useState({ isLoading: false, searching: false });

  // GET the react translation
  const [t] = useTranslation("global");


  // GET the navigate
  const navigate = useNavigate();


  // destructuring data serv
  const { empresa, ingenieros, division, emplPermi } = dataServ && dataServ;


  // funcion para manejar el formulario
  const handleSearch = async (event) => {
    event.preventDefault();

    params.set("nom_comp", form.nom_comp); // set the company
    setParams(params);

    try {
      setState((val) => ({ ...val, searching: true }));
      const response = await fetch(`${urls.tools}?language=${lang}&action=init&compa=${params.get("nom_comp") ?? ""}&descrip=${params.get("nom_herr") ?? ""}&divi=${params.get("nom_divi") ?? ""}&line=${params.get("nom_line") ?? ""}&moda=${params.get("nom_moda") ?? ""}&nom_empl=${params.get("nom_empl") ?? ""}&filt=${params.get("filt") ?? "0"}&pend_cal=${params.get("pend_cal") ?? ""}&code_tool=${params.get("code") ?? ""}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        onSetNewState(data?.allTools);
        onClose();
      } else if (response.status === 401) {
        navigate("/");
      } else {
        onClose();
        onSetNewState([], data?.msg);
        // mostrar mensaje
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (err) {
      console.log(err);
    }
  };



  // funcion para manejar el formulario
  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setForm((val) => ({ ...val, [name]: value }));
    params.set(name, value);
    params.set("filt", 1);
  };

  // funcion para consultar la data al servidor
  const fetchDataServ = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.tools}?language=${lang}&action=filt`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      } else if (response.status === 401) {
        navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    }
  };


  // useEffect para traer la data
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  // useEffect para el filtro
  useEffect(() => {
    if (emplPermi && emplPermi.length > 0 && !params.get("filt")) {
      setForm((val) => ({ ...val, nom_comp: emplPermi[0]?.CodEmpresa }));
    }
  }, [emplPermi]);


  return (
    <div className="background-modal">
      <form className="container-select-customer" onSubmit={handleSearch}>
        <div className="modal-header">
          <h4>{t("ToolsView.filt_herr")}</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("ToolsView.empre")}</strong>
            </div>
            <div className="col-3">
              <select
                name="nom_comp"
                value={form.nom_comp || (params.get("nom_comp") ?? "")}
                onChange={handleChangeForm}>
                <option value=""></option>
                {
                  empresa && empresa.map((emp) => (
                    <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-7">
              <strong>{t("ToolsView.descrip")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="nom_herr"
                value={form.nom_herr || (params.get("nom_herr") ?? "")}
                onChange={handleChangeForm} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("ToolsView.division")}</strong>
            </div>
            <div className="col-3">
              <select
                name="nom_divi"
                value={form.nom_divi || (params.get("nom_divi") ?? "")}
                onChange={handleChangeForm}>
                <option value=""></option>
                {
                  division && division.map((div) => (
                    <option key={div.CodDivision} value={div.CodDivision}>{div.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-7">
              <strong>{t("ToolsView.linea")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <select
                name="nom_line"
                value={form.nom_line || (params.get("nom_line") ?? "")}
                onChange={handleChangeForm}>
                <option value=""></option>
              </select>
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("ToolsView.modalidad")}</strong>
            </div>
            <div className="col-3">
              <select
                name="nom_moda"
                value={form.nom_moda || (params.get("nom_moda") ?? "")}
                onChange={handleChangeForm}>
                <option value=""></option>
              </select>
            </div>
            <div className="col-7">
              <strong>{t("ToolsView.empleado")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <select
                name="nom_empl"
                value={form.nom_empl || (params.get("nom_empl") ?? "")}
                onChange={handleChangeForm}>
                <option value=""></option>
                {
                  ingenieros && ingenieros.map((ing) => (
                    <option key={ing.CodEmpleado} value={ing.CodEmpleado}>{ing.NomEmpleado}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>{t("ToolsView.revisar")}</strong>
            </div>
            <div className="col-3">
              <select
                name="pend_cal"
                value={form.pend_cal || (params.get("pend_cal") ?? "")}
                onChange={handleChangeForm}>
                <option value=""></option>
                <option value="pend">{t("ToolsView.pend_calibrar")}</option>
              </select>
            </div>
            <div className="col-7">
              <strong>Código</strong>
            </div>
            <div className="col-3 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="code"
                value={form.code || (params.get("code") ?? "")}
                onChange={handleChangeForm} />
            </div>
          </div>

          <div className="row-flex flex-end">
            <button type="button" className="btn-success" onClick={() => navigate("/warehouse/new-tool")}>{t("ToolsView.nueva")}</button>
          </div>
        </div>
        <div className="modal-footer j-content-space-around">
          <button type={state.searching ? "button" : "submit"} className={`btn-primary${state.searching ? " disabled" : ""}`}>{state.searching ? t("PartModify.buscando") : t("PartModify.buscar")}</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>{t("Viaticos.cerrar")}</button>
        </div>
      </form>
    </div>
  );
};