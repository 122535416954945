
import React, { useContext } from "react";


// components import
import { Close, FileMarkPlus, Folder, PDFIcon } from "../../components/icons/AppIcons.jsx";
import { SelectFMIEquipment } from "../../components/fmi/SelectFMIEquipment.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";


// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// urls importation
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken } from "../../global-functions/globalFunctions";
import { useFetchFMI } from "../../components/customs/fmi/useFetchFMI.js";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";


export const FMIDetail = () => {

  // language context
  const { lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // location
  const location = useLocation();

  const navigate = useNavigate();

  // useparams
  const { codFMI } = useParams();


  // custom hook
  const { form, state, dataServ, detFMIEqp, handleChangeForm, handleCreateFMI, handleChangeState, handleCloseConfirm, handleChangeDetFMI } = useFetchFMI(codFMI, lang);


  // destructuring data server
  const { division, linea, modalidad, detFMI } = dataServ && dataServ;

  // url GET equipments
  const URL_GET_EQP = `${urls.fmi}/${codFMI}/search?language=${lang}&action=g-e1&modality=${form.modalidad}`;

  const URL_POST_EQP = `${urls.fmi}/${codFMI}/search?language=${lang}&action=c-2&modality=${form.modalidad}&equip=`;


  // funcion para enviar el formulario al servidor
  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const FinalObj = { detFMIEqp, form };

    const Body = state.action === "c-3" ? detFMIEqp : FinalObj;

    try {
      handleChangeState("sending", true);
      const response = await fetch(`${urls.fmi}/${codFMI || "10"}/search?language=${lang}&action=${state.action}&equip=${state.equip}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(Body)
      });

      const data = await response.json();

      if (response.status === 201) {
        // se crea el fmi
        navigate(data?.navigateTo);
        handleNotify(true, data?.msg, false, true);
      } else if (response.status === 200) {
        // se actualiza el FMI
        handleNotify(true, data?.msg, false, true);
        handleChangeState("reload", true);
        handleCloseConfirm();
      }

      handleChangeState("sending", false);

    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div className="container display-data">
      {
        state.modalEqp && (
          <SelectFMIEquipment onClose={handleChangeState} UrlGet={URL_GET_EQP} UrlPost={URL_POST_EQP} />
        )
      }
      {
        state.isLoading && (<LoadingComp />)
      }
      <form onSubmit={handleSubmitForm}>
        <div className="row-flex">
          <div className="col-8">
            <strong>Nro. FMI</strong>
          </div>
          <div className="col-5">
            <input
              type="text"
              className="input-text-control"
              name="fmi"
              value={form.fmi || ""}
              onChange={handleChangeForm} />
          </div>
          <div className="col-8">
            <strong>Revisar por</strong>
          </div>
          <div className="col-5">
            <select
              name="reviewBy"
              value={form.reviewBy || ""}
              onChange={handleChangeForm}>
              <option value="0">System ID</option>
              <option value="1">Serial</option>
            </select>
          </div>
          {
            codFMI && (
              <>
                <div className="col-8">
                  <strong>Estatus</strong>
                </div>
                <div className="flex-grow col-7">
                  <span>{form.estatus}</span>
                </div>
              </>
            )
          }
        </div>
        <div className="row-flex">
          <div className="col-8">
            <strong>Descripción</strong>
          </div>
          <div className="flex-grow col-7">
            <textarea
              className="textarea-description"
              name="description"
              value={form.description || ""}
              onChange={handleChangeForm} />
          </div>
        </div>
        <div className="row-flex">
          <div className="col-8">
            <strong>Fecha tope</strong>
          </div>
          <div className="col-5">
            <input
              className="input-date-styles"
              type="date"
              name="fecTop"
              value={form.fecTop || ""}
              onChange={handleChangeForm} />
          </div>
          {
            codFMI && (
              <>
                <div className="col-8">
                  <strong>Carta de notificación</strong>
                </div>
                <div className="col-5">
                  <button type="button" className="btn-docs">
                    <Folder />
                  </button>
                </div>
              </>
            )
          }
        </div>
        <div className="row-flex">
          <div className="col-8">
            <strong>División</strong>
          </div>
          <div className="col-5">
            <select
              name="division"
              value={form.division}
              onChange={handleChangeForm}
              disabled={detFMI && detFMI.length > 0}>
              <option value=""></option>
              {
                division && division.map((divi) => (
                  <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                ))
              }
            </select>
          </div>
          <div className="col-8">
            <strong>Línea</strong>
          </div>
          <div className="col-5">
            <select
              name="linea"
              value={form.linea || ""}
              onChange={handleChangeForm}
              disabled={detFMI && detFMI.length > 0}>
              <option value=""></option>
              {
                linea && linea.filter((linF) => linF.CodDivision === form.division || "").map((lin) => (
                  <option key={lin.CodLinea} value={lin.CodLinea}>{lin.NomLinea}</option>
                ))
              }
            </select>
          </div>
          <div className="col-8">
            <strong>Modalidad</strong>
          </div>
          <div className="col-5 flex-grow">
            <select
              name="modalidad"
              value={form.modalidad}
              onChange={handleChangeForm}
              disabled={detFMI && detFMI.length > 0}>
              <option value=""></option>
              {
                modalidad && modalidad.filter((modF) => modF.CodLinea === form.linea || "").map((mod) => (
                  <option key={mod.CodModalidad} value={mod.CodModalidad}>{mod.NomModalidad}</option>
                ))
              }
            </select>
          </div>
        </div>
        {
          codFMI && (
            <>
              <div className="row-flex">
                <div className="col-8">
                  <strong>Documentación</strong>
                </div>
                <div className="col-5">
                  <button type="button" className="btn-docs">
                    <Folder />
                  </button>
                </div>
              </div>
              <div className="row-flex">
                <div className="col-8">
                  <strong>Agregar equipo</strong>
                </div>
                <div className="col-5">
                  <button type="button" className="btn-docs" onClick={() => handleChangeState("modalEqp", true)}>
                    <FileMarkPlus />
                  </button>
                </div>
                <div className="col-8">
                  <strong>Visualizar Kit</strong>
                </div>
                <div className="col-5">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="viewKit"
                    checked={form.viewKit}
                    onChange={handleChangeForm} />
                </div>
                <div className="col-8">
                  <strong>Abrir llamadas de inspección</strong>
                </div>
                <div className="col-5">
                  <button type="button" className="btn-docs" onClick={() => handleCreateFMI("c-3")}>
                    <FileMarkPlus />
                  </button>
                </div>
              </div>
            </>
          )
        }

        <div className="row-flex j-content-space-around m-y-small">
          {
            codFMI ? (<button type="button" className="btn-primary" onClick={() => handleCreateFMI("up-1")}>Guardar</button>) : (<button type="button" className="btn-primary" onClick={() => handleCreateFMI("c-1")}>Guardar</button>)
          }
          <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
        </div>

        {
          // aca va el renderizado de la tabla del detalle
          detFMI && detFMI.length > 0 && (
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th></th>
                    <th>Cliente</th>
                    <th>Modelo</th>
                    <th>{form.reviewBy === "1" ? "Serial" : "SID"}</th>
                    <th>ID</th>
                    <th>Comentario</th>
                    <th>Evidencia</th>
                    <th>Comentario</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    detFMIEqp && detFMIEqp.map((det) => (
                      <tr key={det.CodEquipo}>
                        <td className="txt-center">
                          {
                            !det.CodServicio && (
                              <button type="button" className="btn-delete" onClick={() => handleCreateFMI("d-1", det.CodEquipo)}>
                                <Close />
                              </button>
                            )
                          }
                        </td>
                        <td>
                          <Link className="link-tag" to={`/customers/customer-detail/${det.CodCliente}`}>{det.NomCliente}</Link>
                        </td>
                        <td>{det.Modelo}</td>
                        <td className="txt-center">
                          <Link className="link-tag" to={`/equipments/equipment-detail/${det.CodEquipo}`}>
                            {form.reviewBy === "1" ? det.Serial : det.NumSistema}
                          </Link>
                        </td>
                        <td className="txt-center">
                          {
                            det.CodServicio && (<Link className="link-tag" to={`/service/service-call/${det.CodServicio}`}>{det.CodServicio}</Link>)
                          }
                        </td>
                        <td className="col-3">{det.Comentario}</td>
                        <td>
                          <div className="row-flex gap-small j-content-space-around">
                            <button type="button" className="btn-docs">
                              <Folder />
                            </button>
                            {
                              det.Evidencia && (
                                <button type="button" className="btn-pdf">
                                  <PDFIcon />
                                </button>
                              )
                            }
                          </div>
                          {
                            det.Evidencia && (
                              <select
                                name={`Code-${det.CodEquipo}`}
                                className="m-y-small"
                                value={det.IdCodigo}
                                onChange={({ target }) => handleChangeDetFMI(det.CodEquipo, target.value, "IdCodigo")}>
                                <option value="0">En ejecución</option>
                                <option value="1">Completado</option>
                                <option value="2">No requerido</option>
                                <option value="3">Ejecutado</option>
                                <option value="4">Rechazado</option>
                                <option value="5">Ubicación desconocida</option>
                              </select>
                            )
                          }
                        </td>
                        <td className="col-5">
                          <textarea
                            className="textarea-description"
                            name={`comment-${det.CodEquipo}`}
                            value={det.Comment}
                            onChange={({ target }) => handleChangeDetFMI(det.CodEquipo, target.value, "Comment")} />
                        </td>
                        <td className="txt-center">
                          <input
                            className="input-control-checkbox"
                            type="checkbox"
                            name={`check-${det.CodEquipo}`}
                            checked={det.Selected}
                            onChange={({ target }) => handleChangeDetFMI(det.CodEquipo, target.checked, "Selected")} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        }

        {
          state.modalQuest && (
            <ModalConfirm question={state.question} onClose={handleCloseConfirm} sending={state.sending} />
          )
        }
      </form>
    </div>
  );
};