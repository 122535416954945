

import React, { useContext } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { formatCurrency, getTheToken, renderDate, renderTemperature } from "../../global-functions/globalFunctions.js";

// React translation importation
import { useTranslation } from "react-i18next";
import { useFetchNotsEntrega } from "../../components/customs/warehouse/useFetchNotsEntrega.js";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";



export const NotaEntregaDetailView = () => {


  // language context
  const { lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);


  // GET react translation
  const [t] = useTranslation("global");

  // usenavigate
  const navigate = useNavigate();

  // useparams
  const { codSolRepuesto } = useParams();

  // location
  const location = useLocation();


  // GET the useFetchNotsEntrega
  const { state, form, partes, employees, stateSol, handleChangeForm, handleChangeSerial, onCloseModal, onHandleSendModal } = useFetchNotsEntrega(codSolRepuesto, lang);


  const CONDUCE = 40; // ETAPA DE CONDUCE



  // Funcion para abrir el modal al dar click en por despachar
  // const onHandleDispatchModal = () => {
  //   setStateForm((val) => ({ ...val, primaryAction: "2" }));

  //   // Abrir el modal
  //   setState((val) => ({ ...val, showModal: true }));

  //   setQuestionAction(t("RequestPartsView.enviar_sol_despa_pregunta"));
  // };



  // Horario de envio
  const RENDER_HORARIO = {
    0: t("RequestPartsView.en_la_ma"),
    1: t("RequestPartsView.en_la_tar"),
    2: t("RequestPartsView.en_la_noc")
  };



  // Funcion para enviar el formulario al backend
  const onCreateDeliveryNote = async (event) => {
    event.preventDefault();

    // Verificar que la forma de envio, enviado a y tecnico a enviar estén seleccionado
    if (form.formaEnvio === "") {
      return handleNotify(true, t("RequestPartsView.forma_envio"), true);
    }

    if (form.enviadoA === "") {
      return handleNotify(true, t("RequestPartsView.selec_envia"), true);
    }

    // Objeto final a enviar
    const finalObj = { form, partes };

    try {
      const response = await fetch(`${urls.notaEntrega}/${codSolRepuesto}/search?language=${lang}&print=0`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true);
        navigate("/warehouse/principal");
      } else if (response.status === 201) {
        handleNotify(true, data?.msg, false, true);
        navigate("/warehouse/principal");
        window.open(`/warehouse/delivery-notes-print/${data?.codNotEntr}`);
      } else if (response.status === 400) {
        handleNotify(true, data?.msg, true);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        handleNotify(true, data?.msg, true);
      }

    } catch (error) {
      console.log(error);
    }
  };



  return (
    <>
      {/* Unauthorized component */}
      {state.unauthorized && (<Error403View />)}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onCreateDeliveryNote}>
              <div className="row-flex">
                <div className="col-8">
                  <strong>{t("RequestPartsView.soli")}</strong>
                </div>
                <div className="col-5">
                  <h4>{stateSol?.CodSolRepuesto}</h4>
                </div>
                <div className="col-10">
                  <strong>{t("RequestPartsView.id_serv")}</strong>
                </div>
                <div className="col-8">
                  <Link className="link-tag" to={`/service/service-call/${stateSol?.CodServicio}`}>{stateSol?.CodServicio}</Link>
                </div>
                <div className="col-10">
                  <strong>{t("RequestPartsView.modelo")}</strong>
                </div>
                <div className="col-7">
                  <span>{stateSol?.Modelo}</span>
                </div>
                <div className="col-10">
                  <strong>Serial</strong>
                </div>
                <div className="col-16-70 text-hidden-over">
                  <span>{stateSol?.Serial}</span>
                </div>
              </div>

              <div className="row-flex">
                <div className="col-8">
                  <strong>{t("RequestPartsView.fecha")}</strong>
                </div>
                <div className="col-40-5">
                  <span>{renderDate(stateSol?.FecIngreso, lang)}</span>
                </div>
                <div className="col-10">
                  <strong>{t("RequestPartsView.supli")}</strong>
                </div>
                <div className="col-7 text-hidden-over">
                  <span>{stateSol?.Marca}</span>
                </div>
                <div className="col-10">
                  <strong>{t("RequestPartsView.prio")}</strong>
                </div>
                <div className="col-16-70" style={{ display: "flex", alignItems: "center" }}>
                  <span className={renderTemperature(stateSol?.Prioridad)}></span>
                </div>
              </div>

              <div className="row-flex">
                <div className="col-8">
                  <strong>{t("RequestPartsView.cliente")}</strong>
                </div>
                <div className="col-40-5 text-hidden-over">
                  <Link className="link-tag" to={`/customers/customer-detail/${stateSol?.CodCliente}`}>{stateSol?.NomCliente}</Link>
                </div>
                <div className="col-10">
                  <strong>GON</strong>
                </div>
                <div className="col-7">
                  <span>{stateSol?.FDO}</span>
                </div>
                <div className="col-10">
                  <strong>{t("LogisticViewComp.instalation")}</strong>
                </div>
                <div className="col-16-70">
                  <span>{renderDate(stateSol?.FecInstalacion, lang)}</span>
                </div>
              </div>

              <div className="row-flex">
                <div className="col-8">
                  <strong>{t("RequestPartsView.equip")}</strong>
                </div>
                <div className="col-87-49">
                  <Link className="link-tag" to={`/equipments/equipment-detail/${stateSol?.CodEquipo}`}>{stateSol?.Equipo}</Link>
                </div>
              </div>

              <div className="row-flex">
                <div className="col-8">
                  <strong>{t("RequestPartsView.ing_tec")}</strong>
                </div>
                <div className="col-87-49">
                  <span>{stateSol?.NomEmpleado}</span>
                </div>
              </div>

              <div className="row-flex">
                <div className="col-8">
                  <strong>{t("RequestPartsView.falla")}</strong>
                </div>
                <div className="col-87-49">
                  <span style={{ textDecoration: 'underline', color: 'var(--danger-color)' }}>{stateSol?.Falla}</span>
                </div>
              </div>

              <div className="row-flex">
                <div className="col-8">
                  <strong>{t("RequestPartsView.ubicacion")}</strong>
                </div>
                <div className="col-87-49">
                  <span>{stateSol?.Ubicacion}</span>
                </div>
              </div>



              {/* FILA 8 */}
              <div className="row-flex">
                {/* OBSERVACION */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.obser")}</strong>
                </div>
                <div className="col-87-49">
                  <span style={{ color: 'var(--green-color)', textDecoration: 'underline', fontWeight: 'bold' }}>{stateSol?.Comment}</span>
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* REQUERIDO PARA EL */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.requerido_para")}</strong>
                </div>
                <div className="col-34-8">
                  {
                    stateSol?.FecRequerida === null || stateSol?.FecRequerida === ""
                      ? (<h4 className="required">{t("RequestPartsView.no_especificado")}</h4>)
                      : (<span style={{ color: 'var(--money-color)', fontWeight: 'bold' }}>{renderDate(stateSol?.FecRequerida, lang)} {RENDER_HORARIO[stateSol?.Horario] ?? "No se indicó el horario"}</span>)
                  }
                </div>
              </div>

              <hr className="m-y-small" />

              {/* FILA 10 */}
              <div className="row-flex">
                {/* NOTA DE ENTREGA */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.nota_entrega")}</strong>
                </div>
                <div className="col-34-8">
                  <span></span>
                </div>
                {/* TECNICO A ENVIAR */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.tec_enviar")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="tecnicoEnviar"
                    value={form.tecnicoEnv}
                    onChange={({ target }) => handleChangeForm("tecnicoEnv", target.value)}>
                    <option value=""></option>
                    {
                      employees && employees.map((emp) => (
                        <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 11 */}
              <div className="row-flex">
                {/* FORMA DE ENVIO */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.form_envio")}</strong>
                </div>
                <div className="col-34-8">
                  <select
                    name="formaEnvio"
                    value={form.formaEnvio}
                    onChange={({ target }) => handleChangeForm("formaEnvio", target.value)}>
                    <option value=""></option>
                    <option value="0">Personal</option>
                    <option value="1">{t("RequestPartsView.trans_inter")}</option>
                    <option value="2">{t("RequestPartsView.trans_ext")}</option>
                    <option value="3">Taxi</option>
                  </select>
                </div>
                {/* ENVIADO A */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.enviado_a")}</strong>
                </div>
                <div className="col-3">
                  <select
                    className={form.formaEnvio === "" ? "wrapper" : ""}
                    name="enviadoA"
                    value={form.enviadoA}
                    onChange={({ target }) => handleChangeForm("enviadoA", target.value)}
                    disabled={form.formaEnvio === ""}>
                    <option value=""></option>
                    <option value="0">{t("RequestPartsView.tecnico")}</option>
                    <option value="1">{t("RequestPartsView.cliente")}</option>
                    <option value="2">{t("RequestPartsView.otro")}</option>
                  </select>
                </div>
              </div>

              {/* FILA 12 */}
              <div className="row-flex m-y-small">
                {/* COMENTARIOS */}
                <div className="col-8">
                  <strong>{t("RequestPartsView.comentario")}</strong>
                </div>
                <div className="flex-auto">
                  <textarea
                    className="textarea-description"
                    name="comentario"
                    value={form.comment}
                    onChange={({ target }) => handleChangeForm("comment", target.value)}
                    autoComplete="off"
                    placeholder={t("RequestPartsView.si_tiene_comen")} />
                </div>
              </div>

              <hr className="m-y-small" />

              {/* Tabla para mostrar las partes */}
              <div className="table-container-mobile m-y-medium">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>{t("TableParts.canti")}</th>
                      <th>{t("TableParts.existe")}</th>
                      <th>{t("TableParts.orden")}</th>
                      <th>{t("TableParts.parte")}</th>
                      <th>{t("TableParts.descrip")}</th>
                      <th>Serial</th>
                      <th>{t("TableParts.precio")}</th>
                      <th>{t("RequestPartsView.alma")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      partes && partes.map((part, index) => (
                        <tr key={part.CodDetSolRepuesto}>
                          <td className="txt-center">{index + 1}</td>
                          <td className="txt-center col-10">{part.Cantidad}</td>
                          <td>{ }</td>
                          <td className="txt-center">
                            <Link className="link-tag">{part.NumOrden}</Link>
                          </td>
                          <td className="col-7">
                            <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte} ({part.Alternativo})</Link>
                          </td>
                          <td className="col-4">{part.Descripcion}</td>
                          <td className="col-9">
                            <input
                              className="input-text-control"
                              type="text"
                              name={`serial-${part.CodDetSolRepuesto}`}
                              value={part.Serial ?? ""}
                              onChange={({ target }) => handleChangeSerial(part.CodDetSolRepuesto, part.CodSolRepuesto, target.value)}
                              autoComplete="off" />
                          </td>
                          <td style={{ color: 'var(--money-color)', fontWeight: 'bold' }} className="txt-center">
                            {formatCurrency(part.Precio * part.Cantidad, 'USD')}
                          </td>
                          <td className="txt-center">{part.CanAlmacen}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              {/* Botones de accion */}
              <div className="row-flex j-content-space-around m-y-medium">
                <button type="button" className="btn-primary" onClick={onHandleSendModal}>{t("RequestPartsView.enviar")}</button>
                <button type="button" className="btn-info" >{t("RequestPartsView.por_despa")}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
              </div>

              {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
              {
                state.showModal && (
                  <ModalConfirm onClose={onCloseModal} question={state.question} />
                )
              }
            </form>
          )
        }
      </div>
    </>
  );
};