// componente para poder filtrar las llamadas

import { useEffect, useState, useContext } from "react";

// api url import
import { urls } from "../../api-urls/api-urls.js";

// react router dom import
import { useSearchParams } from "react-router-dom";

// react translation import
import { useTranslation } from "react-i18next";
import { getTheToken } from "../../global-functions/globalFunctions.js";


// notification context import
import { NotificationContext } from "../../context/NotificationContext.jsx";





export const ServiceFilter = ({ onClose, lang = "", setNewState }) => {

  // GET translation
  const [t] = useTranslation("global");

  // Context notification
  const { handleNotify } = useContext(NotificationContext);


  // useSearchParams
  const [params, setParams] = useSearchParams();


  // state definition
  const [dataServ, setDataServ] = useState([]);
  const [controller, setController] = useState(null);
  const [pageSize] = useState(20);
  const [state, setState] = useState({ isLoading: false, searching: false });
  const [form, setForm] = useState({
    serviceId: params.get("id_serv") ?? "", customerName: params.get("nom_cus") ?? "", company: params.get("empr") ?? "",
    zone: params.get("area") ?? "", temperature: "", responsible: params.get("respon") ?? "",
    step: params.get("nom_step") ?? "", serviceType: params.get("tip_serv") ?? "1", division: params.get("nom_div") ?? "",
    line: params.get("nom_line") ?? "", modality: params.get("nom_mod") ?? "", reportsNumber: params.get("num_rep") ?? "",
    grupo: params.get("grp") ?? "", sort: params.get("sort_by") ?? "", order: params.get("order_by") ?? ""
  });


  // destructuring data server
  const { allSteps, divisions, lines, modalidad, zona, empPerm } = dataServ && dataServ;


  // order_by
  const ORDER_FILTER = [
    {
      id: "dta_old",
      fk: "dto",
      name: t("newCallComp.mas_anti")
    },
    {
      id: "dta_nws",
      fk: "dto",
      name: t("newCallComp.mas_nueva")
    },
    {
      id: "eqp_a",
      fk: "eqp",
      name: "A-Z"
    },
    {
      id: "eqp_z",
      fk: "eqp",
      name: "Z-A"
    },
    {
      id: "cst_a",
      fk: "cst",
      name: "A-Z"
    },
    {
      id: "cst_z",
      fk: "cst",
      name: "Z-A"
    },
    {
      id: "dta_old",
      fk: "dta",
      name: t("newCallComp.mas_anti")
    },
    {
      id: "dta_nsw",
      fk: "dta",
      name: t("newCallComp.mas_nueva")
    }
  ];


  // funcion para limpiar los filtros
  const onClearFilter = () => {
    // localStorage.setItem("servFilt", JSON.stringify(filterInputs));
    params.set("id_serv", "");
    params.set("nom_cus", "");
    params.set("empr", "");
    params.set("area", "");
    params.set("respon", "");
    params.set("nom_step", "");
    params.set("tip_serv", "");
    params.set("nom_div", "");
    params.set("nom_line", "");
    params.set("nom_mod", "");
    params.set("num_rep", "");

    setParams(params);

    setForm((val) => ({
      ...val, serviceId: "", customerName: "", company: "", zone: "",
      temperature: "", responsible: "", step: "", division: "", line: "",
      modality: "", reportsNumber: ""
    }));
  };


  // funcion para cambiar los valores del filtro
  const onHandleChangeVal = (newVal = "", formN = "", paramsN = "") => {
    // params.set("filt", 1);

    if (formN === "servoceId") {
      if (isNaN(newVal)) {
        return;
      }
    }

    setForm((val) => ({ ...val, [formN]: newVal }));
    params.set(paramsN, newVal);

  };



  // Funcion para realizar el submit del formulario
  const onSearchCall = async (event) => {
    event.preventDefault();

    if (!params.get("filt")) {

      if (!params.get("empr") && empPerm) {
        params.set("empr", empPerm[0]?.CodEmpresa);
      }
      if (!params.get("area") && empPerm) {
        params.set("area", empPerm[0]?.CodZona);
      }
      if (!params.get("grp") && empPerm) {
        params.set("grp", empPerm[0]?.CodGrupo);
      }
      if (!params.get("respon") && empPerm && [1, 10].includes(empPerm[0]?.CodPerfil) && !empPerm[0]?.Coordinador) {
        params.set("respon", empPerm[0]?.NomEmpleado);
      }

      if (!params.get("tip_serv")) {
        params.set("tip_serv", form.serviceType);
      }

      params.set("filt", 1);
    }
    setParams(params);

    try {
      setState((val) => ({ ...val, searching: true }));
      const response = await fetch(`${urls.calls}?language=${lang}&empresa=${form.company}&zona=${form.zone}&cliente=${form.customerName}&responsable=${form.responsible}&division=${form.division}&linea=${form.line}&modalidad=${form.modality}&reportes=${form.reportsNumber}&tipServ=${form.serviceType}&etapa=${form.step}&serviceId=${form.serviceId}&page=1&pageSize=${pageSize}&grupo=${form.grupo}&sort=${form.sort}&order=${form.order}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        }
      });


      const data = await response.json();

      if (response.status === 200) {
        let currentPage = 2;
        let moreD = false;

        if (data?.total < pageSize) {
          moreD = true;
          currentPage = 1;
        }

        setNewState(data?.serviceCalls, currentPage, data?.total, moreD);
        onClose(); // cerrar el modal

      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        // return navigate("/");
      } else if (response.status === 404) {
        handleNotify(true, data?.msg, true);
      } else if (response.status === 500) {
        // setNotification({ show: true, msg: data?.msg, error: true, great: false });
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar la informacion al servidor
  const fetchDataServ = async () => {

    const END_POINT = `${urls.calls}?language=${lang}&action=1`;

    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
      }

      setState((val) => ({ ...val, isLoading: true }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cargar la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);


  useEffect(() => {
    if (empPerm && params.get("filt") === null) {
      // setear los valores en el estado de los filtros
      const Zona = empPerm[0]?.CodZona ? empPerm[0]?.CodZona : "";

      if ([1, 10].includes(empPerm[0]?.CodPerfil) && !empPerm[0]?.Coordinador) {
        setForm((val) => ({ ...val, company: empPerm[0]?.CodEmpresa, zone: Zona, responsible: empPerm[0]?.NomEmpleado }));
      } else {
        setForm((val) => ({ ...val, company: empPerm[0]?.CodEmpresa, zone: Zona }));
      }
      setForm((val) => ({ ...val, grupo: empPerm[0]?.CodGrupo }));
    }
  }, [empPerm]);



  return (
    <div className="background-modal">
      <form onSubmit={onSearchCall} className="container-select-customer">
        <div className="modal-header">
          <h3>{t("newCallComp.busqueda")}</h3>
          <button type="reset" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>

        <div className="modal-body">
          <div className="row-flex">
            <div className="col-4">
              <strong>{t("serviceCard.serviceNumber")}</strong>
              <input
                className="input-text-control"
                type="text" value={form.serviceId}
                name="numServ"
                onChange={({ target }) => onHandleChangeVal(target.value, "serviceId", "id_serv")}
                placeholder="ID"
                autoComplete="off" />
            </div>
            <div className="col-3 flex-grow">
              <strong>{t("serviceCard.customer")}</strong>
              <input
                className="input-text-control"
                type="text"
                name="customer"
                value={form.customerName}
                onChange={({ target }) => onHandleChangeVal(target.value, "customerName", "nom_cus")}
                autoComplete="off" />
            </div>
            <div className="col-4">
              <strong>{t("serviceCard.company")}</strong>
              <select
                name="company"
                value={form.company}
                onChange={({ target }) => onHandleChangeVal(target.value, "company", "empr")}>
                <option value=""></option>
                <option value="1">Global Medica</option>
                <option value="2">GMD Healthcare Ltd</option>
              </select>
            </div>
          </div>

          {/* FILA 2 */}
          <div className="row-flex">
            <div className="col-4">
              <strong>{t("serviceCard.area")}</strong>
              <select
                name="area"
                value={form.zone}
                onChange={({ target }) => onHandleChangeVal(target.value, "zone", "area")}>
                <option value=""></option>
                {
                  zona && zona.filter((filtZ) => filtZ.CodEmpresa.toString() === form.company).map((zon) => (
                    <option key={zon.CodZona} value={zon.CodZona}>{zon.NomZona}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-4 flex-grow">
              <strong>{t("serviceCard.temperature")}</strong>
              <select name="temperature">
                <option value=""></option>
                <option style={{ backgroundColor: 'var(--green-color)', color: 'var(--white-color)' }} value="0">Operativo</option>
                <option value=""></option>
                <option value=""></option>
              </select>
            </div>
            <div className="col-4">
              <strong>{t("serviceCard.responsible")}</strong>
              <input
                className="input-text-control"
                type="text"
                name="respons"
                value={form.responsible}
                onChange={({ target }) => onHandleChangeVal(target.value, "responsible", "respon")}
                autoComplete="off" />
            </div>
          </div>

          {/* FILA 4 */}
          <div className="row-flex">
            <div className="col-4">
              <strong>{t("serviceCard.step")}</strong>
              <select
                name="step"
                value={form.step}
                onChange={({ target }) => onHandleChangeVal(target.value, "step", "nom_step")}>
                <option value=""></option>
                {
                  allSteps && allSteps.map((step) => (
                    <option key={step.IdEtapa} value={step.IdEtapa}>{step.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-3 flex-grow">
              <strong>{t("serviceCard.serviceTypeSelect")}</strong>
              <select
                name="serviceType"
                value={form.serviceType}
                onChange={({ target }) => onHandleChangeVal(target.value, "serviceType", "tip_serv")}>
                <option value="0">{t("serviceCard.all")}</option>
                <option value="1">{t("serviceCard.correctiveMaint")}</option>
                <option value="2">{t("serviceCard.preventMaint")}</option>
                <option value="6">{t("serviceCard.workshop")}</option>
                <option value="3">{t("serviceCard.installation")}</option>
                <option value="4">FMI</option>
                <option value="5">{t("serviceCard.applications")}</option>
                <option value="7">{t("serviceCard.stockReplacement")}</option>
                <option value="8">{t("serviceCard.internalSupport")}</option>
                <option value="9">{t("serviceCard.proyecto")}</option>
                <option value="10">{t("serviceCard.preInstallation")}</option>
              </select>
            </div>
          </div>

          {/* FILA 5 */}
          <div className="row-flex">
            <div className="col-4">
              <strong>{t("serviceCard.division")}</strong>
              <select
                name="division"
                value={form.division}
                onChange={({ target }) => onHandleChangeVal(target.value, "division", "nom_div")}>
                <option value=""></option>
                {
                  divisions && divisions.map((divi) => (
                    <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-3 flex-grow">
              <strong>{t("serviceCard.line")}</strong>
              <select
                name="line"
                value={form.line}
                disabled={form.division === ""}
                onChange={({ target }) => onHandleChangeVal(target.value, "line", "nom_line")}>
                <option value=""></option>
                {
                  lines && lines.filter((filt) => filt.CodDivision === form.division).map((line) => (
                    <option key={line.CodLinea} value={line.CodLinea}>{line.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-4">
              <strong>{t("serviceCard.modality")}</strong>
              <select
                name="modality"
                className={form.line === "" ? "wrapper" : ""}
                disabled={form.line === ""}
                value={form.modality}
                onChange={({ target }) => onHandleChangeVal(target.value, "modality", "nom_mod")}>
                <option value=""></option>
                {
                  modalidad && modalidad.filter((filtMo) => filtMo.CodLinea === form.line).map((mod) => (
                    <option key={mod.CodModalidad} value={mod.CodModalidad}>{mod.Nombre}</option>
                  ))
                }
              </select>
            </div>

          </div>

          {/* FILA 6 */}
          <div className="row-flex">
            <div className="col-4">
              <strong>{t("serviceCard.reports")}</strong>
              <input
                className="input-text-control"
                type="text"
                name="reportsNum"
                value={form.reportsNumber}
                onChange={({ target }) => onHandleChangeVal(target.value, "reportsNumber", "num_rep")}
                autoComplete="off" />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-4">
              <strong>Llamadas de</strong>
              <select
                name="grup"
                value={form.grupo}
                onChange={({ target }) => onHandleChangeVal(target.value, "grupo", "grp")}>
                <option value="">{t("newCallComp.todos")}</option>
                <option value="1">{t("navigationMenu.service")}</option>
                <option value="3">{t("newCallComp.ventas")}</option>
              </select>
            </div>
          </div>

          <hr className="m-y-small" />

          <div className="row-flex">
            <div className="col-5">
              <strong>{t("newCallComp.order_por")}</strong>
            </div>
            <div className="col-4">
              <select
                name="sort"
                value={form.sort}
                onChange={({ target }) => onHandleChangeVal(target.value, "sort", "sort_by")}>
                <option value=""></option>
                <option value="dto">{t("newCallComp.fec_aper")}</option>
                <option value="eqp">{t("LogisticViewComp.equipment")}</option>
                <option value="cst">{t("WareHouseView.customer")}</option>
                <option value="dta">{t("newCallComp.fec_accion")}</option>
              </select>
            </div>
            <div className="col-7">
              <select
                name="order"
                value={form.order}
                onChange={({ target }) => onHandleChangeVal(target.value, "order", "order_by")}>
                <option value="">{t("newCallComp.order")}</option>
                {
                  ORDER_FILTER && ORDER_FILTER.filter((filt) => filt.fk === form.sort).map((ord) => (
                    <option key={ord.id} value={ord.id}>{ord.name}</option>
                  ))
                }
              </select>
            </div>
          </div>
        </div>

        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success" disabled={state.searching}>{state.searching ? t("LoginView.cargando") : t("serviceCard.buscar")}</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>{t("serviceCard.cerrar")}</button>
          <button type="button" className="btn-warning">{t("serviceCard.limpiar_filtro")}</button>
        </div>
      </form>
    </div>
  );
};