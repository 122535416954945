import React, { useState, useContext } from "react";

// ----> Imports from react-router-dom
import { Link, useNavigate, useParams } from "react-router-dom";

// ----> Import for translation
import { useTranslation } from "react-i18next";

// ----> Components importation
import { ErrorNotification } from "../error-notification/ErrorNotification.jsx";
import { Error403View } from "../../views/error-403-view/Error403View.jsx";
import { UploadServReportComp } from "./UploadServReportComp.jsx";
import { GeneratePOD } from "./GeneratePOD.jsx";
import { SegPartsTable } from "./SegPartsTable.jsx";
import { SegToolsTable } from "./SegToolsTable.jsx";
import { TemperSelect } from "./TemperSelect.jsx";
import { LoadingComp } from "../Loading-comp/LoadingComp.jsx";
import { AddScheduleComp } from "./AddScheduleComp.jsx";
import { UploadServiceDocs } from "./UploadServiceDocs.jsx";
import { UploadServQuot } from "./UploadServQuot.jsx";


// icons import
import { ArrowClockIcon, BookIcon, BookMarkIcon, Close, Folder, PDFIcon, SearchIcon, SpinnerIcon, ToolIcon, TrashIcon } from "../icons/AppIcons.jsx";


// Context components
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";


// ----> URL IMPORTATIONS
import { urls } from "../../api-urls/api-urls.js";

// ----> GLOBAL FUNCTIONS
import { renderDate, getTheToken } from "../../global-functions/globalFunctions.js";

// IMPORT CSS STYLES
import "../../components/serviceComponents/ServiceCallsDetails.css";

// custom hooks import
import { useDownloadDocs } from "../customs/useDownloadDocs.js";
import { useFetchServCall } from "../customs/service/useFetchServCall.js";




export const ServiceCallsDetails = ({ grupo }) => {

  // lang context
  const { lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  const { id } = useParams();

  const EndPoint = `${urls.calls}/${id}/search?language=${lang}`;

  // custom hook component
  const { form, state, stateServCall, dataServ, ETAPAS, handleChangeForm, handleChangeTemp, handleChangeState, handleOpenCloseServReport } = useFetchServCall(EndPoint, getTheToken().token, lang, id)

  // STATE DEFINITION
  const [badRequestError, setBadRequestError] = useState({ show: false, msg: "", urlMsg: "", url: false, otherMsg: "" }); // este estado es para que se muestre cuando ocurra un bad request 400
  const [caFile, setCAFile] = useState(null); // estado para cargar el documento de la ca en el estado y luego enviar al servidor
  const [documentType, setDocumentType] = useState(""); // para setear el tipo de documento que se subira
  const [employees, setEmployees] = useState([]);

  // DESTRUCTURING DATA SERVER
  const {
    segIdCall,
    allSteps,
    empPerm,
    serviceReports,
    cotPend,
    PoderCerrar,
    tienePOD,
    reporAplica,
    docuAplicaciones,
    CADoc,
    PODER_PROGRAMAR,
    PENDIENTE_FACTURAR,
    SE_PUEDE_CERRAR,
    tienePacking,
    EXIST_REPUESTO,
    MsgError,
    Quotations,
    // tieneGarantia
  } = dataServ && dataServ;


  // custom hook to download docs
  const { downLoadFile, stateS } = useDownloadDocs();


  // DEFINICION DE CONSTANTE DE ETAPAS DE SERVICIO 
  const ASIG_TECNICO = 2; // Asignacion de tecnico
  const SOL_REPUESTO = 10; // Solicitud de repuesto
  const APRO_TECNICA = 11; // aprobacion tecnica
  const APRO_ADMIN = 12; // aprobacion administrativa
  const SOL_PRECIO = 13; // Solicitud de precio
  const COTIZACION = 14; // Cotizacion
  const APRO_SOPORTE = 18; // Aprobacion del soporte
  const ORDEN_COMPRA = 20; // Orden de compra
  const ESPERA_REPUESTO = 21; // Espera de repuesto
  const CERRAR_SERVICIO = 25; // Cerrar Servicio
  const PIEZA_PERDIDA = 28; // Pieza perdida
  const ACUSE_RECIBO = 30; // Acuse de recibo
  const GUIA_DESPA = 31; // Guia de despacho
  const RECIBO_ALMACEN = 32; // Recibo de almacen
  const FEC_DESPACHO = 36; // Fecha de despacho
  const EN_TRANSITO = 37; // En transito
  const NACIONALIZACION = 38; // Nacionalizacion
  const CONDUCE = 40; // Conduce
  const NOTA_RETIRO = 43; // Nota de retiro
  const SE_ENTREG_REP_CLIENTE = 46; // Se entregó repuesto al cliente
  const REC_POD = 78; // Recepcion del POD
  const EQUIP_BAJO_OBSER = 22; // Equipo bajo observacion
  const CULMINADO = 99; // Culminado
  const CERRAR_APLI = 61; // Cerrar aplicacion


  // etapas genericas
  const GENERIC_STEPS = [
    SOL_REPUESTO,
    APRO_TECNICA,
    APRO_ADMIN,
    SOL_PRECIO,
    ORDEN_COMPRA,
    COTIZACION,
    APRO_SOPORTE,
    ESPERA_REPUESTO,
    SE_ENTREG_REP_CLIENTE,
    ACUSE_RECIBO,
    GUIA_DESPA,
    RECIBO_ALMACEN,
    FEC_DESPACHO,
    EN_TRANSITO,
    NACIONALIZACION,
    PIEZA_PERDIDA,
    NOTA_RETIRO,
    CONDUCE
  ]; // en la renderizacion buscar por (ACCIONES PARA ETAPAS GENERICAS)


  // TIPOS DE SERVICIO
  const INSTALL = 3; // INSTALACION
  const APP = 5; // APLICACIONES
  const PRE_INSTALL = 10; // Pre instalacion
  const serviceTypes = [INSTALL, APP, PRE_INSTALL];

  // console.log(stateServCall)



  // console.log(stateForm)

  const handleSetEmployee = (empl = []) => {
    setEmployees(empl);
  };

  // CLOSE MODAL BAD REQUEST
  const onCloseModalBadRequest = () => {
    setBadRequestError({ show: false, msg: "", urlMsg: "", url: false, otherMsg: "" });
  };

  const GoToService = () => {
    if (process.env.NODE_ENV === "development") {
      return `/service/service-report/ul/${stateServCall[0]?.CodServicio}`;
    }
    return grupo === 1 ? `/service/service-report/${stateServCall[0]?.CodServicio}` : `/commercial/service/service-report/${stateServCall[0]?.CodServicio}`;
  };


  // funcion para manejar la carga del documento de la ca y demas documentos
  const handleFileChange = (event) => {

    const { target } = event;

    const file = target.files[0];
    setCAFile(file);
  };


  // Destructuring useTranslation() Hook
  const [t] = useTranslation("global");


  // ----> useNavigate
  const navigate = useNavigate();


  // funcion para generar la CA
  const handleCreateCADoc = (fileName = "") => {

    const END_POINT = `${urls.geneEquipDocs}/${stateServCall[0]?.CodEquipo}/search?language=${lang}&doc=ca`;

    downLoadFile(END_POINT, fileName, "application/pdf");
  };



  // ON SUBMIT SERVICE CALL 
  const onSaveIDCall = async (event) => {
    event.preventDefault();

    if (!form.action) {
      return handleNotify(true, t("serviceCard.selec_accion"), true);

    } else {

      if (Number(form.action) === 3 && (stateServCall[0]?.IdEtapa === 3 || stateServCall[0]?.IdEtapa === 4)) { // se programo la visita la fecha no debe estar vacia
        if (!form.fecVisit) {
          return handleNotify(true, t("serviceCard.selec_fec_visi"), true);
        }
      }

      if (stateServCall[0]?.IdEtapa === 5 && form.action === "3" && !form.fecVisit) {
        return handleNotify(true, t("newCallComp.select_fec_visi"), true);
      }

      // si es un comentario que solamente se hará en la llamada
      // if (Number(form.action) === 0 && form.comment.trim() === "") {
      //   return setNotification({
      //     show: true, msg: language === "en" ? "If you are making a comment, you should write something descriptive." : "Si realizará un comentario, debe escribir algo descriptivo.", errorNoti: true, great: false
      //   });
      // }

      // Verificar si estan intentando cerrar el servicio manualmente si la llamada está en la etapa de Recepcion del POD, porque hasta que no se cargue el POD no se puede cerrar la llamada
      if ((stateServCall[0]?.IdEtapa === REC_POD && Number(form.step) === CERRAR_SERVICIO && PoderCerrar && !tienePOD) || (stateServCall[0]?.IdEtapa === REC_POD && Number(form.step) === CULMINADO && PoderCerrar && !tienePOD)) {
        return handleNotify(true, t("serviceCard.cargar_pod"), true);
      }

      // Verificar que exista al menos un reporte de aplicacion para poder cerrar la llamada
      if ((stateServCall[0]?.IdEtapa === CERRAR_APLI && Number(form.step) === CERRAR_SERVICIO && !reporAplica) || (stateServCall[0]?.IdEtapa === CERRAR_APLI && Number(form.step) === CULMINADO && !reporAplica)) {
        return handleNotify(true, t("serviceCard.cargar_report_app"), true);
      }

      // cotPend
      if ((stateServCall[0]?.IdEtapa === EQUIP_BAJO_OBSER && Number(form.step) === CERRAR_SERVICIO && cotPend) || (stateServCall[0]?.IdEtapa === EQUIP_BAJO_OBSER && Number(form.step) === CULMINADO && cotPend)) {
        return handleNotify(true, t("newCallComp.coti_pend_facturar"), true);
      }

      // Verificar que si la llamada está en asignacion de tecnico, envien el tecnico encargado
      if (stateServCall[0]?.IdEtapa === ASIG_TECNICO && form.tecRespon === "") {
        return handleNotify(true, t("newCallComp.seleccionar.tec_respon"), true);
      }

      // si la etapa es Programacion de preventivo
      if (stateServCall[0]?.IdEtapa === 29 && form.fecVisit === "" && form.action === "45") {
        return handleNotify(true, t("newCallComp.select_fec_visi"), true);
      }
    }


    // SI LLEGA HASTA ACA ES PORQUE SE PUEDE ACTUALIZAR LA DATA
    // setLoadDataServer(true);

    try {
      handleChangeState("sending", true); // para inhabilitar el boton de guardar

      const response = await fetch(`${urls.calls}/${id}/search?language=${lang}&grupoParams=${grupo}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      // console.clear();

      const data = await response.json();

      if (response.status === 200) {
        handleChangeState("reload", true)
        handleNotify(true, data?.msg, false, true);
      } else if (response.status === 201) {

      } else if (response.status === 404) {
        handleNotify(true, data?.msg, true);
      } else {
        handleNotify(true, data?.msg, true);
        // ocurrió un error al actualizar los datos
      }

      handleChangeState("sending", false); // para volver al estado normal

    } catch (error) {
      // console.log(error);
    }

  };





  // funcion para buscar una nueva llamada por su id
  const onSearchCall = (event) => {
    event.preventDefault();

    navigate(`/service/service-call/${form.idServic}`);

    // setState((val) => ({ ...val, modalSearchCall: false }));

  };


  // FUNCIONES PARA SOLICITAR O REALIZAR ACCIONES EXTRAS
  const downloadSerDoc = (docRequest, fileName = "") => {

    const END_POINT = `${urls.calls}/docs/${stateServCall[0]?.CodEquipo}/search?docRequest=${docRequest}`;

    downLoadFile(END_POINT, fileName, "application/pdf");
  };


  // funcion para descargar las cotizaciones de comercial
  const downLoadQuot = (idP = 0, fileName = "") => {
    const END_POINT = `${urls.calls}/docs/${id}/search?docRequest=quot&IdP=${idP}`;

    downLoadFile(END_POINT, fileName, "application/pdf");
  };


  // funcion para descargar el reporte de servicio
  const downloadServiceReportDoc = (codRepServ = 0, fileName = "") => {

    const END_POINT = `${urls.serviceReport}?codRepServ=${codRepServ}&language=${lang}&action=1`;

    downLoadFile(END_POINT, fileName, "application/pdf");
  };







  return (
    <>
      {/* SE ACTUALIZO LA LLAMADA CORRECTAMENTE */}

      {state.isLoading && (
        <LoadingComp />
      )}
      {/* ----> SI HAY UN ERROR <---- */}
      {
        badRequestError.show && (
          <div className="background-modal">
            <ErrorNotification
              errorDisplayMsg={badRequestError.msg}
              url={badRequestError.url}
              urlMsg={badRequestError.urlMsg}
              otherMsg={badRequestError.otherMsg}
              onClose={onCloseModalBadRequest} />
          </div>
        )
      }
      {state.unAuthorized && (<Error403View />)}

      {/* ----> VIEW FOR DESKTOP <---- */}
      {
        state.dataVisible && (
          <>
            <div className="display-data">
              <div className="container">
                <form onSubmit={onSaveIDCall}>
                  <div style={{
                    borderRadius: '0.3rem',
                    backgroundColor: 'rgba(0, 0, 0, 0.01)',
                    padding: '0.5rem 0.6rem 0.5rem 0.6rem',
                    boxShadow: 'var(--box-shadow-bottom)'
                  }}>



                    {/* ESTO ES UNA FILA PARA COLOCAR EL NOMBRE DEL CLIENTE, FECHA INGRESO DE LA LLAMADA Y LA ETAPA */}
                    <div className="row-flex m-y-small j-content-space-b">
                      {/* id de la llamada */}
                      <div className="row-flex col-5 align-c">
                        <h4>ID: {stateServCall[0]?.CodServicio}</h4>
                      </div>
                      {/* fecha que se ingresó */}
                      <div className="row-flex align-c col-4 col-70-sm">
                        <div className="col-4">
                          <strong>Date</strong>
                        </div>
                        <div className="flex-auto">
                          <span>{renderDate(stateServCall[0]?.FecIngreso, lang)}</span>
                        </div>
                      </div>
                      {/* ----> ETAPAS <---- */}
                      <div className="row-flex col-6 flex-end">
                        <div className="col-4">
                          <strong>{t("serviceCard.step")}</strong>
                        </div>
                        <div className="col-6">
                          {
                            (stateServCall[0]?.IdEtapa !== 99 && ![1, 8, 10].includes(empPerm[0]?.CodPerfil)) && (
                              <select
                                name="step"
                                value={form.step}
                                onChange={({ target }) => handleChangeForm("step", target.value)}>
                                <option value=""></option>
                                {
                                  allSteps && allSteps.map((step) => (
                                    <option key={step.IdEtapa} value={step.IdEtapa}>{step.NomEtapa}</option>
                                  ))
                                }
                              </select>
                            )
                          }
                          {
                            (stateServCall[0]?.IdEtapa === 99 || [1, 8, 10].includes(empPerm[0]?.CodPerfil)) && (<span>{stateServCall[0]?.NomEtapa}</span>)
                          }
                        </div>
                      </div>
                    </div>

                    {/* ESTO ES UNA FILA PARA COLOCAR EL NOMBRE DEL CLIENTE, TEMPERATURA Y EL ESTATUS DE LA LLAMADA */}
                    <div className="row-flex">
                      {/* NOMBRE DEL CLIENTE */}
                      <div className="col-8 col-sm-8">
                        <strong>Cliente</strong>
                      </div>
                      <div className="col-3 col-70-sm">
                        <Link
                          className="link-tag customer-name"
                          to={`/customers/customer-detail/${stateServCall[0]?.CodCliente}`}>
                          {stateServCall[0]?.Nombre}
                        </Link>
                      </div>
                      {/* TEMPERATURA DE LA LLAMADA */}
                      <div className="col-8 col-sm-8">
                        <strong>{t("newCallComp.temperature")}</strong>
                      </div>
                      <TemperSelect updateParent={handleChangeTemp} value={form.priority} />
                      {/* STATUS DE LA LLAMADA */}
                      <div className="col-8 status-label col-sm-8">
                        <strong style={{ marginRight: '0.3rem' }}>{t("serviceCard.status")}</strong>
                      </div>
                      <div className="col-10 col-70-sm">
                        <span style={{
                          backgroundColor: 'var(--primary-color)',
                          borderRadius: '0.2rem',
                          color: 'var(--white-color)',
                          padding: '0.2rem'
                        }}>{stateServCall[0]?.IdEstatus === 1 ? t("newCallComp.ejecutandose") : t("newCallComp.completado")}</span>
                      </div>
                    </div>

                    {/* FILA PARA LA COBRANZA */}
                    <div className="row-flex">
                      <div className="col-8 col-sm-8">
                        <strong>Cobranza</strong>
                      </div>
                      <div className="col-3 col-70-sm">
                        <Link className="link-tag">Estado de cuenta</Link>
                      </div>
                    </div>

                    {/* ESTO ES UNA FILA PARA EL EQUIPO, Y SELECCIONAR EL INGENIERO */}
                    <div className="row-flex">
                      <div className="col-8 col-sm-8">
                        <strong>{t("serviceCard.equipment")}</strong>
                      </div>
                      <div className="col-3 col-70-sm">
                        <select
                          name="equipment"
                          value={form.equipment}
                          onChange={({ target }) => handleChangeForm("equipment", target.value)}>
                          <option value=""></option>
                          {
                            stateServCall && stateServCall[0]?.equipments.map((equip) => (
                              <option key={equip.CodEquipo} value={equip.CodEquipo}>{equip.TipEquipo || ''} - {equip.Modelo || ''} - {equip.Serial || ''}</option>
                            ))
                          }
                        </select>
                        <div className="text-hidden-over">
                          <Link className="link-equipments" to={`/equipments/equipment-detail/${stateServCall[0]?.CodEquipo}`}>{stateServCall[0]?.nomEquipo}</Link>
                        </div>
                      </div>
                      <div className="col-8 col-sm-8">
                        <strong>{t("serviceCard.responsible")}</strong>
                      </div>
                      <div className="col-39-2 col-70-sm flex-grow">
                        <select
                          name="reponsablProgr"
                          value={form.responsibleEmployee}
                          onChange={({ target }) => handleChangeForm("responsibleEmployee", target.value)}>
                          <option value=""></option>
                          {
                            employees && employees.map((emplo) => (
                              <option key={emplo.CodEmpleado} value={emplo.CodEmpleado}>{emplo.NomEmpleado}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>

                    <div className="row-flex">
                      <div className="col-8 col-sm-8">
                        <strong>{t("serviceCard.problem")}</strong>
                      </div>
                      <div className="col-45-83 col-70-sm">
                        <textarea
                          style={{ userSelect: 'none', textTransform: 'capitalize' }}
                          className="textarea-description"
                          placeholder={t("serviceCard.problemPlaceHolder")}
                          value={form.problem}
                          readOnly />
                      </div>
                      <div className="col-39-2 col-sm-100-out flex-grow">
                        {/* aca va el location */}
                        <p style={{ padding: 0, margin: "0 0 4px 0" }} className="border-bottom">
                          <strong>{lang === "en" ? "Location" : "Ubicación"}: </strong>
                          <span>{stateServCall[0]?.Ubicacion}</span>
                        </p>
                        {
                          // Nro. Contrato
                          stateServCall[0]?.NumContrato && (
                            <>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.nro_cont")}:</strong>
                                <span> {stateServCall[0]?.NroContrato} {stateServCall[0]?.NumContrato}</span>
                              </p>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.duracion")}: </strong>
                                <span>{renderDate(stateServCall[0]?.FecInicio, lang)} - {renderDate(stateServCall[0]?.FecFin, lang)}</span>
                              </p>
                              <p style={{ padding: 0, margin: 0 }}>
                                <strong>{t("serviceCard.frecuencia")}: </strong>
                                <span>{stateServCall[0]?.Frecuencia}</span>
                              </p>
                            </>
                          )
                        }
                        {
                          stateServCall[0]?.FecEOL && (
                            <h4 style={{ color: "#ff0000" }}>{t("serviceCard.equipo_EOGS")}</h4>
                          )
                        }
                      </div>
                    </div>


                    {/* DATOS DEL CLIENTE LABEL */}
                    <h4 style={{ color: 'var(--first-color)' }}>{t("serviceCallSteps.customerData")}</h4>
                    <hr />

                    {/* ----> MOSTRAR LOS DATOS DEL CLIENTE <---- */}
                    <div className="row-flex align-c"> {/* ROW */}
                      {/* TIPO DE SERVICIO */}
                      <div className="col-8 col-sm-8">
                        <strong style={{ marginRight: '0.3rem' }}>{t("serviceCard.serviceTypeSelect")}</strong>
                      </div>
                      <div className="col-7 col-70-sm">
                        <select
                          name="tipServ"
                          value={form.idTipServicio}
                          onChange={({ target }) => handleChangeForm("idTipServicio", target.value)}>
                          <option value="1">{t("serviceCallSteps.correctiveMaint")}</option>
                          <option value="2">{t("serviceCallSteps.preventMaint")}</option>
                          <option value="4">FMI</option>
                          <option value="9">{t("serviceCallSteps.project")}</option>
                          <option value="10">{t("serviceCallSteps.preInstall")}</option>
                          <option value="3">{t("serviceCallSteps.install")}</option>
                          <option value="5">{t("serviceCallSteps.applications")}</option>
                          <option value="6">{t("serviceCallSteps.workshop")}</option>
                          <option value="7">{t("serviceCallSteps.stockRepo")}</option>
                          <option value="8">{t("serviceCallSteps.internalSupport")}</option>
                        </select>
                      </div>

                      {/* CORREO, TELEFONO DEL CLIENTE */}
                      <div className="col-5 col-sm-100-out">
                        <strong>{t("newCallComp.name")}:</strong>
                        <span style={{ textTransform: 'capitalize', marginLeft: '0.3rem' }}>{stateServCall[0]?.Encargado}</span>
                      </div>
                      {/* SEGUNDA COLUMNA */}
                      <div className="flex-auto col-45-sm col-sm-100-out">
                        <strong>{t("newCallComp.email")}:</strong>
                        <span style={{ marginLeft: '0.3rem', textDecoration: 'underline', color: 'blue' }}>
                          {stateServCall[0]?.Correo}
                        </span> |
                        <strong style={{ marginLeft: '0.3rem' }}>{t("newCallComp.cell")}</strong>
                        <span style={{ marginLeft: '0.3rem' }}>{stateServCall[0]?.Telefono}</span>
                      </div>
                    </div>
                  </div> {/* ESTE ES EL CIERRE DEL DIV DE LA TARJETA */}

                  {/* SI EL TIPO DE SERVICIO ES DE APLICACIONES (5) */}
                  {
                    serviceTypes.includes(stateServCall[0]?.IdTipServicio) && (
                      <>
                        <div className="m-y-small">
                          {/* FILA 1 */}
                          <div className="row-flex">
                            <div className="col-8">
                              <strong style={{ textDecoration: 'underline' }}>{t("newCallComp.docs")}</strong>
                            </div>
                          </div>
                          <div className="row-flex align-c">
                            <div className="col-9">
                              <strong>{t("serviceCard.documentos_cargar")}</strong>
                            </div>
                            <div className="col-10">
                              <button type="button" className="btn-docs" onClick={() => handleChangeState("modalDocs", true)}>
                                <Folder />
                              </button>
                            </div>
                            {/* documentos de CA */}
                            {
                              stateServCall[0]?.IdTipServicio === INSTALL && (
                                <>
                                  <div className="card-shadow row-flex align-c gap-small">
                                    <h4>CA</h4>
                                    {
                                      (CADoc && CADoc[0]?.Acta) && (
                                        <>
                                          <div className="">
                                            <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("caService", "ca.pdf")}>
                                              {
                                                stateS.isLoading ? (<ArrowClockIcon fillP="var(--first-color)" />) : (<PDFIcon />)
                                              }
                                            </button>
                                          </div>
                                          <div>
                                            <button type="button" className="btn-del">
                                              <TrashIcon />
                                            </button>
                                          </div>
                                        </>
                                      )
                                    }
                                    {
                                      CADoc && !CADoc[0]?.Acta && (<strong>No se ha cargado</strong>)
                                    }
                                  </div>
                                  <div className="col-4 flex-end row-flex gap-small m-left-auto-comp-serv">
                                    <div>
                                      <strong>{t("newCallComp.uploadComp")}</strong>
                                    </div>
                                    <div>
                                      <Link className="link-tag" to={`/service/service-equipment-components/${form.equipment}`}>{t("newCallComp.comp")}</Link>
                                    </div>
                                    <div>
                                      <button type="button" disabled={stateS.isLoading} style={{ cursor: "pointer" }} title="CA" onClick={() => handleCreateCADoc("ca.pdf")}>
                                        {
                                          stateS.isLoading ? (<ArrowClockIcon fillP="var(--first-color)" />) : (<BookMarkIcon fillP="var(--first-color)" />)
                                        }
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                            {
                              // si es pre instalacion mostrar el packing list y el pod para generar y descargar
                              stateServCall[0]?.IdTipServicio === PRE_INSTALL && (
                                <>
                                  <div className="row-flex col-80 j-content-space-b">
                                    <div className="col-4 row-flex">
                                      <div className="col-6">
                                        <strong>Packing List</strong>
                                      </div>
                                      <div className="col-6">
                                        {
                                          tienePacking && (
                                            <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("packingList", "packing list.pdf")}>
                                              {
                                                stateS.isLoading ? (<ArrowClockIcon fillP="var(--first-color)" />) : (<PDFIcon />)
                                              }
                                            </button>
                                          )
                                        }
                                      </div>
                                    </div>
                                    {/* POD */}
                                    <div className="row-flex col-3 align-c">
                                      <div className="col-3">
                                        <button type="button" className="btn-success" style={{ display: "flex", gap: "0.3rem", alignItems: "center" }} onClick={() => handleChangeState("modalPOD", true)}>
                                          POD
                                          <BookMarkIcon />
                                        </button>
                                      </div>
                                      {
                                        tienePOD && (
                                          <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("pod", "pod.pdf")}>
                                            {
                                              stateS.isLoading ? (<ArrowClockIcon fillP="var(--first-color)" />) : (<PDFIcon />)
                                            }

                                          </button>
                                        )
                                      }
                                    </div>
                                  </div>
                                </>
                              )
                            }

                          </div>
                          {/* Esto se renderiza unicamente si el tipo de llamada es instalacion */}

                          {/* Esto se renderiza unicamente si el tipo de llamada es aplicacion */}
                          {
                            stateServCall[0]?.IdTipServicio === APP && (
                              <div className="flex-auto row-flex">
                                {/* APLICACION 1 */}
                                <div className="col-5 col-sm-80 row-flex align-c gap-small">
                                  <div>
                                    <strong className="p-small">Aplicación Fase 1</strong>
                                  </div>

                                  {
                                    docuAplicaciones && docuAplicaciones[0]?.Aplicacion1 && (
                                      <div>
                                        <button type="button" className="btn-pdf" onClick={() => downloadSerDoc("Aplicacion1", "Aplication 1.pdf")}>
                                          {
                                            stateS.isLoading ? (<ArrowClockIcon fillP="var(--first-color)" />) : (<PDFIcon />)
                                          }

                                        </button>
                                      </div>
                                    )
                                  }
                                </div>

                                {/* APLICACION 2 */}
                                <div className="col-5 col-sm-80 row-flex align-c gap-small">
                                  <div>
                                    <strong className="p-small">Aplicación Fase 2</strong>
                                  </div>

                                  {
                                    docuAplicaciones && docuAplicaciones[0]?.Aplicacion2 && (
                                      <div>
                                        <button type="button" className="btn-pdf" onClick={() => downloadSerDoc("Aplicacion2", "Aplication 2.pdf")}>
                                          {
                                            stateS.isLoading ? (<ArrowClockIcon fillP="var(--first-color)" />) : (<PDFIcon />)
                                          }
                                        </button>
                                      </div>
                                    )
                                  }
                                </div>

                                {/* APLICACION 3 */}
                                <div className="col-5 col-sm-80 row-flex align-c gap-small">
                                  <div>
                                    <strong className="p-small">Aplicación Fase 3</strong>
                                  </div>

                                  {
                                    docuAplicaciones && docuAplicaciones[0]?.Aplicacion3 && (
                                      <div>
                                        <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadSerDoc("Aplicacion3", "Aplication 3.pdf")}>
                                          {
                                            stateS.isLoading ? (<ArrowClockIcon fillP="var(--first-color)" />) : (<PDFIcon />)
                                          }
                                        </button>
                                      </div>
                                    )
                                  }
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </>
                    )
                  }

                  { // Documentos para cargar de Comercial
                    stateServCall[0]?.CodGrupo === 3 && (
                      <div className="m-y-small">
                        <div className="row-flex gap-small align-c">
                          <strong>Cargar cotizacion</strong>
                          <button type="button" className="btn-docs" onClick={() => handleChangeState("modalCot", true)}>
                            <Folder />
                          </button>
                        </div>
                        {
                          Quotations && Quotations.length > 0 && (
                            <div className="m-y-small row-flex align-c gap-small">
                              {
                                Quotations && Quotations.map((quot) => (
                                  <button type="button" style={{ flexDirection: "column", alignItems: "center" }} key={quot.Id} className="btn-pdf" onClick={() => downLoadQuot(quot.Id, `${quot.Descripcion}.pdf`)}>
                                    <PDFIcon />
                                    {quot.Descripcion}
                                  </button>
                                ))
                              }
                            </div>
                          )
                        }
                      </div>
                    )
                  }

                  {/* TARJETA QUE SIEMPRE APARECE PARA AGREGAR INGENIEROS */}
                  <AddScheduleComp token={getTheToken().token} IdCall={id} setEmployee={handleSetEmployee} lang={lang} />

                  {/* el select siempre aparece */}
                  <div className="m-y-small">
                    <div className="row-flex">
                      <div className="col-8 col-sm-30">
                        <strong>{t("newCallComp.selectAction")}</strong>
                      </div>
                      <div className="col-4 col-sm-70-mod">
                        <select name="actionCall"
                          value={form.action}
                          onChange={({ target }) => handleChangeForm("action", target.value)}>
                          <option value=""></option>
                          { // Recepción del POD
                            stateServCall[0]?.IdEtapa === 78 && (
                              <>
                                {
                                  tienePOD && (<option value="2">{t("AsignacionTecnicoComp.closeService")}</option>)
                                }
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Visita de aplicacion
                            stateServCall[0]?.IdEtapa === 60 && (
                              <>
                                <option value="2">{t("serviceCard.app_termin")}</option>
                                <option value="3">{t("serviceCard.prog_app")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Recepcion del acta de instalacion
                            stateServCall[0]?.IdEtapa === 62 && (
                              <>
                                { // Si el acta esta cargada
                                  CADoc && CADoc[0]?.Acta && (
                                    <>
                                      <option value="2">{t("serviceCard.prog_app")}</option>
                                      <option value="3">{t("serviceCard.gen_crono")}</option>
                                    </>
                                  )
                                }
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Espera dia de visita de recepcion de sitio
                            stateServCall[0]?.IdEtapa === 83 && (
                              <>
                                {
                                  empPerm && ([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 10 && [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio)) || (empPerm[0]?.Coordinador && empPerm[0]?.CodPerfil === 1) || (empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                    <>
                                      <option value="2">{t("newCallComp.tec_asistio")}</option>
                                      <option value="3">{t("newCallComp.tec_no_asistio")}</option>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                          { // Cerrar aplicacion
                            stateServCall[0]?.IdEtapa === 61 && (
                              <>
                                {reporAplica && (<option value="2">{t("serviceCard.confirm_app")}</option>)}
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          {
                            GENERIC_STEPS.includes(stateServCall[0]?.IdEtapa) && (<option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>)
                          }
                          { // Asignar especialista tecnico pre instalacion
                            stateServCall[0]?.IdEtapa === 71 && (
                              <>
                                <option value="2">{t("AsignacionTecnicoComp.technicianContacted")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Confirmar visita de aplicacion - Espera dia de la aplicacion
                            [66, 59].includes(stateServCall[0]?.IdEtapa) && (
                              <>
                                <option value="5">{t("newCallComp.tec_asistio")}</option>
                                <option value="6">{t("newCallComp.tec_no_asistio")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Espera dia de pre instalacion - Confirmar visita de pre instalacion
                            [73, 74].includes(stateServCall[0]?.IdEtapa) && (
                              <>
                                <option value="5">{t("newCallComp.tec_asistio")}</option>
                                <option value="6">{t("newCallComp.tec_no_asistio")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Programacion de aplicaciones
                            stateServCall[0]?.IdEtapa === 58 && (
                              <>
                                <option value="2">{t("newCallComp.program_aplicacion")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Visita pre instalacion
                            stateServCall[0]?.IdEtapa === 75 && (
                              <>
                                <option value="5">{t("newCallComp.ambien_listo")}</option>
                                <option value="6">{t("newCallComp.progr_revis_ambi")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Visita de instalacion
                            stateServCall[0]?.IdEtapa === 75 && (
                              <>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                {
                                  empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil)) || (empPerm[0]?.CodPerfil === 10 && [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio)) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || (empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                    <>
                                      <option value="2">{t("newCallComp.instalacion_term")}</option>
                                      <option value="3">{t("newCallComp.scheduleVisit")}</option>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                          { // Espera por dia de visita - Confirmar visita
                            [6, 7].includes(stateServCall[0]?.IdEtapa) && (
                              <>
                                {
                                  empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador))) && (
                                    <>
                                      <option value="8">{t("newCallComp.tec_sitio")}</option>
                                      <option value="18">{t("newCallComp.tec_no_asistio")}</option>
                                      <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                      <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                          { // Programacion de visita - Espera por cliente
                            [3, 4].includes(stateServCall[0]?.IdEtapa) && (
                              <>
                                {
                                  empPerm &&
                                  (
                                    (
                                      [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio) &&
                                      (
                                        [3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) ||
                                        (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                      )
                                    ) ||
                                    (
                                      [1, 2].includes(stateServCall[0]?.IdTipServicio) &&
                                      PODER_PROGRAMAR &&
                                      (
                                        [3, 6, 12].includes(empPerm[0]?.CodPerfil) ||
                                        (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                      )
                                    )
                                  ) && (
                                    <>
                                      <option value="3">{t("ProgramacionVisita.visitScheduled")}</option>
                                      <option value="2">{t("ProgramacionVisita.problemSolved")}</option>
                                    </>
                                  )
                                }
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Visita tecnica - Diagnostico con escalacion
                            [8, 9].includes(stateServCall[0]?.IdEtapa) && (
                              <>
                                {
                                  empPerm && ([3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                    <>
                                      <option value="2">{t("newCallComp.scheduleVisit")}</option>
                                      <option value="9">{t("newCallComp.diagnostScalation")}</option>
                                      <option value="23">{t("newCallComp.serviceCompleted")}</option>
                                    </>
                                  )
                                }
                                {
                                  stateServCall[0]?.CodGrupo === 3 && (
                                    <option value="4">{t("newCallComp.esp_clie")}</option>
                                  )
                                }
                              </>
                            )
                          }
                          { // Recepcion de reporte de servicio - Cerrar servicio - devolucion de parte
                            [23, 25, 42].includes(stateServCall[0]?.IdEtapa) && (
                              <>
                                {
                                  (SE_PUEDE_CERRAR && !EXIST_REPUESTO) && ([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 10 && [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio))) && (
                                    <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                  )
                                }
                                <option value="24">{t("newCallComp.pendToBeInv")}</option>
                                <option value="2">{t("newCallComp.scheduleVisit")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Ambiente listo
                            stateServCall[0]?.IdEtapa === 77 && (
                              <>
                                <option value="30">{t("newCallComp.sol_inst")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Confirmar preventivo
                            stateServCall[0]?.IdEtapa === 5 && (
                              <>
                                {
                                  empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador))) && (
                                    <>
                                      <option value="3">{t("newCallComp.confirmo_visita")}</option>
                                      <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                      <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                          { // Programacion pre instalacion
                            stateServCall[0]?.IdEtapa === 72 && (
                              <>
                                <option value="73">{t("newCallComp.progra_revi_ambient")}</option>
                                <option value="77">{t("newCallComp.ambien_culmin")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Pendiente de facturar
                            stateServCall[0]?.IdEtapa === 24 && (
                              <>
                                {
                                  PENDIENTE_FACTURAR ? (<option value="25">{t("newCallComp.fact_realizada")}</option>) : null
                                }
                                <option value="23">{t("newCallComp.recepcion_rep_serv")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Programacion de instalacion
                            stateServCall[0]?.IdEtapa === 54 && (
                              <>
                                <option value="2">{t("newCallComp.instalacion_prog")}</option>
                                <option value="3">{t("newCallComp.confir_instalacion")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Espera dia de la instalacion - Confirmar visita de instalacion
                            [55, 67].includes(stateServCall[0]?.IdEtapa) && (
                              <>
                                {
                                  empPerm && (([3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || empPerm[0]?.CodEmpleado === stateServCall[0]?.CodEmpResponsable)) && (
                                    <>
                                      <option value="4">{t("newCallComp.tec_asistio")}</option>
                                      <option value="2">{t("newCallComp.tec_no_asistio")}</option>
                                    </>
                                  )
                                }
                                <option value="3">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Equipo bajo observacion
                            stateServCall[0]?.IdEtapa === 22 && (
                              <>
                                <option value="1">{t("newCallComp.serviceCompleted")}</option>
                                <option value="2">{t("newCallComp.diagnostScalation")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Asignacion de tecnico
                            stateServCall[0]?.IdEtapa === 2 && (
                              <>
                                <option value="2">{t("AsignacionTecnicoComp.technicianContacted")}</option>
                                <option value="4">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                                <option value="1">{t("AsignacionTecnicoComp.closeService")}</option>
                              </>
                            )
                          }
                          { // Asignar Especialista Técnico para la Instalación
                            stateServCall[0]?.IdEtapa === 53 && (
                              <>
                                <option value="3">{t("AsignacionTecnicoComp.technicianContacted")}</option>
                                <option value="2">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          { // Programación de Preventivos
                            stateServCall[0]?.IdEtapa === 29 && (
                              <>
                                {
                                  empPerm && ((empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || [3, 6, 12].includes(empPerm[0]?.CodPerfil)) && (
                                    <>
                                      <option value="45">{t("ProgramacionVisita.visitScheduled")}</option>
                                      <option value="46">{t("ProgramacionVisita.problemSolved")}</option>
                                    </>
                                  )
                                }
                                <option value="5">{t("AsignacionTecnicoComp.waitingForCustomer")}</option>
                              </>
                            )
                          }
                          <option value="0">{t("newCallComp.comment")}</option>
                        </select>
                      </div>
                      { // Programación de Preventivos
                        stateServCall[0]?.IdEtapa === 29 && (
                          <>
                            {
                              empPerm && ((empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador) || [3, 6, 12].includes(empPerm[0]?.CodPerfil)) && (
                                <>
                                  <div className="col-8">
                                    <strong>{t("newCallComp.fec_visita")}</strong>
                                  </div>
                                  <div className="col-4">
                                    <input
                                      className="input-date-styles"
                                      type="datetime-local"
                                      name="fecVisita"
                                      value={form.fecVisit}
                                      onChange={({ target }) => handleChangeForm("fecVisit", target.value)} />
                                  </div>
                                </>
                              )
                            }
                          </>
                        )
                      }
                      { // Asignar Especialista Técnico para la Instalación
                        stateServCall[0]?.IdEtapa === 53 && (
                          <>
                            <div className="col-8">
                              <strong>{t("newCallComp.ing_respon")}</strong>
                            </div>
                            <div className="col-4">
                              <select
                                name="responInstall"
                                value={form.tecRespon}
                                onChange={({ target }) => handleChangeForm("tecRespon", target.value)}>
                                <option value=""></option>
                                {
                                  employees && employees.map((emp) => (
                                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </>
                        )
                      }
                      { // Asignacion de tecnico
                        stateServCall[0]?.IdEtapa === 2 && (
                          <>
                            <div className="col-8">
                              <strong>{t("newCallComp.techRespon")}</strong>
                            </div>
                            <div className="col-4">
                              <select
                                name="employeeRes"
                                value={form.tecRespon}
                                onChange={({ target }) => handleChangeForm("tecRespon", target.value)}>
                                <option value=""></option>
                                {
                                  employees && employees.map((emp) => (
                                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </>
                        )
                      }
                      { // Programacion de instalacion
                        stateServCall[0]?.IdEtapa === 54 && (
                          <>
                            <div className="col-8">
                              <strong>{t("newCallComp.fec_instalacion")}</strong>
                            </div>
                            <div className="col-4">
                              <input
                                className="input-date-styles"
                                type="datetime-local"
                                name="fecInstall"
                                value={form.fecVisit}
                                onChange={({ target }) => handleChangeForm("fecVisit", target.value)} />
                            </div>
                          </>
                        )
                      }
                      { // Pendiente de facturar (Muestra mensaje de que se debe facturar antes de cerrar)
                        stateServCall[0]?.IdEtapa === 24 && (
                          <>
                            {
                              !PENDIENTE_FACTURAR ? <div className="flex-auto"><span className="required">{t("newCallComp.coti_pend_facturar")}</span></div> : null
                            }
                          </>
                        )
                      }
                      { // Programacion pre instalacion
                        stateServCall[0]?.IdEtapa === 72 && (
                          <>
                            <div className="col-8">
                              <strong>{t("newCallComp.fec_rebi")}</strong>
                            </div>
                            <div className="col-4">
                              <input
                                className="input-date-styles"
                                type="datetime-local"
                                name="fecAmbiente"
                                value={form.fecVisit}
                                onChange={({ target }) => handleChangeForm("fecVisit", target.value)} />
                            </div>
                          </>
                        )
                      }
                      { // Confirmar preventivo
                        stateServCall[0]?.IdEtapa === 5 && (
                          <>
                            {
                              empPerm && (([3, 6, 12].includes(empPerm[0]?.CodPerfil) || (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador))) && (
                                <>
                                  <div className="col-8 col-sm-30">
                                    <strong>{t("newCallComp.fec_visita")}</strong>
                                  </div>
                                  <div className="col-4 col-sm-70-mod">
                                    <input
                                      className="input-date-styles"
                                      type="datetime-local"
                                      name="fecVisiPreven"
                                      value={form.fecVisit}
                                      onChange={({ target }) => handleChangeForm("fecVisit", target.value)} />
                                  </div>
                                </>
                              )
                            }
                          </>
                        )
                      }
                      { // Recepcion de reporte de servicio - Cerrar servicio - devolucion de parte
                        [23, 25, 42].includes(stateServCall[0]?.IdEtapa) && (
                          <>
                            {
                              MsgError && (
                                <div className="col-3">
                                  <strong className="required">{MsgError}</strong>
                                </div>
                              )
                            }
                          </>
                        )
                      }
                      {// Programacion de visita - Espera por cliente
                        [3, 4].includes(stateServCall[0]?.IdEtapa) && (
                          <>
                            {
                              ([1, 2].includes(stateServCall[0]?.IdTipServicio) && !PODER_PROGRAMAR) && (
                                <div className="col-3">
                                  <strong className="required">{t("newCallComp.no_se_puede_programar")}</strong>
                                </div>
                              )
                            }
                          </>
                        )
                      }
                      { // Programacion de visita - Espera por cliente
                        [3, 4].includes(stateServCall[0]?.IdEtapa) && (
                          <>
                            {
                              empPerm && (
                                (
                                  [3, 5, 9, 10].includes(stateServCall[0]?.IdTipServicio) &&
                                  (
                                    [3, 6, 10, 12].includes(empPerm[0]?.CodPerfil) ||
                                    (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                  )
                                ) ||
                                (
                                  [1, 2].includes(stateServCall[0]?.IdTipServicio) &&
                                  PODER_PROGRAMAR &&
                                  (
                                    [3, 6, 12].includes(empPerm[0]?.CodPerfil) ||
                                    (empPerm[0]?.CodPerfil === 1 && empPerm[0]?.Coordinador)
                                  )
                                )
                              ) && (
                                <>
                                  <div className="col-8 col-sm-30">
                                    <strong>{t("newCallComp.fec_visita")}</strong>
                                  </div>
                                  {/* INPUT PARA COLOCAR LA FECHA Y HORA */}
                                  <div className="col-4 col-sm-70-mod">
                                    <input
                                      className="input-date-styles"
                                      type="datetime-local"
                                      name="fecVisit"
                                      value={form.fecVisit}
                                      onChange={({ target }) => handleChangeForm("fecVisit", target.value)} />
                                  </div>
                                </>
                              )
                            }
                          </>
                        )
                      }
                      { // Programacion de aplicaciones
                        stateServCall[0]?.IdEtapa === 58 && (
                          <>
                            <div className="col-8">
                              <strong>{t("newCallComp.fec_aplicacion")}</strong>
                            </div>
                            <div className="col-4">
                              <input
                                className="input-date-styles"
                                type="datetime-local"
                                name="fecVisit"
                                value={form.fecVisit}
                                onChange={({ target }) => handleChangeForm("fecVisit", target.value)} />
                            </div>
                          </>
                        )
                      }
                      { // Recepcion del POD
                        stateServCall[0]?.IdEtapa === 78 && (
                          <>
                            {
                              !tienePOD && (
                                <div className="col-46-7"><span className="required">{t("newCallComp.no_pod")}</span></div>
                              )
                            }
                          </>
                        )
                      }
                      { // Recepcion del acta de instalacion
                        stateServCall[0]?.IdEtapa === 62 && (
                          <>
                            {
                              CADoc && !CADoc[0]?.Acta && (
                                <div className="col-42-29"><strong className="required">{t("newCallComp.no_ca")}</strong></div>
                              )
                            }
                          </>
                        )
                      }
                      { // Cerrar aplicacion
                        stateServCall[0]?.IdEtapa === 61 && (
                          <>
                            {
                              !reporAplica && (
                                <div className="col-46-7"><span className="required">{t("newCallComp.no_report_apli")}</span></div>
                              )
                            }
                          </>
                        )
                      }
                      { // Esto es para otra etapa tambien
                        stateServCall[0]?.IdEtapa === 71 && (
                          <>
                            <div className="col-8 col-sm-30">
                              <strong>{t("newCallComp.techRespon")}</strong>
                            </div>
                            <div className="col-4 col-sm-70-mod">
                              <select
                                name="employee"
                                value={form.tecRespon}
                                onChange={({ target }) => handleChangeForm("tecRespon", target.value)}>
                                <option value=""></option>
                                {
                                  employees && employees.map((emp) => (
                                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </>
                        )
                      }
                    </div>
                    {
                      stateServCall[0]?.CodGrupo === 3 && (
                        <>
                          { // Programacion de visita
                            [3, 4].includes(stateServCall[0]?.IdEtapa) && (
                              <div>
                                <strong className="required">{t("serviceCard.cargar_cot")}</strong>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                  </div>



                  {
                    // Fila (Reportes y demás)
                    stateServCall[0]?.IdEtapa !== 99 && (
                      <div className="row-flex gap-small">
                        <div style={{ alignItems: 'center' }} className="row-flex j-content-space-b col-100-sm">
                          <div className="col-70-sm request-links">
                            <strong>{t("newCallComp.requestPartsAndTools")}</strong>
                          </div>
                          <button type="button" className="btn-call-details-steps" onClick={() => navigate(`/service/request-part/${stateServCall[0]?.CodServicio}`)}>
                            <ToolIcon />
                          </button>
                        </div>
                        <div style={{ alignItems: 'center' }} className="row-flex j-content-space-b col-100-sm">
                          <div className="col-70-sm request-links">
                            <strong>{t("newCallComp.assetsRequest")}</strong>
                          </div>
                          <button type="button" className="btn-call-details-steps" onClick={() => navigate(`/service/asset-request/${stateServCall[0]?.CodServicio}`)}>
                            <ToolIcon />
                          </button>
                        </div>
                        <div style={{ alignItems: 'center' }} className="row-flex j-content-space-b col-100-sm">
                          <div className="request-links">
                            <strong>{t("newCallComp.serviceReport")}</strong>
                          </div>
                          <button type="button" className="btn-call-details-steps" onClick={() => navigate(GoToService())}>
                            <BookIcon />
                          </button>
                        </div>
                      </div>
                    )
                  }


                  {/* PARA COMENTAR */}
                  <div className="row-flex d-f-column">
                    <div>
                      <strong>{t("newCallComp.comment")}</strong>
                    </div>
                    <div className="parent">
                      <textarea
                        className="textarea-description"
                        name="comentario"
                        value={form.comment}
                        onChange={({ target }) => handleChangeForm("comment", target.value)}
                        placeholder={lang === "en" ? "Comments..." : "Comentarios..."} />
                    </div>
                  </div>

                  {/* AGREGAR COMENTARIO A LA LLAMADA - BOTONES DE ACCION */}
                  <div style={{ margin: '0 1rem 1.5rem 1rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '0.5rem', margin: '0.8rem 0' }}>
                      <div className={state.sending ? "wrapper" : ""}>
                        <button type={state.sending ? "button" : "submit"} className={`${state.sending ? "disabled" : ""} btn-primary`}>{t("newCallComp.save")}</button>
                      </div>
                      <div>
                        <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                      </div>
                    </div>
                  </div>
                  {/* {
                  window.screen.width >= 1023 && ( */}
                  <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }}>{t("serviceCard.follow")}</h4>

                  <div className="table-container-mobile fade-animation seg-service-table" style={{ margin: '1rem 0' }}>
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th style={{ borderRadius: '5px 0 0 0' }}>{t("newCallComp.date")}</th>
                          <th>{t("newCallComp.currentStep")}</th>
                          <th>{t("newCallComp.nextStep")}</th>
                          <th>{t("newCallComp.responStepChange")}</th>
                          <th>{t("newCallComp.serviceRespon")}</th>
                          <th style={{ borderRadius: '0 5px 0 0' }}>{t("newCallComp.action")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          segIdCall && segIdCall.map((servSeg) => (
                            <tr key={servSeg.codeIndex} className={servSeg.Tabla === "Cot" ? "bg-gray" : ""}>
                              <td style={{ textAlign: 'center' }} className="col-10">{renderDate(servSeg.FecIngreso, lang)}</td>
                              <td className="col-9">{servSeg.NomEtaActual}</td>
                              <td className="col-9">{servSeg.NomEtapa}</td>
                              <td className="col-7">{servSeg.NomEmpleado || "APP"}</td>
                              <td className="col-7">{servSeg.EmpResponsable}</td>
                              <td>{servSeg.Accion}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>

                  {/* Tabla de herramientas */}
                  <SegToolsTable lang={lang} idCall={id} token={getTheToken().token} />


                  {/* TABLA PARA MOSTRAR LAS PARTES QUE TIENE ESTA LLAMADA */}

                  <SegPartsTable lang={lang} token={getTheToken().token} idCall={id} />


                  {/* TABLA PARA MOSTRAR LOS REPORTES DE SERVICIO */}
                  <h4 style={{ color: 'var(--first-color)', textDecoration: 'underline' }} className="m-y-small">{t("serviceCard.reports")}</h4>
                  {
                    serviceReports && serviceReports.length > 0 ? (
                      <div className="table-container-mobile">
                        <table className="table">
                          <thead className="table-head">
                            <tr>
                              <th>

                              </th>
                              <th>{t("serviceCard.date")}</th>
                              <th>{t("serviceCard.serviceReport")}</th>
                              <th>{t("serviceCard.description")}</th>
                              <th>{t("serviceCard.horas")}</th>
                              <th>{t("serviceCard.horas_viaje")}</th>
                              <th>{t("serviceCard.horas_extras")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody className="table-body">
                            {
                              serviceReports && serviceReports.map((report) => (
                                <tr key={report.CodRepServicio}>
                                  <td className="txt-center">
                                    {
                                      (empPerm && [3, 6].includes(empPerm[0]?.CodPerfil) && !report.CodEmpGerente) && (
                                        <button type="button" className="btn-delete">
                                          <Close />
                                        </button>
                                      )
                                    }
                                  </td>
                                  <td style={{ textAlign: 'center' }} className="col-10">{renderDate(report.FecReporte, lang)}</td>
                                  <td style={{ textAlign: 'center' }}>
                                    {
                                      grupo === 1 ? <Link className="link-tag" to={`/service/service-report-detail/${report.CodRepServicio}`}>{report.NumReporte}</Link>
                                        :
                                        <Link className="link-tag" to={`/commercial/service-report-detail/${report.CodRepServicio}`}>{report.NumReporte}</Link>
                                    }
                                  </td>
                                  <td>{report.Descripcion}</td>
                                  <td className="txt-center">{report.Horas}</td>
                                  <td className="txt-center">{report.HorViaje}</td>
                                  <td className="txt-center">{report.HorExtras + report.HorExtNocturna + report.HorExtFeriado}</td>
                                  <td className="txt-center">
                                    <div style={{ display: "flex", gap: "0.3rem" }}>
                                      <button type="button" className="btn-docs" onClick={() => handleOpenCloseServReport(report.CodRepServicio)}>
                                        <Folder />
                                      </button>
                                      {
                                        report.Reporte && (
                                          <button type="button" disabled={stateS.isLoading} className="btn-pdf" onClick={() => downloadServiceReportDoc(report.CodRepServicio, "service report.pdf")}>
                                            {
                                              stateS.isLoading ? (<SpinnerIcon />) : (<PDFIcon />)
                                            }
                                          </button>
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    ) : (<div className="m-y-small"><strong style={{ fontSize: '0.9rem', color: 'var(--danger-color)' }}>{lang === "en" ? "There is no service reports" : "Aún no hay reportes de servicio"} &#128064;</strong></div>)
                  }
                </form>
              </div>


              {/* Modal para cargar el reporte de servicio */}
              {
                state.modalUploadServRep && (
                  <div className="background-modal" onClick={() => handleChangeState("modalUploadServRep", false)}>
                    <UploadServReportComp
                      onSendValue={handleOpenCloseServReport}
                      onNotify={handleChangeState}
                      codRepServ={state.codRepServ}
                      lang={lang} />
                  </div>
                )
              }

              {/* button search */}
              <div className="container-btn-search-2">
                <button type="button" className="btn-search" onClick={() => handleChangeState("modalSearchCall", true)}>
                  <SearchIcon />
                </button>
              </div>
            </div >

            {/* modal para filtrar por una llamada */}
            {
              state.modalSearchCall && (
                <div className="background-modal" onClick={() => handleChangeState("modalSearchCall", false)}>
                  <div className="container-modal-search-id" onClick={(event) => event.stopPropagation()}>
                    <form style={{ width: "100%" }} onSubmit={onSearchCall}>
                      <div className="row-flex align-c">
                        <div className="col-3">
                          <h5>Buscar llamada</h5>
                        </div>
                        <div className="col-6">
                          <input
                            className="input-text-control"
                            type="text"
                            name="searchCall"
                            value={form.idServic}
                            onChange={({ target }) => {
                              if (isNaN(target.value)) {
                                return;
                              }
                              handleChangeForm("idServic", target.value)
                            }} />
                        </div>
                        <div>
                          <button type="submit" className="btn-success">
                            <SearchIcon />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )
            }
          </>
        )
      }

      {
        // modal para crear el POD
        state.modalPOD && (
          <GeneratePOD onClose={handleChangeState} CodEquip={stateServCall[0]?.CodEquipo} />
        )
      }

      { // Modal para cargar las cotizaciones de comercial
        state.modalCot && (
          <UploadServQuot onNotify={handleChangeState} lang={lang} token={getTheToken().token} Id={id} />
        )
      }

      { // Modal para cargar documentos de la llamada
        state.modalDocs && (
          <UploadServiceDocs onNotify={handleChangeState} lang={lang} token={getTheToken().token} CodEqp={form.equipment} />
        )
      }
    </>
  );
};