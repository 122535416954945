// componente para crear un contacto

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { urls } from "../../api-urls/api-urls";
import { useNavigate } from "react-router-dom";




export const CreateContactComp = ({ onClose, idCus, lang, token, empPerm, onNotify }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the react translation
  const [t] = useTranslation("global");


  // state definition
  const [form, setForm] = useState({ nombre: "", cedula: "", codTele: "", tele: "", codCell: "", cell: "", tipo: "", fax: "", correo: "", depart: "", posicion: "", especialidad: "", principal: false, activo: true });
  const [state, setState] = useState({ isLoading: false, msg: null });


  // funcion para actualizar el estado del formulario
  const onUpdateForm = (newVal, name = "") => {
    if (["codTele", "codCell"].includes(name)) {
      if (newVal.length > 5) {
        return;
      }
    }

    setForm((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para enviar el form al serv
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.getCustomer}/${idCus}/search?language=${lang}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.status === 201) {
        // cerrar el modal
        onClose();
        setState((val) => ({ ...val, msg: null }));
        // console.log(data?.msg);
        onNotify(data?.msg, false, true, true);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        onNotify(data?.msg, true, false, false);
      } else if (response.status === 500) {
        setState((val) => ({ ...val, msg: data?.msg }));
        setState((val) => ({ ...val, isLoading: false }));
      }

    } catch (error) {
      console.log(error);
    }
  };




  return (
    <div className="background-modal">
      <form onSubmit={onHandleSubmit} className="container-select-customer">
        <div className="modal-header">
          <h4>{t("customerView.crear_contac")}</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          {
            state.msg && (<h5 style={{ color: "var(--danger-color)" }}>{state.msg}</h5>)
          }
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("customerView.name")}</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="contname"
                value={form.nombre}
                onChange={({ target }) => onUpdateForm(target.value, "nombre")}
                autoComplete="off" />
            </div>
            <div className="col-7">
              <strong>{t("customerView.cedula")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="idCard"
                value={form.cedula}
                onChange={({ target }) => onUpdateForm(target.value, "cedula")}
                autoComplete="off" />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>{t("customerView.telephone")}</strong>
            </div>
            <div className="col-70 row-flex">
              <div className="col-4">
                <input
                  className="input-text-control"
                  type="text"
                  name="codTel"
                  value={form.codTele}
                  onChange={({ target }) => onUpdateForm(target.value, "codTele")}
                  placeholder="000" />
              </div>
              <div className="col-46-7">
                <input
                  className="input-text-control"
                  type="text"
                  name="tele"
                  value={form.tele}
                  onChange={({ target }) => onUpdateForm(target.value, "tele")}
                  placeholder="000-0000" />
              </div>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>{t("customerView.cell")}</strong>
            </div>
            <div className="col-70 row-flex">
              <div className="col-4">
                <input
                  className="input-text-control"
                  type="text"
                  name="codCell"
                  value={form.codCell}
                  onChange={({ target }) => onUpdateForm(target.value, "codCell")}
                  placeholder="000" />
              </div>
              <div className="col-46-7">
                <input
                  className="input-text-control"
                  type="text"
                  name="cell"
                  value={form.cell}
                  onChange={({ target }) => onUpdateForm(target.value, "cell")}
                  placeholder="000-0000" />
              </div>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>{t("customerView.tipo")}</strong>
            </div>
            <div className="col-3">
              <select
                name="tipo"
                value={form.tipo}
                onChange={({ target }) => onUpdateForm(target.value, "tipo")}>
                <option value=""></option>
                <option value="0">{t("customerView.post_venta")}</option>
                <option value="1">{t("customerView.inge_tec")}</option>
                <option value="2">{t("customerView.cobranza")}</option>
                <option value="3">{t("customerView.devol_parte")}</option>
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Fax</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="fax"
                value={form.fax}
                onChange={({ target }) => onUpdateForm(target.value, "fax")} />
            </div>
            <div className="col-7">
              <strong>{t("customerView.email")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="email"
                value={form.correo}
                onChange={({ target }) => onUpdateForm(target.value, "correo")}
                placeholder="email@email.com" />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>{t("customerView.department")}</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="depart"
                value={form.depart}
                onChange={({ target }) => onUpdateForm(target.value, "depart")} />
            </div>
            <div className="col-7">
              <strong>{t("customerView.posicion")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="posicion"
                value={form.posicion}
                onChange={({ target }) => onUpdateForm(target.value, "posicion")} />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>{t("customerView.especialidad")}</strong>
            </div>
            <div className="col-3">
              <select
                name="espe"
                value={form.especialidad}
                onChange={({ target }) => onUpdateForm(target.value, "especialidad")}>
                <option value=""></option>
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Principal</strong>
            </div>
            <div className="col-3">
              {
                empPerm && empPerm[0]?.CodPerfil === 6 ?
                  (<input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="principal"
                    checked={form.principal}
                    onChange={({ target }) => onUpdateForm(target.checked, "principal")} />)
                  : (<span style={{ color: "var(--danger-color)", textDecoration: "underline" }}>{lang === "en" ? "You must contact the admin" : "Debe contactar al administrador."}</span>)
              }
            </div>
            <div className="col-7">
              <strong>{t("customerView.activo")}</strong>
            </div>
            <div className="col-3 flex-grow">
              <input
                className="input-control-checkbox"
                type="checkbox"
                name="active"
                checked={form.activo}
                onChange={({ target }) => onUpdateForm(target.checked, "activo")} />
            </div>
          </div>

        </div>
        <div className="modal-footer j-content-space-around">
          <button type={state.isLoading ? "button" : "submit"} disabled={state.isLoading} className={`btn-primary ${state.isLoading ? "disabled" : ""}`}>{t("createCustomerComp.btnSave")}</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>{t("LogisticViewComp.cerrar")}</button>
        </div>
      </form>
    </div>
  );
};