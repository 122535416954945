// -----> React importations
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

// -----> Importation of useTranslate
import { useTranslation } from "react-i18next";

// ----> Global Logo importation
import GlobalLogo from "../../assets/logo-global-blanco.png";
import GMDHLogo from "../../assets/logo-gmdh-blanco.png";

// Icons importation 
import { Warehouse, ClipBoardPulseIcon } from "../../components/icons/AppIcons.jsx";


// ----> inline Styles importation
import { customerContainerLabel } from "../../inline-styles/generalStyles";

// COMPONENTES
import { SideBarMenu } from "../SideBarMenu/SideBarMenu";

// STYLES IMPORTATION
import "../../components/top-searchbar-mobile/TopNavBarMobile.css";

// ----> USER CONTEXT IMPORTATION
import { UserContext } from "../../context/UserContext";
import { urls } from "../../api-urls/api-urls";
import { getTheToken } from "../../global-functions/globalFunctions";




export const TopNavbarMobile = ({ onNewEvent }) => {

  // -----> useTranslation() Hook
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  // ----> Application state()
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dataSer, setDataSer] = useState([]); // controlador para colocar el usuario en el estado
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor

  // ruta de servicio
  const SERVICE_ROUTE = dataSer && dataSer[0]?.CodGrupo === 1 ? "/service" : "/commercial/service";


  // rutas para los pendientes
  const RouteName = {
    1: "/my-tasks/service",
    3: "/my-tasks/operation",
    10: "/my-tasks/site-planning",
    12: "/my-tasks/commercial",
  };



  // ----> language value from localStorage
  // const language = localStorage.getItem("language") || window.navigator.language.slice(0, 2);

  // ----> useLocation() Hook and all hooks related to the router dom
  const location = useLocation();


  // funcion para obtener los permisos del usuario
  const fetchPermissions = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      const response = await fetch(urls.permissions, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataSer(data?.EmpPerm);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {
    fetchPermissions();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  // ruta para los pendientes dependiendo el perfil
  const MY_TASKS_ROUTES = [
    "/my-tasks/commercial",
    "/my-tasks/operation",
    "/my-tasks/service",
    "/my-tasks/site-planning"
  ];


  // ----> useParams()
  const { id, codDetSolR, solRepuesto, codOrden, codSolRepuesto, codEquipo, codCliente, codSolRep, orderNum, idEtapa, codRepServ, idCall, notEntr, codSolG, codDet, codHerr, codOrdenDet, codSol } = useParams();

  const handleMobileOpen = () => {
    setMobileOpen(!mobileOpen);
  };



  // GET THE USER INFO string format
  const userInfo = localStorage.getItem("userInfo");

  // const userInfoParsed = JSON.parse(userInfo);

  // // iniciales del nombre del usuario
  // const initialName = userInfoParsed.name !== null ? userInfoParsed.name.split(" ") : "";

  let initialName = ""; // valor predeterminado

  const userInfoParsed = userInfo ? JSON.parse(userInfo) : "";
  initialName = userInfoParsed.name || "";

  return (
    <>

      {
        location.pathname !== `/warehouse/delivery-notes-print/${notEntr}` && (
          <>
            <div className="top-navbar-mobile">
              <div className="container-topbar">
                <div style={{ position: 'absolute', bottom: '0', top: '0' }}>
                  {
                    // si el empleado pertenece a global
                    dataSer && dataSer[0]?.CodEmpresa === "1" && (
                      <img className="global-logo-topnavbar" src={GlobalLogo} alt="Global Logo" />
                    )
                  }
                  {
                    // si el empleado pertenece a GMD
                    dataSer && dataSer[0]?.CodEmpresa === "2" && (
                      <img className="global-logo-topnavbar" src={GMDHLogo} alt="GMDH Logo" />
                    )
                  }
                </div>
                {/* ----> RENDERIZA SI EL PATH ES === '/home' */}
                {
                  location.pathname === '/home' && (
                    <>
                      {
                        window.screen.width >= 1200 && (
                          <div style={customerContainerLabel}>

                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to={`${RouteName[dataSer[0]?.CodPerfil] ?? "/home"}`}>
                                {t("navigationMenu.pending")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customer")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.equipment")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to={SERVICE_ROUTE}>
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-principal">
                                {t("navigationMenu.logistic")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/post-sales/proposal">
                                {t("navigationMenu.sales")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.commercial")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/human-capital/employees">
                                {t("navigationMenu.employees")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/principal">
                                {t("navigationMenu.wareHouse")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/administration/pending">
                                {t("navigationMenu.administration")}
                              </NavLink>
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
                {
                  // renderizado si el path es /administration/consumption-request
                  location.pathname === "/administration/consumption-request" && (
                    <>
                      {
                        window.screen.width >= 1200 && (
                          <div style={customerContainerLabel}>
                            <h5 className="top-navbar-h4">{t("navigationMenu.sol_consumo")}</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--second-color)" className="bi bi-credit-card" viewBox="0 0 16 16">
                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                              <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                            </svg>

                            <div className="row-flex align-c gap-small">
                              <NavLink className="navbar-link-help" to="/administration/pending">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/administration/authorizations">{t("navigationMenu.autorizaciones")}</NavLink>

                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
                {
                  // renderizado si el path es /administration/authorizations
                  location.pathname === "/administration/authorizations" && (
                    <>
                      {
                        window.screen.width >= 1200 && (
                          <div style={customerContainerLabel}>
                            <h5 className="top-navbar-h4">{t("navigationMenu.autorizaciones")}</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--second-color)" className="bi bi-credit-card" viewBox="0 0 16 16">
                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                              <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                            </svg>

                            <div className="row-flex align-c gap-small">
                              <NavLink className="navbar-link-help" to="/administration/pending">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/administration/consumption-request">{t("navigationMenu.sol_consumo")}</NavLink>
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }

                {
                  // renderizado si el path es /administration/pending
                  location.pathname === "/administration/pending" && (
                    <>
                      {
                        window.screen.width >= 1200 && (
                          <div style={customerContainerLabel}>
                            <h5 className="top-navbar-h4">{t("navigationMenu.pending")}</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--second-color)" className="bi bi-ui-checks-grid" viewBox="0 0 16 16">
                              <path d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0l2-2z" />
                            </svg>

                            <div className="row-flex align-c gap-small">
                              <NavLink className="navbar-link-help" to="/administration/authorizations">{t("navigationMenu.autorizaciones")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/administration/consumption-request">{t("navigationMenu.sol_consumo")}</NavLink>
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
                {
                  // renderizado si el path es /administration/consumption-request-detail/${codSol}
                  [`/administration/consumption-request-detail/${codSol}`, "/administration/create-consumption-request"].includes(location.pathname) && (
                    <>
                      {
                        window.screen.width >= 1200 && (
                          <div style={customerContainerLabel}>
                            <h5 className="top-navbar-h4">
                              {t("navigationMenu.sol_consumo")}
                            </h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--second-color)" className="bi bi-credit-card" viewBox="0 0 16 16">
                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
                              <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                            </svg>

                            <div className="row-flex align-c gap-small">
                              <NavLink className="navbar-link-help" to="/administration/pending">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/administration/authorizations">{t("navigationMenu.autorizaciones")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/administration/consumption-request">{t("navigationMenu.sol_consumo")}</NavLink>
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
                {/* ----> RENDERIZADO SI EL PATH === '/equipments' */}
                {
                  location.pathname === "/equipments" && (
                    <>
                      {
                        window.screen.width >= 1200 && (
                          <div style={customerContainerLabel}>
                            <h5 className="top-navbar-h4">{t("navigationMenu.equipment")}</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--second-color)" className="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
                              <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z" />
                            </svg>
                            {/* links de ayuda */}

                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/equipments/create-equipment/equipment">
                                {t("navigationMenu.newEquipment")}
                              </NavLink>
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /equipments/equipment-modify/255 */}
                {
                  location.pathname === `/equipments/equipment-modify/${codEquipo}` && (
                    <>
                      {
                        window.screen.width >= 1200 && (
                          <div style={customerContainerLabel}>
                            <h5 className="top-navbar-h4">{t("navigationMenu.equipment")}</h5>
                            {/* links de ayuda */}
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--second-color)" className="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
                                <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z" />
                              </svg>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.newEquipment")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.equipment")}
                              </NavLink>
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }

                {
                  // renderizado si la ruta es servicio de commercial
                  location.pathname === '/commercial/service' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.service")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/commercial/projects">
                                {t("navigationMenu.projects")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/request-orders">
                                {t("navigationMenu.request")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/service/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/purchase-order">
                                {t("navigationMenu.purchaseOrder")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {
                  // renderizado si la ruta es /commercia/service/service-call/${id}
                  location.pathname === "/commercial/service/new-call" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.openCall")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/commercial/projects">
                                {t("navigationMenu.projects")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/request-orders">
                                {t("navigationMenu.request")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/purchase-order">
                                {t("navigationMenu.purchaseOrder")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {
                  // renderizado si la ruta es /commercia/service/service-call/${id}
                  location.pathname === `/commercial/service/service-call/${id}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.service")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/commercial/projects">
                                {t("navigationMenu.projects")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/request-orders">
                                {t("navigationMenu.request")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/service">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/service/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/commercial/purchase-order">
                                {t("navigationMenu.purchaseOrder")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {
                  // Renderizado si la ruta es new-call
                  location.pathname === '/new-call' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.openCall")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.fmiStb")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/reports">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA === '/service' */}
                {
                  location.pathname === "/service" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.service")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to={`/service/fmi`}>
                                {t("navigationMenu.fmiStb")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-principal">
                                {t("navigationMenu.logistic")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/reports">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es '/service/service-report-detail/:codRepServ' */}
                {
                  location.pathname === `/service/service-report-detail/${codRepServ}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.reporte_servicio")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                                <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                              </svg>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>

                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to={`/service`}>
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to={`/service/fmi`}>
                                {t("navigationMenu.fmiStb")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/reports">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es === `/service/service-report/:idCall (Aca se crean los reportes de servicio) */}
                {
                  location.pathname === `/service/service-report/${idCall}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.reporte_servicio")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                                <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                              </svg>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>

                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to={`/service`}>
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to={`/service/fmi`}>
                                {t("navigationMenu.fmiStb")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/reports">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES === '/service/fmi' */}
                {
                  location.pathname === '/service/fmi' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">FMI/STB</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <ClipBoardPulseIcon heightP="19" widthP="19" fillP="var(--second-color)" />
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to={`/service`}>
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/reports">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI EL PATH ES === '/service/service-call/:id' */}
                {
                  location.pathname === `/service/service-call/${id}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.callDetail")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/fmi">
                                {t("navigationMenu.fmiStb")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-principal">
                                {t("navigationMenu.logistic")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/reports">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZA SI LA RUTA ES === '/service/reports' */}
                {
                  location.pathname === '/service/reports' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.serviceReport")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/fmi">
                                {t("navigationMenu.fmiStb")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES === '/service/scheduling' */}
                {
                  location.pathname === '/service/scheduling' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.schedule")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" class="bi bi-watch" viewBox="0 0 16 16">
                        <path d="M8.5 5a.5.5 0 0 0-1 0v2.5H6a.5.5 0 0 0 0 1h2a.5.5 0 0 0 .5-.5z" />
                        <path d="M5.667 16C4.747 16 4 15.254 4 14.333v-1.86A5.985 5.985 0 0 1 2 8c0-1.777.772-3.374 2-4.472V1.667C4 .747 4.746 0 5.667 0h4.666C11.253 0 12 .746 12 1.667v1.86a5.99 5.99 0 0 1 1.918 3.48.502.502 0 0 1 .582.493v1a.5.5 0 0 1-.582.493A5.99 5.99 0 0 1 12 12.473v1.86c0 .92-.746 1.667-1.667 1.667zM13 8A5 5 0 1 0 3 8a5 5 0 0 0 10 0" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/service">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/new-call">
                                {t("navigationMenu.newCall")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/fmi">
                                {t("navigationMenu.fmiStb")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/customers">
                                {t("navigationMenu.customerList")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/equipments">
                                {t("navigationMenu.showEquipments")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service/reports">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /warehouse/administrative-approval/approval-detail/${code} */}
                {
                  location.pathname === `/warehouse/administrative-approval/approval-detail/${codSolRep}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.aprobacion")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/warehouse/principal">
                                {t("navigationMenu.pending")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/technical-approval">
                                {t("navigationMenu.technicalApproval")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/administrative-approval">
                                {t("navigationMenu.adminApproval")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }


                {/* Renderizado si la ruta es /warehouse/principal */}
                {
                  location.pathname === '/warehouse/principal' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.pending")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-shop" viewBox="0 0 16 16">
                                <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                              </svg>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/warehouse/technical-approval">
                                {t("navigationMenu.technicalApproval")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/administrative-approval">
                                {t("navigationMenu.adminApproval")}
                              </NavLink>

                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /warehouse/delivery-notes/:codSolRepuesto */}
                {
                  location.pathname === `/warehouse/delivery-notes/${codSolRepuesto}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.pending")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <Warehouse fill="var(--second-color)" />
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/warehouse/principal">
                                {t("navigationMenu.pending")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/technical-approval">
                                {t("navigationMenu.technicalApproval")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/administrative-approval">
                                {t("navigationMenu.adminApproval")}
                              </NavLink>

                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /my-tasks */}
                {
                  MY_TASKS_ROUTES.includes(location.pathname) && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.pending")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-ui-checks-grid" viewBox="0 0 16 16">
                        <path d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0l2-2z" />
                      </svg>
                      <ul className="lst-nav">
                        <li>
                          <NavLink className="navbar-link-help" to="/service/scheduling">{t("navigationMenu.schedule")}</NavLink>
                        </li>
                        <li>
                          <NavLink className="navbar-link-help" to="/service">{t("navigationMenu.service")}</NavLink>
                        </li>
                      </ul>
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /warehouse/administrative-approval */}
                {
                  location.pathname === '/warehouse/administrative-approval' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.aprobaciones")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/technical-approval">
                                {t("navigationMenu.technicalApproval")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es warehouse/technical-approval */}
                {
                  (location.pathname === "/warehouse/technical-approval" || location.pathname === `/warehouse/technical-approval/technical-detail/${codSolRepuesto}`) && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.technicalApproval")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="var(--second-color)" className="bi bi-tools" viewBox="0 0 16 16">
                        <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                      </svg>
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/service/scheduling">
                                {t("navigationMenu.schedule")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/service">
                                {t("navigationMenu.service")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/warehouse/administrative-approval">
                                {t("navigationMenu.adminApproval")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /travel-expenses */}
                {
                  location.pathname === "/travel-expenses" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.anticipo")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: "flex" }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="var(--second-color)" className="bi bi-currency-exchange" viewBox="0 0 16 16">
                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                              </svg>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/travel-expenses/expense-report">
                                {t("navigationMenu.rela_gastos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/travel-expenses/approvals">
                                {t("navigationMenu.aprobacion")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.historial")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.pagos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.datos")}
                              </NavLink>

                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es travel-expenses/expense-report */}
                {
                  location.pathname === "/travel-expenses/expense-report" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.rela_gastos")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/travel-expenses">
                                {t("navigationMenu.anticipo")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/travel-expenses/approvals">
                                {t("navigationMenu.aprobacion")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.historial")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.pagos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.datos")}
                              </NavLink>

                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /travel-expenses */}
                {
                  location.pathname === '/travel-expenses/approvals' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.anticipo")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/travel-expenses">
                                {t("navigationMenu.anticipo")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/travel-expenses/expense-report">
                                {t("navigationMenu.rela_gastos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.historial")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.pagos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.datos")}
                              </NavLink>

                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /travel-expenses/expense-report-detail/:codSolG o /travel-expenses/create-expense-report*/}
                {
                  (location.pathname === `/travel-expenses/expense-report-detail/${codSolG}` || location.pathname === "/travel-expenses/create-expense-report") && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.rela_gastos")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/travel-expenses">
                                {t("navigationMenu.anticipo")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/travel-expenses/expense-report">
                                {t("navigationMenu.rela_gastos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/travel-expenses/approvals">
                                {t("navigationMenu.aprobacion")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.historial")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.pagos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.datos")}
                              </NavLink>

                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }


                {/* Renderizado si la ruta es /travel-expenses/expense-detail/:codSolGasto */}
                {
                  (location.pathname === `/travel-expenses/expense-detail/${codSolG}` || location.pathname === `/travel-expenses/expense-detail/${codSolG}/${codDet}` || location.pathname === "/travel-expenses/create-advance") && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{location.pathname === "/travel-expenses/create-advance" ? t("navigationMenu.nuevo_antici") : t("navigationMenu.anticipo")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: "flex" }}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="var(--second-color)" className="bi bi-currency-exchange" viewBox="0 0 16 16">
                                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                              </svg>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/travel-expenses">
                                {t("navigationMenu.anticipo")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/travel-expenses/expense-report">
                                {t("navigationMenu.rela_gastos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/travel-expenses/approvals">
                                {t("navigationMenu.aprobacion")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.historial")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.pagos")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help">
                                {t("navigationMenu.datos")}
                              </NavLink>

                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /order-tracking/:orderNum/:idEtapa */}
                {
                  location.pathname === `/order-tracking/${orderNum}/${idEtapa}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.monitoring")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/logistic-principal">
                                {t("navigationMenu.pending")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-follow-up">
                                {t("navigationMenu.monitoring")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/supplier-data">
                                {t("navigationMenu.supplier")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/ship-to">
                                Ship To
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-reports-view">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /order-detail/:id */}
                {
                  location.pathname === `/order-detail/${id}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.crear_orden")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/logistic-principal">
                                {t("navigationMenu.pending")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-follow-up">
                                {t("navigationMenu.monitoring")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/supplier-data">
                                {t("navigationMenu.supplier")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/ship-to">
                                Ship To
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-reports-view">
                                {t("navigationMenu.reports")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /customers */}
                {
                  location.pathname === "/customers" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.customer")}</h5>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--second-color)" className="bi bi-people-fill" viewBox="0 0 16 16">
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                      </div>

                      {/* Links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <NavLink className="navbar-link-help" to="/customers/new-customer">
                                {t("customerView.newCustomer")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }
                {/* Renderizado si la ruta es /customers/new-customer */}
                {
                  location.pathname === '/customers/new-customer' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("customerView.newCustomer")}</h5>
                      {/* links de ayuda */}
                      <div>
                        <h5 className="help-links">
                          {t("navigationMenu.helpLinks")}
                          <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                          </svg>
                        </h5>
                      </div>
                      <div>
                        <NavLink className="navbar-link-help" to="/customers">
                          {t("navigationMenu.customerList")}
                        </NavLink>
                      </div>
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /customers/customer-detail/:id */}
                {
                  (location.pathname === `/customers/customer-detail/${id}` || location.pathname === `/customers/customer-modify/${codCliente}`) && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.customerDetail")}</h5>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--second-color)" className="bi bi-people-fill" viewBox="0 0 16 16">
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                      </div>
                      {/* links de ayuda */}
                      <div className="row-flex gap-small align-c">
                        <NavLink className="navbar-link-help" to="/customers">
                          {t("navigationMenu.customerList")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/customers/new-customer">
                          {t("customerView.newCustomer")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/equipments">
                          {t("navigationMenu.equipment")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/equipments/create-equipment/equipment">
                          {t("navigationMenu.newEquipment")}
                        </NavLink>
                      </div>
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /customers/customer-detail/:id */}

                {
                  // renderizado si la ruta es /human-capital/employees
                  location.pathname === "/human-capital/employees" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.employees")}</h5>
                      {/* links de ayuda */}
                      <div style={{ display: "flex" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/human-capital/pendings">
                          {t("navigationMenu.pending")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/human-capital/vacations/request">
                          {t("navigationMenu.vacaciones")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        {
                          dataSer && [6, 9].includes(dataSer[0]?.CodPerfil) && (
                            <>
                              <NavLink className="navbar-link-help" to="/human-capital/employees/new-employee">
                                {t("navigationMenu.newEmployeeLabel")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                            </>
                          )
                        }
                        <NavLink className="navbar-link-help">
                          {t("navigationMenu.overTime")}
                        </NavLink>

                      </div>
                    </div>
                  )
                }


                {
                  // renderizado si la ruta es /human-capital/employees
                  location.pathname === `/human-capital/employee/modify/${id}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.employees")}</h5>
                      {/* links de ayuda */}
                      <div style={{ display: "flex" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/human-capital/pendings">
                          {t("navigationMenu.pending")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/human-capital/employees/new-employee">
                          {t("navigationMenu.newEmployeeLabel")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/human-capital/employees">
                          {t("navigationMenu.employees")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">
                          {t("navigationMenu.overTime")}
                        </NavLink>
                      </div>
                    </div>
                  )
                }


                {
                  // renderizado si la ruta es /human-capital/pendings
                  location.pathname === "/human-capital/pendings" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.pending")}</h5>
                      {/* links de ayuda */}
                      <div style={{ display: "flex" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/human-capital/employees">
                          {t("navigationMenu.employees")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/human-capital/employees/new-employee">
                          {t("navigationMenu.newEmployeeLabel")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">
                          {t("navigationMenu.overTime")}
                        </NavLink>
                      </div>
                    </div>
                  )
                }

                {
                  // renderizado si la ruta es /human-capital/vacations
                  location.pathname === "/human-capital/vacations" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.vacaciones")}</h5>
                      {/* links de ayuda */}
                      <div style={{ display: "flex" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/human-capital/pendings">
                          {t("navigationMenu.solicitud")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/human-capital/employees/new-employee">
                          {t("navigationMenu.aprobacion")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/human-capital/employees">
                          {t("navigationMenu.planifi_logis")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">
                          {t("navigationMenu.reportes")}
                        </NavLink>
                      </div>
                    </div>
                  )
                }


                {/* RENDERIZADO SI LA RUTA ES /logistic-principal */}
                {
                  location.pathname === "/logistic-principal" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.logistic")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* LINKS DE AYUDA */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/logistic-follow-up">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/supplier-data">{t("navigationMenu.supplier")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/ship-to">Ship To</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-reports-view">{t("navigationMenu.reports")}</NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado para la ruta de cuando esten en los reportes de logistica */}
                {
                  location.pathname === "/logistic-reports-view" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.reporte_logistica")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* LINKS DE AYUDA */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/logistic-principal">Principal</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-follow-up">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/supplier-data">{t("navigationMenu.supplier")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/ship-to">Ship To</NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /logistic-follow-up */}
                {
                  location.pathname === '/logistic-follow-up' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.monitoring")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {
                        window.screen.width >= 1200 && (
                          <>
                            {/* LINKS DE AYUDA */}
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/logistic-principal">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/supplier-data">{t("navigationMenu.supplier")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/ship-to">Ship To</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-reports-view">{t("navigationMenu.reports")}</NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* Renderizado si la ruta es /ship-to */}
                {
                  location.pathname === '/ship-to' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">Ship to</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* LINKS DE AYUDA */}
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/logistic-principal">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/logistic-follow-up">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/supplier-data">{t("navigationMenu.supplier")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/logistic-reports-view">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /supplier-data */}
                {
                  location.pathname === '/supplier-data' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.supplier")}</h4>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* LINKS DE AYUDA */}
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/logistic-principal">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/logistic-follow-up">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/ship-to">Ship To</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/logistic-reports-view">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /commercial/create-order */}
                {
                  location.pathname === '/commercial/create-order' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.crear_orden")}</h4>
                      {/* LINKS DE AYUDA */}
                      <div>
                        <h5 className="help-links">
                          {t("navigationMenu.helpLinks")}
                          <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                          </svg>
                        </h5>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/commercial/principal">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/projects">{t("navigationMenu.projects")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/request-orders">{t("navigationMenu.request")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/purchase-order">{t("navigationMenu.purchaseOrder")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/reports-view">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /commercial/principal */}
                {
                  location.pathname === '/commercial/principal' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">Principal</h5>
                      {/* LINKS DE AYUDA */}
                      <div>
                        <h5 className="help-links">
                          {t("navigationMenu.helpLinks")}
                          <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                          </svg>
                        </h5>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/commercial/projects">{t("navigationMenu.projects")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/request-orders">{t("navigationMenu.request")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/purchase-order">{t("navigationMenu.purchaseOrder")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/reports-view">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /price-request/codSol=12566 */}
                { //price-request/codSol=12252
                  location.pathname === `/price-request/${id}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.priceRequest")}</h5>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* LINKS DE AYUDA */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/logistic-principal">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-follow-up">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/supplier-data">{t("navigationMenu.supplier")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/ship-to">Ship To</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/logistic-reports-view">{t("navigationMenu.reports")}</NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /part-detail/:id/:codDetSolR/:solRepuesto */}
                {
                  location.pathname === `/part-detail/${codDetSolR}/${solRepuesto}` && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">Detalle</h4>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      {/* LINKS DE AYUDA */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /post-sales/proposal */}
                {
                  location.pathname === "/post-sales/proposal" && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.proposal")}</h4>
                      {/* LINKS DE AYUDA */}
                      <div>
                        <h5 className="help-links">
                          {t("navigationMenu.helpLinks")}
                          <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                          </svg>
                        </h5>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.contract")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/post-sales/quotations">{t("navigationMenu.quotation")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /post-sales/quotations */}
                {
                  location.pathname === "/post-sales/quotations" && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.quotation")}</h4>
                      {/* LINKS DE AYUDA */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--second-color)" className="bi bi-cash-coin" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" />
                          <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" />
                          <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" />
                          <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/post-sales/proposal">{t("navigationMenu.proposal")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.contract")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /post-sales/quotation-detail/:codSolRepuesto */}
                {
                  location.pathname === `/post-sales/quotation-detail/${codSolRepuesto}` && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.quotation")}</h4>
                      {/* LINKS DE AYUDA */}
                      <div>
                        <h5 className="help-links">
                          {t("navigationMenu.helpLinks")}
                          <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                          </svg>
                        </h5>
                      </div>
                      <div className="row-flex gap-small">
                        <NavLink className="navbar-link-help">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/post-sales/proposal">{t("navigationMenu.proposal")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.contract")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/post-sales/quotations">{t("navigationMenu.quotation")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }
                {/* RENDERIZADO SI LA RUTA ES /commercial/purcharse-order */}
                {
                  location.pathname === "/commercial/purchase-order" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.purchaseOrder")}</h5>
                      {/* LINKS DE AYUDA */}
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                          <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/projects">{t("navigationMenu.projects")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.request")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.directorApproval")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/reports-view">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* Renderizado si la ruta es warehouse/tools */}
                {
                  location.pathname === "/warehouse/tools" && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.tools")}</h5>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                          <path d="M16 4.5a4.5 4.5 0 0 1-1.703 3.526L13 5l2.959-1.11q.04.3.041.61" />
                          <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.5 4.5 0 0 0 11.5 9m-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376M3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                        </svg>
                      </div>
                      {/* links */}
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/warehouse/principal">{t("navigationMenu.pending")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* Renderizado si la ruta es warehouse/tools-detail/id */}
                {
                  location.pathname === `/warehouse/tools-detail/${codHerr}` && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.tools")}</h5>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                          <path d="M16 4.5a4.5 4.5 0 0 1-1.703 3.526L13 5l2.959-1.11q.04.3.041.61" />
                          <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.5 4.5 0 0 0 11.5 9m-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376M3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                        </svg>
                      </div>
                      {/* links */}
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help" to="/warehouse/principal">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/warehouse/tools">{t("navigationMenu.tools")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /commercial/purchase-order/order-detail/:codOrden */}
                {
                  (location.pathname === `/commercial/purchase-order/order-detail/${codOrden}` || location.pathname === `/commercial/purchase-order/order-detail/${codOrden}/${codOrdenDet}`) && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.purchaseOrderDetail")}</h5>
                      {/* LINKS DE AYUDA */}
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="var(--second-color)" className="bi bi-truck" viewBox="0 0 16 16">
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/projects">{t("navigationMenu.projects")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.request")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.directorApproval")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/purchase-order">{t("navigationMenu.purchaseOrder")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/reports-view">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /commercial/projects */}
                {
                  location.pathname === `/commercial/projects` && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.projects")}</h4>
                      {/* LINKS DE AYUDA */}
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--second-color)" className="bi bi-pc-display" viewBox="0 0 16 16">
                          <path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0m2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0M9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5M1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2z" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/request-orders">
                          {t("navigationMenu.request")}
                        </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.directorApproval")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/purchase-order">{t("navigationMenu.purchaseOrder")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.monitoring")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/reports-view">{t("navigationMenu.reports")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /commercial/reports-view */}
                {
                  location.pathname === "/commercial/reports-view" && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.reports")}</h4>
                      {/* LINKS DE AYUDA */}
                      <div className="row-flex align-c">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="var(--second-color)" className="bi bi-gear" viewBox="0 0 16 16">
                          <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                          <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                        </svg>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <NavLink className="navbar-link-help">{t("navigationMenu.pending")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.projects")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.request")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.directorApproval")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help" to="/commercial/purchase-order">{t("navigationMenu.purchaseOrder")}</NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                        <NavLink className="navbar-link-help">{t("navigationMenu.monitoring")}</NavLink>
                      </div>
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /data/division */}
                {
                  location.pathname === '/data/division' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.data")}</h4>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/data/line">
                                {t("navigationMenu.line")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/modality">
                                {t("navigationMenu.modality")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/equipment-type">
                                {t("navigationMenu.equipType")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/model">
                                {t("navigationMenu.model")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/target">
                                Target
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/area">
                                {t("navigationMenu.zone")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/region">
                                {t("navigationMenu.region")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/city">
                                {t("navigationMenu.city")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /data/line */}
                {
                  location.pathname === '/data/line' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.data")}</h4>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/data/division">
                                {t("navigationMenu.division")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/modality">
                                {t("navigationMenu.modality")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/equipment-type">
                                {t("navigationMenu.equipType")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/model">
                                {t("navigationMenu.model")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/target">
                                Target
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/area">
                                {t("navigationMenu.zone")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/region">
                                {t("navigationMenu.region")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/city">
                                {t("navigationMenu.city")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /data/modality */}
                {
                  location.pathname === '/data/modality' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.data")}</h4>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/data/division">
                                {t("navigationMenu.division")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/line">
                                {t("navigationMenu.line")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/equipment-type">
                                {t("navigationMenu.equipType")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/model">
                                {t("navigationMenu.model")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/target">
                                Target
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/area">
                                {t("navigationMenu.zone")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/region">
                                {t("navigationMenu.region")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/city">
                                {t("navigationMenu.city")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /data/equipment-type */}
                {
                  location.pathname === '/data/equipment-type' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.data")}</h4>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/data/division">
                                {t("navigationMenu.division")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/line">
                                {t("navigationMenu.line")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/modality">
                                {t("navigationMenu.modality")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/model">
                                {t("navigationMenu.model")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/target">
                                Target
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/area">
                                {t("navigationMenu.zone")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/region">
                                {t("navigationMenu.region")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/city">
                                {t("navigationMenu.city")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /data/model */}
                {
                  location.pathname === '/data/model' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.data")}</h4>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/data/division">
                                {t("navigationMenu.division")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/line">
                                {t("navigationMenu.line")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/modality">
                                {t("navigationMenu.modality")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/equipment-type">
                                {t("navigationMenu.equipType")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/target">
                                Target
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/area">
                                {t("navigationMenu.zone")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/region">
                                {t("navigationMenu.region")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/city">
                                {t("navigationMenu.city")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /data/target */}
                {
                  location.pathname === '/data/target' && (
                    <div style={customerContainerLabel}>
                      <h4 className="top-navbar-h4">{t("navigationMenu.data")}</h4>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/data/division">
                                {t("navigationMenu.division")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/line">
                                {t("navigationMenu.line")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/modality">
                                {t("navigationMenu.modality")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/equipment-type">
                                {t("navigationMenu.equipType")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/model">
                                {t("navigationMenu.model")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/area">
                                {t("navigationMenu.zone")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/region">
                                {t("navigationMenu.region")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/city">
                                {t("navigationMenu.city")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* RENDERIZADO SI LA RUTA ES /data/zona */}
                {
                  location.pathname === '/data/area' && (
                    <div style={customerContainerLabel}>
                      <h5 className="top-navbar-h4">{t("navigationMenu.data")}</h5>
                      {/* links de ayuda */}
                      {
                        window.screen.width >= 1200 && (
                          <>
                            <div>
                              <h5 className="help-links">
                                {t("navigationMenu.helpLinks")}
                                <svg style={{ marginLeft: '0.2rem', width: '1.1rem', height: '1.1rem' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                              </h5>
                            </div>
                            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                              <NavLink className="navbar-link-help" to="/data/division">
                                {t("navigationMenu.division")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/line">
                                {t("navigationMenu.line")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/modality">
                                {t("navigationMenu.modality")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/equipment-type">
                                {t("navigationMenu.equipType")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/model">
                                {t("navigationMenu.model")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/area">
                                Target
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/region">
                                {t("navigationMenu.region")}
                              </NavLink> <span style={{ color: 'var(--white-color)' }}>|</span>
                              <NavLink className="navbar-link-help" to="/data/city">
                                {t("navigationMenu.city")}
                              </NavLink>
                            </div>
                          </>
                        )
                      }
                    </div>
                  )
                }

                {/* ---------------------------------------- */}
                {/* <div className="global-logo">
                  <img src={GlobalLogo} alt="Logo de Global" />
                </div> */}
                <div className="profile">
                  <button onClick={handleMobileOpen}>{initialName.slice(0, 1)}</button>
                </div>
              </div>
            </div>
            {mobileOpen && (
              <div style={{
                animation: 'fadeInAnimation 0.4s ease',
                backgroundColor: 'var(--background-black-alfa)',
                position: 'fixed',
                height: '100%',
                top: '0',
                width: '100%',
                zIndex: '2'
              }}
                onClick={handleMobileOpen}>
                <SideBarMenu handleClick={handleMobileOpen} dataSer={dataSer} />
                {/* ProContratoGE */}
              </div>
            )}
          </>
        )
      }

    </>
  );
};