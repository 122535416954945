// componente para renderizar los pendientes de servicio


import { useEffect, useState } from "react";

// components importation
import { CardInformation } from "./CardInformation.jsx";
import { Close } from "../icons/AppIcons.jsx";

// urls importation
import { urls } from "../../api-urls/api-urls.js";

// global functions
import { getTheToken } from "../../global-functions/globalFunctions.js";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";





export const RendingPendingService = ({ lang = "", area = "", grup = "", title = "", onClose }) => {

  // useParams
  const [params, setParams] = useSearchParams();

  // GET the usenavigate
  const navigate = useNavigate();

  // react translation
  const [t] = useTranslation("global");

  // state definition
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, msg: null });
  const [dataServ, setDataServ] = useState([]);


  // destructuring data
  const { DataRes } = dataServ && dataServ;


  // funcion para solicitar la informacion al servidor
  const fetchDataServ = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.myTasks}?language=${lang}&filtro=${params.get("filt") ?? "0"}&zona=${area}&action=${params.get("name_view")}&grup=${grup}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        return navigate(-1, { replace: true });
      } else {
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang, area, grup]);



  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h4>{title}</h4>
          <button type="button" className="btn-close" onClick={() => onClose("", "")}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            state.msg && (<strong>{state.msg}</strong>)
          }
          {
            state.isLoading && (<h4 style={{ color: "var(--green-color)" }}>{t("PartModify.cargando_info")}</h4>)
          }
          {
            state.dataVisible && (<CardInformation ArrData={DataRes} lang={lang} />)
          }
        </div>
      </div>
    </div>
  );
};