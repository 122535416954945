// componente para ver la foto de la herramienta


// components importation
import { Close } from "../icons/AppIcons.jsx";




export const ViewPhotoTool = ({ Photo = "", onClose }) => {

  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h4>Foto de la herramienta</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          <img className="tool-image" src={`data:image/jpeg;base64,${Photo}`} alt="Tool photo" />
        </div>
      </div>
    </div>
  );
};