// componente para buscar en la lista de FMI

import { useEffect, useState, useContext } from "react";
import { Close, FolderOpenIcon } from "../icons/AppIcons.jsx";

// react router
import { useNavigate, useSearchParams } from "react-router-dom";


import { urls } from "../../api-urls/api-urls.js";
import { getTheToken } from "../../global-functions/globalFunctions.js";


// context importation
import { LangContext } from "../../context/LangContext.jsx";


export const SearchFMI = ({ onClose, setNewList }) => {

  // state definition
  const [form, setForm] = useState({});

  const navigate = useNavigate();


  // language context
  const { lang } = useContext(LangContext);

  // react router dom
  const [params, setParams] = useSearchParams();

  // funcion para manejar el formulario
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setForm((val) => ({ ...val, [name]: value }));
    params.set(name, value);
  };


  // funcion para enviar el formulario
  const handleSubmit = async (event) => {
    event.preventDefault();

    setParams(params);
    try {

      const response = await fetch(`${urls.fmi}?status=${params.get("status") ?? "0"}&fmi=${params.get("fmi") ?? ""}&close=${params.get("close") ?? ""}&language=${lang}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        setNewList(data?.FMI);
        onClose();
      }

    } catch (err) {
      console.log(err);
    }
  };

  // para setear los parametros en el formulario si es que hay
  useEffect(() => {
    if (params.size) {
      setForm((val) => ({ ...val, fmi: params.get("fmi") ?? "", status: params.get("status") ?? "", close: params.get("close") ?? "" }));
    }
  }, []);


  return (
    <div className="background-modal">
      <form onSubmit={handleSubmit} className="container-select-customer">
        <div className="modal-header">
          <h4>Búsqueda</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close widthP="23" heightP="23" />
          </button>
        </div>
        <div className="modal-body">
          <div className="row-flex">
            <div className="col-7">
              <strong>FMI</strong>
            </div>
            <div className="flex-grow">
              <input
                type="text"
                className="input-text-control"
                name="fmi"
                value={form.fmi || ""}
                onChange={handleFormChange} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Estatus</strong>
            </div>
            <div className="flex-grow">
              <select
                name="status"
                value={form.status || ""}
                onChange={handleFormChange}>
                <option value="-1">(Todos)</option>
                <option value="0">En ejecución</option>
                <option value="2">Culminado</option>
              </select>
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Cierre</strong>
            </div>
            <div className="flex-grow">
              <select
                name="close"
                value={form.close || ""}
                onChange={handleFormChange}>
                <option value="-1">(Todos)</option>
                <option value="1">Completado</option>
                <option value="2">No requerido</option>
                <option value="3">No ejecutado</option>
                <option value="4">Rechazado</option>
                <option value="5">Ubicación desconocida</option>
              </select>
            </div>
          </div>
          <div className="row-flex flex-end m-y-small">
            <button type="button" className="btn-success" onClick={() => navigate("/service/fmi/new-fmi")}>
              Nuevo FMI
              <FolderOpenIcon />
            </button>
          </div>
        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success">Buscar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};