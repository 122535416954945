
import React, { useEffect, useState, useContext } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch.jsx";
import { FilterTools } from "../../components/warehouse/FilterTools.jsx";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// REACT TRANSLATION IMPORTATION
import { useTranslation } from "react-i18next";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls.js";

// global functions import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";


// language context
import { LangContext } from "../../context/LangContext.jsx";


export const ToolsView = () => {

  // GEt the language
  const { lang } = useContext(LangContext);

  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();

  // REACT TRANSLATION
  const [t] = useTranslation("global");


  // STATE DEFINITION
  const [listTools, setListTools] = useState([]); // estado para almacenar la data que viene del servidor
  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [state, setState] = useState({ isLoading: false, showModal: false, msg: null });

  // get params
  const [params] = useSearchParams();


  // funcion para setear el estado desde el filtro
  const handleSetNewList = (newState = [], msg = "") => {
    setListTools(newState);
    setState((val) => ({ ...val, msg }));
  };


  // funcion para abrir el filter
  const handleOpenCloseFilter = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };



  // funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.tools}?language=${lang}&filt=${params.get("filt") ?? "0"}&action=init&compa=${params.get("nom_comp") ?? ""}&descrip=${params.get("nom_herr") ?? ""}&divi=${params.get("nom_divi") ?? ""}&line=${params.get("nom_line") ?? ""}&moda=${params.get("nom_moda") ?? ""}&nom_empl=${params.get("nom_empl") ?? ""}&pend_cal=${params.get("pend_cal") ?? ""}&code_tool=${params.get("code") ?? ""}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setListTools(data?.allTools);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 500) {
        // ocurrio un error en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    };
  };


  // useEffect para traer la data del servidor
  useEffect(() => {


    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang]);


  return (
    <>
      {
        state.isLoading && (<LoadingComp />)
      }


      <div className="container display-data">
        {
          state.msg && (
            <h5 style={{ color: "var(--danger-color)" }}>{state.msg}</h5>
          )
        }
        {
          listTools && listTools.length > 0 && (
            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>{t("ToolsView.code")}</th>
                    <th>{t("ToolsView.name")}</th>
                    <th>{t("ToolsView.modality")}</th>
                    <th>{t("ToolsView.partNum")}</th>
                    <th>{t("ToolsView.empre")}</th>
                    <th>{t("ToolsView.lastCalibration")}</th>
                    <th>{t("ToolsView.prox_cal")}</th>
                    <th>Status</th>
                    <th>{t("ToolsView.location")}</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    listTools && listTools.map((tool, index) => (
                      <tr key={index}>
                        <td>{tool.CodHerramienta}</td>
                        <td>
                          <Link className="link-tag" to={`/warehouse/tools-detail/${tool.CodHerramienta}`}>{tool.Nombre}</Link>
                        </td>
                        <td>{tool.Modalidad}</td>
                        <td>{tool.NumParte}</td>
                        <td>{tool.NomEmpresa}</td>
                        <td>{renderDate(tool.FecCalibracion, lang)}</td>
                        <td>{renderDate(tool.NextCalDate, lang)}</td>
                        <td>
                          <span className={tool.Activo ? "completed" : "inactive"}>{tool.status}</span>
                        </td>
                        <td>{tool.Ubicacion}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </div>


      {
        state.showModal && (<FilterTools onClose={handleOpenCloseFilter} lang={lang} onSetNewState={handleSetNewList} />)
      }

      <ButtonSearch onOpenFilter={handleOpenCloseFilter} />
    </>
  );
};