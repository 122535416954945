import React, { useContext } from "react";


// Components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error404Comp } from "../../components/errors/Error404Comp.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";

// URL importation
import { urls } from "../../api-urls/api-urls.js";

// React router dom importation
import { Link, useNavigate, useParams } from "react-router-dom";

// Global functions
import { getTheToken } from "../../global-functions/globalFunctions.js";

// React translation
import { useTranslation } from "react-i18next";


// custom hook import to fetch data
import { useFetchLlegPart } from "../../components/customs/warehouse/useFetchLlegPart.js";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";




export const LlegadaPartesDetail = () => {

  // context language
  const { lang } = useContext(LangContext);

  // context notification
  const { handleNotify } = useContext(NotificationContext);


  // GET react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();


  // GET the useParams
  const { codSolRep } = useParams();

  const END_POINT = `${urls.partArrivalWarehouse}/${codSolRep}/search?language=${lang}`;

  const { partes, state, form, handleChangeForm, handleSaveReturn, handleChangeValParts, handleClearForm, handleCloseModal } = useFetchLlegPart(END_POINT, "GET", codSolRep);

  // Funcion para manejar el envio del formulario al backend
  const onSubmitRequest = async (event) => {
    event.preventDefault();

    const finalObj = {
      form,
      partes
    };

    try {
      const response = await fetch(`${urls.partArrivalWarehouse}/${codSolRep}/search?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true);
        const params = "scrollbars=no"
        // navigate("/warehouse/principal");
        navigate(-1, { replace: true });
        handleClearForm();
        handleCloseModal();
      }

    } catch (error) {
      handleNotify(true, t("General_Comp.error_comunic_serv"), true);
      // setState((val) => ({ ...val, isLoading: false }));
      console.clear();
    }
  };



  return (
    <>
      {/* Component loading */}
      {state.isLoading && (<LoadingComp />)}
      {/* Component error 404 */}
      {state.notFound && (<Error404Comp msgPrin={"Oops no hay nada en p"} navigateTo={"/warehouse/principal"} />)}
      <div className="container display-data">
        {
          state.notAllowed && (<Error403View />)
        }
        {
          state.dataVisible && (
            <>
              <div className="m-y-small">
                <p>
                  <strong>Etapa actual: </strong> <strong className="active-class">Por llegar a almacén</strong>
                </p>
              </div>
              <form onSubmit={onSubmitRequest}>

                {/* FILA */}
                <div className="row-flex">
                  {/* Guia de llegada */}
                  <div className="col-7">
                    <strong>Guía de llegada</strong>
                  </div>
                  <div className="col-5">
                    <input
                      className="input-text-control"
                      type="text"
                      name="guiaLleg"
                      value={form.guiaLleg}
                      onChange={({ target }) => handleChangeForm("guiaLleg", target.value)}
                      autoComplete="off" />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Entrada almacen */}
                  <div className="col-7">
                    <strong>Entrada almacén</strong>
                  </div>
                  <div className="col-5">
                    <input
                      className="input-text-control"
                      type="text"
                      name="entrAlma"
                      value={form.entrAlm}
                      onChange={({ target }) => handleChangeForm("entrAlm", target.value)}
                      autoComplete="off" />
                  </div>
                </div>

                {/* FILA */}
                <div className="row-flex">
                  {/* Comentario */}
                  <div className="col-7">
                    <strong>Comentario</strong>
                  </div>
                  <div className="col-83-87">
                    <textarea
                      className="textarea-description"
                      name="comment"
                      value={form.comment}
                      onChange={({ target }) => handleChangeForm("comment", target.value)}
                      autoComplete="off" />
                  </div>
                </div>

                <hr className="m-y-small" />

                {/* Tabla para las partes */}
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#Orden</th>
                        <th>Cantidad</th>
                        <th>Nro. Parte</th>
                        <th>Descripcion</th>
                        <th>Técnico solicitante</th>
                        <th>Equipo</th>
                        <th>Modalidad</th>
                        <th>Serial</th>
                        <th>Cant. Llegó</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        partes && partes.map((part) => (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="txt-center"><Link className="link-tag">{part.NumOrden}</Link></td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td>
                              <Link className="link-tag" to={`/part-detail/${part.CodDetSolRepuesto}/${part.CodSolRepuesto}`}>{part.NumParte} ({part.Alternativo})</Link>
                            </td>
                            <td>{part.Descripcion}</td>
                            <td>{part.NomEmpleado}</td>
                            <td>{part.NomTipEquipo} - {part.NomModelo}</td>
                            <td>{part.Modalidad}</td>
                            <td className="col-10">
                              <input
                                className="input-text-control txt-center"
                                type="text"
                                name={`serial-${part.CodDetSolRepuesto}`}
                                value={part.Serial}
                                onChange={({ target }) => handleChangeValParts(part.CodDetSolRepuesto, part.CodSolRepuesto, target.value, "serial")}
                                autoComplete="off" />
                            </td>
                            <td className="col-10">
                              <input
                                className="input-text-control txt-center"
                                type="text"
                                name={`cantLlego-${part.CodDetSolRepuesto}`}
                                value={part.CanLlego}
                                onChange={({ target }) => handleChangeValParts(part.CodDetSolRepuesto, part.CodSolRepuesto, target.value, "qty")}
                                autoComplete="off" />
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Botones de accion */}
                <div className="row-flex m-y-medium gap-medium j-content-space-around">
                  <button type="button" className="btn-primary" onClick={() => handleSaveReturn("1")}>{t("RequestPartsView.guar")}</button>
                  <button type="button" className="btn-warning" onClick={() => handleSaveReturn("2")}>{t("RequestPartsView.devol")}</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                </div>

                {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
                {
                  state.showModal && (
                    <ModalConfirm onClose={handleCloseModal} question={state.question} />
                  )
                }

              </form>
            </>
          )
        }
      </div>
    </>
  );
};