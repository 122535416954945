// vista para actualizar la que ya esta creada

import { useContext } from "react";


// components import
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { UploadDocsSolConsump } from "../../components/administration-compon/UploadDocsSolConsump.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";

// app icons import
import { Folder, PDFIcon } from "../../components/icons/AppIcons.jsx";


// react router import
import { useLocation, useNavigate, useParams } from "react-router-dom";

// urls import
import { urls } from "../../api-urls/api-urls.js";

// global functions import
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";


// context import
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";


// custom hooks for fetch data
import { useFetchSolConsumo } from "../../components/customs/administration/useFetchSolConsumo.js";




export const SolConsumoDetailView = () => {

  // lang context
  const { lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // GET the useParams
  const { codSol } = useParams();

  // end point
  const END_POINT = `${urls.autorizaciones}/${codSol}/search?language=${lang}`;


  // GET the custom hook properties
  const { state,
    form,
    solConsump,
    segSolConsump,
    cards,
    division,
    empresa,
    gerencia,
    empPerm,
    handleOpenCloseModalConfir,
    handleChangeFormVal,
    handleCloseOpenDocsModal,
    handlePerfomAction,
    handleCloseOpenSave,
    handleSendData,
    onReloadDataFromSon,
    handleFormatCurrency,
    handleClearForm } = useFetchSolConsumo(END_POINT, lang, codSol, getTheToken().token, 1);


  // GET the react translation
  // const [t] = useTranslation("global");


  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();



  // state definition
  // const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false, codSol: 0 });
  // const [errors, setErrors] = useState({ msg: "", error: false });
  // const [reload, setReload] = useState(false);




  // funcion para manejar el formulario de submit
  const onHandleSubmit = async (event) => {
    event.preventDefault();

    try {
      handleSendData(true);
      const response = await fetch(`${urls.autorizaciones}/${codSol}/search?language=${lang}`, {
        method: "PUT",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if ([200, 201].includes(response.status)) {
        handleNotify(true, data?.msg, false, true);
        // setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        handleCloseOpenSave();

        if (data?.flag) {
          return navigate("/administration/consumption-request");
        }

        if (data?.codSol) {
          navigate(`/administration/consumption-request-detail/${data?.codSol}`);
        } else {
          onReloadDataFromSon(true);
        }

        handleClearForm();
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if ([403, 404].includes(response.status)) {
        handleNotify(true, data?.msg, true);
        handleCloseOpenSave();
      }

      handleSendData();

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para descargar el documento
  const onHandleDownload = async (action = "") => {
    try {
      const response = await fetch(`${urls.autorizaciones}?language=${lang}&action=${action}&codSol=${codSol}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      // const data = await
      if (response.status === 200) {

        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear un enlace oculto
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobURL;
        a.download = action === "9" ? "invoice.pdf" : "formulario-solicitud.pdf";
        document.body.appendChild(a);

        a.click();

        // limpiar la url
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);

        // setNotification((val) => ({ ...val, show: true, msg: "Se descargó el documento.", great: true }));

      } else {
        const data = await response.json();

        if (response.status === 404) {
          // setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
          handleNotify(true, data?.msg, true);
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        }

      }

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="container display-modal">
      {
        state.modalConfirm && (
          <div className="background-modal">
            <div className="container-modal-upload-docs gap-medium align-c justify-center" style={{ display: "flex", flexDirection: "column" }}>
              <h4 className="txt-center">Esta solicitud se encuentra aprobada.</h4>

              <div className="row-flex justify-center">
                <button type="button" className="btn-info" onClick={handleOpenCloseModalConfir}>OK!</button>
              </div>
            </div>
          </div>
        )
      }
      {
        // loading components
        state.isLoading && (<LoadingComp />)
      }
      {
        // error 403 component
        state.unauthorized && (<Error403View />)
      }
      {
        state.dataVisible && (
          <form onSubmit={onHandleSubmit}>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Solicitud</strong>
              </div>
              <div className="col-3">
                <h4>{form.solic}</h4>
              </div>
              <div className="col-7">
                <strong>Etapa</strong>
              </div>
              <div className="col-3">
                <h5 className="active-class" style={{ textDecoration: "underline" }}>{form.nomEtapa}</h5>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Tarjeta</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <select
                      name="tarje"
                      value={form.idTarj}
                      onChange={({ target }) => handleChangeFormVal("idTarj", target.value)}>
                      <option value=""></option>
                      {
                        cards && cards.map((c) => (
                          <option key={c.CodId} value={c.CodId}>{c.NumTarjeta}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.tarjeta}</span>
                  )
                }
              </div>
              <div className="col-7">
                <strong>Titular</strong>
              </div>
              <div className="col-3">
                <span>{form.titu} / Fecha vencimiento {form.fecVen}</span>
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Empresa</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <select
                      name="company"
                      value={form.empresa}
                      onChange={({ target }) => handleChangeFormVal("empresa", target.value)}>
                      <option value=""></option>
                      {
                        empresa && empresa.map((emp) => (
                          <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.nomEmpresa}</span>
                  )
                }
              </div>
              <div className="col-7">
                <strong>Tipo de gasto</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <select
                      name="tipGasto"
                      value={form.tipGasto}
                      onChange={({ target }) => handleChangeFormVal("tipGasto", target.value)}>
                      <option value=""></option>
                      <option value="1">Compra</option>
                      <option value="2">Hospedaje</option>
                    </select>
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.nomTipGasto}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>División empresa</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <select
                      name="group"
                      value={form.idDivi}
                      onChange={({ target }) => handleChangeFormVal("idDivi", target.value)}>
                      <option value=""></option>
                      {
                        division && division.map((div) => (
                          <option key={div.CodGrupo} value={div.CodGrupo}>{div.NomGrupo}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.division}</span>
                  )
                }
              </div>
              <div className="col-7">
                <strong>Gerencia</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <select
                      name="geren"
                      value={form.idGeren}
                      onChange={({ target }) => handleChangeFormVal("idGeren", target.value)}>
                      <option value=""></option>
                      {
                        gerencia && gerencia.filter((filtGe) => filtGe.CodGrupo.toString() === form.idDivi.toString()).map((ger) => (
                          <option key={ger.CodGerencia} value={ger.CodGerencia}>{ger.NomGerencia}</option>
                        ))
                      }
                    </select>
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.gerencia}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Suplidor</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <input
                      className="input-text-control"
                      type="text"
                      name="consuFinal"
                      value={form.consuFin}
                      onChange={({ target }) => handleChangeFormVal("consuFin", target.value)} />
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.consuFin}</span>
                  )
                }
              </div>
              {
                form.tipGasto === "2" && (
                  <>
                    {
                      form.idEtapa === 1 && (
                        <>
                          <div className="col-7">
                            <strong>Fin del Hospedaje</strong>
                          </div>
                          <div className="col-3">
                            <input
                              className="input-date-styles"
                              type="date"
                              name="fecFinHosp"
                              value={form.fecFinHospe}
                              onChange={({ target }) => handleChangeFormVal("fecFinHospe", target.value)} />
                          </div>
                        </>
                      )
                    }
                    {
                      form.idEtapa > 1 && form.tipGasto === "2" && (
                        <>
                          <div className="col-7">
                            <strong>Fin del Hospedaje</strong>
                          </div>
                          <div className="col-3">
                            <span>{renderDate(form.fecFinHospe, lang)}</span>
                          </div>
                        </>
                      )
                    }
                  </>
                )
              }
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Número de itinerario</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <input
                      className="input-text-control"
                      type="text"
                      name="numItiner"
                      value={form.numItiner}
                      onChange={({ target }) => handleChangeFormVal("numItiner", target.value)}
                      autoComplete="off" />
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.numItiner}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Monto</strong>
              </div>
              <div className="col-3">
                {
                  form.idEtapa === 1 && (
                    <input
                      className="input-text-control"
                      type="text"
                      name="amount"
                      value={form.monto}
                      onChange={({ target }) => handleFormatCurrency(target.value, 1)}
                      autoComplete="off" />
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span className="active-class">${form.monto}</span>
                  )
                }
              </div>
              <div className="col-7">
                {
                  form.idEtapa === 1 && (
                    <select
                      name="currency"
                      value={form.moneda}
                      onChange={({ target }) => handleChangeFormVal("moneda", target.value)}>
                      <option value="">Seleccionar la moneda</option>
                      <option value="1">RD$</option>
                      <option value="2">US$</option>
                      <option value="3">TT$</option>
                    </select>
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.nomMoneda}</span>
                  )
                }
              </div>
              <div className="col-7">
                <strong>Fecha requerida</strong>
              </div>
              <div className="col-7">
                {
                  form.idEtapa === 1 && (
                    <input
                      className="input-date-styles"
                      type="date"
                      name="fecReque"
                      value={form.fecReque}
                      onChange={({ target }) => handleChangeFormVal("fecReque", target.value)} />
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{renderDate(form.fecReque, lang)}</span>
                  )
                }
              </div>
            </div>

            {
              (form.tipGasto === "2" && form.idEtapa >= 3) && (
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Monto final</strong>
                  </div>
                  <div className="col-7">
                    <input
                      className="input-text-control"
                      type="text"
                      name="montFinal"
                      disabled={form.idEtapa >= 4}
                      value={form.montFinal}
                      onChange={({ target }) => handleFormatCurrency(target.value, 2)}
                      autoComplete="off" />
                  </div>
                </div>
              )
            }

            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Concepto</strong>
              </div>
              <div className="col-80-94">
                {
                  form.idEtapa === 1 && (
                    <textarea
                      className="textarea-description"
                      name="concept"
                      value={form.concept}
                      onChange={({ target }) => handleChangeFormVal("concept", target.value)}
                      autoComplete="off" />
                  )
                }
                {
                  form.idEtapa > 1 && (
                    <span>{form.concept}</span>
                  )
                }
              </div>
            </div>
            {/* fila */}
            <div className="row-flex">
              <div className="col-7">
                <strong>Cargar factura</strong>
              </div>
              <div className="col-10">
                <button type="button" className="btn-docs" onClick={handleCloseOpenDocsModal}>
                  <Folder />
                </button>
              </div>
              {
                form.documento && (
                  <div className="col-10">
                    <button type="button" className="btn-pdf" onClick={() => onHandleDownload("9")}>
                      <PDFIcon />
                    </button>
                  </div>
                )
              }
              <div className="col-10"></div>
            </div>
            {/* fila */}
            {
              form.idEtapa > 1 && (
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Acción</strong>
                  </div>
                  <div className="col-7">
                    <select
                      name="action"
                      value={form.action}
                      onChange={({ target }) => handleChangeFormVal("action", target.value)}>
                      <option value="">--Seleccionar acción--</option>
                      {
                        form.idEtapa === 2 && (
                          <>
                            {
                              empPerm && (empPerm[0]?.CodEmpleado === form.lider || [6, 7].includes(empPerm[0]?.CodPerfil)) && (
                                <>
                                  <option value="1">Aprobar</option>
                                  <option value="2">Devolver</option>
                                </>
                              )
                            }
                            {
                              empPerm && (empPerm[0]?.CodEmpleado === form.empSoli) && (
                                <option value="4">Reenviar autorización</option>
                              )
                            }
                          </>
                        )
                      }
                      {
                        empPerm && ((empPerm[0]?.CodEmpleado === form.empSoli || empPerm[0]?.CodPerfil === 6) && form.idEtapa === 3) && (
                          <>
                            {
                              solConsump && solConsump[0]?.Documento && (
                                <option value="1">Factura cargada</option>
                              )
                            }
                            <option value="2">Factura no cargada</option>
                          </>
                        )
                      }
                      {
                        // etapa pendiente de cierre
                        form.idEtapa === 4 && ((empPerm && empPerm[0]?.NivViatico >= 4 && empPerm[0]?.CodPerfil === 4) || empPerm[0]?.CodPerfil === 6) && (
                          <option value="1">Proceso culminado</option>
                        )
                      }
                      <option value="3">Comentario</option>
                    </select>
                  </div>
                  {
                    (form.idEtapa === 2 && form.action === "4") && (
                      <>
                        <div className="fade-animation col-9">
                          <strong>Copiar a</strong>
                        </div>
                        <div className="fade-animation col-4">
                          <input
                            className="input-text-control"
                            type="text"
                            name="copyReq"
                            value={form.copiaEmail}
                            onChange={({ target }) => handleChangeFormVal("copiaEmail", target.value)} />
                        </div>
                      </>
                    )
                  }
                </div>
              )
            }
            {
              form.idEtapa === 3 && (
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Nota</strong>
                  </div>
                  <div className="col-80">
                    <strong className="required">Tiene que imprimir el formulario en físico para pasar a la siguiente etapa.</strong>
                  </div>
                </div>
              )
            }
            {/* fila */}
            <div className="row-flex m-y-small">
              <div className="col-7">
                <strong>Comentario</strong>
              </div>
              <div className="col-80-94">
                <textarea
                  className="textarea-description"
                  name="concept"
                  value={form.comentario}
                  onChange={({ target }) => handleChangeFormVal("comentario", target.value)}
                  autoComplete="off" />
                {/* {
                  errors.error && (
                    <span className="required fade-animation">{errors.msg}</span>
                  )
                } */}
              </div>
            </div>
            {/* <hr className="m-y-small" /> */}

            <div className="row-flex j-content-space-around m-y-medium">
              <button type="button" className="btn-primary" onClick={() => handlePerfomAction(1)}>Guardar</button>
              {
                empPerm && empPerm[0]?.CodPerfil === 6 && (
                  <button type="button" className="btn-danger" onClick={() => handlePerfomAction(4)}>Eliminar</button>
                )
              }
              {
                form.idEtapa === 1 && (empPerm && (empPerm[0]?.CodEmpleado === form.empSoli || empPerm[0]?.CodPerfil === 6)) && (
                  <button type="button" className="btn-success" onClick={() => handlePerfomAction(3)}>Enviar</button>
                )
              }
              {
                form.idEtapa >= 3 && (
                  <>
                    <button type="button" className="btn-primary" onClick={() => onHandleDownload("10")}>Imprimir</button>
                  </>
                )
              }
              {
                form.idEtapa >= 3 && (empPerm && (empPerm[0]?.CodEmpleado === form.empSoli)) && (
                  <button type="button" className="btn-success" onClick={() => handlePerfomAction(2)}>Copiar solicitud</button>
                )
              }
              <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
            </div>

            <div className="table-container-mobile">
              <table className="table">
                <thead className="table-head">
                  <tr>
                    <th>Fecha</th>
                    <th>Etapa</th>
                    <th>Empleado</th>
                    <th>Comentario</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    segSolConsump && segSolConsump.map((seg) => (
                      <tr key={seg.CodSegSolicitud}>
                        <td className="txt-center">{renderDate(seg.FecIngreso, lang)}</td>
                        <td className="txt-center">{seg.NomEtapa}</td>
                        <td className="txt-center">{seg.NomEmpleado}</td>
                        <td style={{ color: seg.Valor !== "" ? seg.Valor : "black", fontWeight: seg.Valor !== "" ? "bold" : "" }}>{seg.Comentario}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>

            {
              state.modalSave && (
                <ModalConfirm onClose={handleCloseOpenSave} question={state.questionAction} sending={state.loadingData} />
              )
            }
          </form>
        )
      }

      {
        // modal para cargar los documentos
        state.modalDocs && (
          <UploadDocsSolConsump onClose={handleCloseOpenDocsModal} lang={lang} codSol={codSol} token={getTheToken().token} onHandleReload={onReloadDataFromSon} />
        )
      }
    </div>
  );
};