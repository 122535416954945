// custom hook para las solicitudes de precio

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export const useFetchSolPrice = (CodSol = "", urls = {}, token = "", lang = "") => {

  // state definition
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, showModal: false, question: "", sending: false, reload: false });
  const [form, setForm] = useState({ tipApro: "", comentario: "", workFlow: "", action: 0 });
  const [controller, setController] = useState(null); // controlador para cancelar la data al servidor
  const [parts, setParts] = useState([]); // estado para setear las partes
  const [empPerm, setEmpPerm] = useState([]);
  const [solRep, setSolRep] = useState({}); // Estado para colocar la solicitud de repuesto


  const [t] = useTranslation("global");


  // funcion para disparar la actualizacion desde la modificacion de las partes
  const onReloadFromChild = (newVal = false) => {
    setState((val) => ({ ...val, reload: newVal }));
  };

  // funcion para cancelar el boton de enviar
  const handleSendServer = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };


  // funcion para limpiar los valores del formulario
  const handleClearForm = () => {
    setForm((val) => ({ ...val, action: 0, comentario: "" }));
  };


  // funcion para cambiar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };

  // funcion para cerrar el modal
  const handleCloseOpenModal = () => {
    setState((val) => ({ ...val, showModal: !state.showModal }));
  };


  // funcion para modificar el precio de lista
  const handleListPrice = (CodSol = 0, CodDetSol = 0, newVal = "") => {
    setParts((val) => {
      return val.map((part) => {
        if (part.CodSolRepuesto === CodSol && part.CodDetSolRepuesto === CodDetSol) {
          if (isNaN(newVal)) {
            return part;
          }
          let Price = 0;
          Price = parseFloat(newVal) * (1 - part.Descuento / 100);

          if (newVal === "") {
            newVal = "";
            Price = "";
          } else {
            Price.toFixed(2);
          }

          return {
            ...part,
            Lista: newVal,
            Precio: Price
          };
        }
        return part;
      });
    });
  };

  // funcion para modificar el precio
  const handleChangePrice = (CodSol = 0, CodDet = 0, newVal = "") => {
    setParts((val) => {
      return val.map((part) => {
        if (part.CodSolRepuesto === CodSol && part.CodDetSolRepuesto === CodDet) {

          const Lista = part.Lista || 0; // evitar valores undefined

          const Descuento = Lista > 0 ? ((Lista - parseFloat(newVal) || "") / Lista) * 100 : 0;

          return {
            ...part,
            Precio: newVal || "",
            Descuento: Descuento.toFixed(2)
          };
        }
        return part;
      });
    });
  };


  // funcion para modificar el descuento
  const handleDiscount = (CodSol = 0, CodDet = 0, newVal = "") => {
    setParts((val) => {
      return val.map((part) => {
        if (part.CodSolRepuesto === CodSol && part.CodDetSolRepuesto === CodDet) {
          if (isNaN(newVal)) {
            return part;
          }
          let DiscPrice = 0;
          DiscPrice = part.Lista - ((part.Lista * parseFloat(newVal)) / 100);

          if (newVal === "") {
            newVal = "";
            DiscPrice = part.Lista;
          } else {
            DiscPrice.toFixed(2);
          }

          return {
            ...part,
            Descuento: newVal,
            Precio: DiscPrice
          }
        }
        return part;
      });
    });
  };


  // funcion para cambiar el Itbis
  const handleItbis = (CodSol = 0, CodDet, newVal = "") => {
    setParts((val) => {
      return val.map((part) => {
        if (part.CodSolRepuesto === CodSol && part.CodDetSolRepuesto === CodDet) {
          if (isNaN(newVal)) {
            return part;
          }
          return { ...part, Itbis: newVal };
        }
        return part;
      });
    });
  };


  // funcion para cambiar el devolver
  const handleChangeVal = (CodSol = 0, CodDet = 0, newVal = "", name = "") => {
    setParts((val) => {
      return val.map((part) => {
        if (part.CodSolRepuesto === CodSol && part.CodDetSolRepuesto === CodDet) {
          return { ...part, [name]: newVal };
        }
        return part;
      });
    });
  }; // Devolver, Disponible, BackOrder


  // funcion para manejar el enviar el formulario
  const handlePerformAction = (id = 0) => {
    const QUESTIONS = {
      1: t("RequestPartsView.enviar_a_cotiza"),
      2: t("RequestPartsView.enviar_a_apro_admin"),
      3: t("RequestPartsView.accion_comment")
    };

    setState((val) => ({ ...val, showModal: true, question: QUESTIONS[id] }));
    setForm((val) => ({ ...val, action: id }));
  };




  // Funcion para solicitar la data al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(`${urls.priceRequest}/${CodSol}/search?language=${lang}`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const { solRepuesto, detSolRepuesto, EmpPerm } = await response.json();

      // console.clear();
      setState((val) => ({ ...val, reload: false }));

      if (response.status === 200) {
        // setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
        setSolRep(solRepuesto[0]);
        setParts(detSolRepuesto);
        setEmpPerm(EmpPerm);
        setForm((val) => ({ ...val, tipApro: solRepuesto[0]?.IdTipAprobacion, workFlow: detSolRepuesto[0]?.WorkFlow ?? "" }));
      } else if (response.status === 404) {
        // 
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        // return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));
    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    document.title = lang === "es" ? "Detalle de la Solicitud" : "Request Detail";

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodSol, lang, state.reload]);




  return {
    state,
    form,
    parts,
    solRep,
    empPerm,
    handleListPrice,
    handleDiscount,
    handleItbis,
    handleChangeVal,
    handlePerformAction,
    handleChangeFormVal,
    handleCloseOpenModal,
    onReloadFromChild,
    handleClearForm,
    handleSendServer,
    handleChangePrice
  };
};