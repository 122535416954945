// componente para listar los componentes de una herramienta

import { useEffect, useState } from "react";

// components importation
import { Close, PencilSquareIcon } from "../icons/AppIcons.jsx";

import { urls } from "../../api-urls/api-urls.js";

import { getTheToken } from "../../global-functions/globalFunctions.js";




export const ListCompTool = ({ CodHerr = "", lang = "", onClose }) => {

  // state definition
  const [controller, setController] = useState(null); // estado para cancelar la peticion
  const [list, setList] = useState([]); // estado para cargar la lista


  // funcion para traer la data del servidor
  const fetchData = async () => {
    const abortControl = new AbortController();

    setController(abortControl);

    try {
      const response = await fetch(`${urls.tools}/${CodHerr}/search?language=${lang}&historial=no&action=comp`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortControl.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setList(data?.listComp)
      }

    } catch (err) {
      console.log(err);
    }
  };


  // useEff
  useEffect(() => {
    fetchData();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodHerr]);



  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h4>Componentes</h4>
          <button type="reset" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            list && list.length > 0 && (
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th></th>
                      <th>Nro. Parte</th>
                      <th>Descripción</th>
                      <th>Cantidad</th>
                      <th>Cantidad Minima</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      list && list.map((lst) => (
                        <tr key={lst.CodComponente}>
                          <td></td>
                          <td>{lst.NumParte}</td>
                          <td>{lst.Nombre}</td>
                          <td className="txt-center">{lst.Cantidad}</td>
                          <td className="txt-center">{lst.CanMinima}</td>
                          <td className="txt-center">
                            <button className="btn-close">
                              <PencilSquareIcon fillP="var(--first-color)" />
                            </button>
                          </td>
                          <td className="txt-center">
                            <button className="btn-close">
                              <Close fillP="var(--danger-color)" />
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};