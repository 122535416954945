

// componente para listar los documentos de la herramienta

import { useEffect, useState } from "react";

// components importation
import { Close, PDFIcon } from "../icons/AppIcons.jsx";


import { urls } from "../../api-urls/api-urls.js";

// custom component
import { useDownloadDocs } from "../customs/useDownloadDocs.js";


import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";


import { useTranslation } from "react-i18next";




export const ListDocsTools = ({ onClose, CodHerr = "", lang = "" }) => {


  // state definition
  const [controller, setController] = useState(null); // estado para cancelar la peticion
  const [list, setList] = useState([]); /// estado para la lista de certificados
  const [state, setState] = useState({ isLoading: false, downloading: false, msg: null });


  // GET custom hook download docs
  const { downLoadFile } = useDownloadDocs();

  // GET the react translation
  const [t] = useTranslation("global");


  // funcion para descargar el documento
  const handleDownloadDocs = (name = "", CodComp = 0) => {
    const EndPoint = `${urls.tools}/${CodHerr}/${CodComp}/search?language=${lang}`;

    downLoadFile(EndPoint, name, "application/pdf");
  };


  // funcion para solicitar la data al servidor
  const fetchDataServ = async () => {
    const abortContr = new AbortController();

    setController(abortContr);
    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.tools}/${CodHerr}/search?language=${lang}&historial=no&action=lst_docs`, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setList(data?.lstDocs);
      } else if (response.status === 404) {
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    }
  };


  // useEffect para cargar la data
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [CodHerr]);



  return (
    <div className="background-modal">
      <div className="container-select-customer">
        <div className="modal-header">
          <h4>{t("ToolsView.cert_cali")}</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            state.msg && (
              <h5 style={{ color: "var(--danger-color)" }}>{state.msg}</h5>
            )
          }
          {
            list && list.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("ToolsView.cargado_por")}</th>
                    <th>{t("ToolsView.fecha")}</th>
                    <th>{t("ToolsView.nombre")}</th>
                    <th>{t("ToolsView.documento")}</th>
                  </tr>
                </thead>
                <tbody className="t-report-body">
                  {
                    list && list.map((lst) => (
                      <tr key={lst.CodHerDocumento} style={{ borderBottom: "1px solid #dcdcdc" }}>
                        <td className="txt-center">{lst.NomEmpleado}</td>
                        <td className="txt-center">{renderDate(lst.FecIngreso, lang)}</td>
                        <td className="txt-center">{lst.Nombre}</td>
                        <td className="row-flex align-c j-content-center">
                          <button type="button" className="btn-pdf" onClick={() => handleDownloadDocs(lst.Nombre, lst.CodHerDocumento)}>
                            <PDFIcon />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            )
          }
        </div>
      </div>
    </div>
  );
};