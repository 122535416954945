
import React, { useEffect, useContext } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error403View } from "../error-403-view/Error403View.jsx";
import { ModalConfirm } from "../../components/modal-confirm/ModalConfirm.jsx";
import { Add, ToolIcon, Folder } from "../../components/icons/AppIcons.jsx";
import { AddCompTool } from "../../components/warehouse/AddCompTool.jsx";
import { ListCompTool } from "../../components/warehouse/ListCompTool.jsx";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// GLOBAL FUNCTIONS IMPORTATION
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";

// react translation import
import { useTranslation } from "react-i18next";


// context importation
import { LangContext } from "../../context/LangContext.jsx"
import { NotificationContext } from "../../context/NotificationContext.jsx"


import { useFetchTools } from "../../components/customs/warehouse/useFetchTools.js";
import { ViewPhotoTool } from "../../components/warehouse/ViewPhotoTool.jsx";
import { UploadDocsTools } from "../../components/warehouse/UploadDocsTools.jsx";
import { ListDocsTools } from "../../components/warehouse/ListDocsTools.jsx";




export const ToolsDetailView = () => {

  // GET the context language
  const { lang } = useContext(LangContext);

  // GET the notification context
  const { handleNotify } = useContext(NotificationContext);


  // GET react transtlation
  const [t] = useTranslation("global");

  // GET navigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // GET the params
  const { codHerr } = useParams();


  // useFetchTools importation
  const { form, state, dataServ, handleChangeForm, handleSending, handleOpenClose, handleSave, handleMultiValsForm, handleOpenCloseAddComp, handleReloadData, handleOpenCloseListComp, handleOpenClosePhoto, handleOpenCloseDocs, handleCloseOpenListDocs } = useFetchTools(lang, codHerr);


  // DESTRUCTURING DATA SERVER
  const { tool, division, linea, modalidad, empresa, suplidor, empleados, empPerm, countComp, zonas } = dataServ && dataServ;


  // funcion para manejar el submit del formulario al servidor
  const onHandleUpdate = async (event) => {
    event.preventDefault();

    try {
      handleSending(true);
      const response = await fetch(`${urls.tools}/${codHerr ?? "init"}/search?language=${lang}&action=${codHerr ? "upt-1" : "crt-1"}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleOpenClose();
        handleNotify(true, data?.msg, false, true);
      } else if (response.status === 201) {
        handleOpenClose();
        navigate(data?.navigateTo);
        handleNotify(true, data?.msg, false, true);
      } else if (response.status === 404) {
        handleOpenClose();
        handleNotify(true, data?.msg, true);
      }

      handleSending();

    } catch (error) {
      console.log(error)
    }
  };



  // useEffect para setear la data en el estado para poder cambiar los valores
  useEffect(() => {
    if (codHerr && tool && tool.length > 0) {

      const {
        FecAdquisicion, FecCalibracion, FecRevision, CodEmpresa, CodHerramienta, NumParte, Nombre, IdTipo,
        CodEmpleado, CodDivision, CodLinea, CodModalidad, Serial, OrdCompra, CodProveedor, Precio, Dimension,
        Peso, PerCalibracion, PerRevision, Ubicacion, Observacion, Activo, NomEmpIngr, NomEmpMod, FecIngreso, FecModificacion, Foto, IdPeriodo, CodZona
      } = tool[0];

      const FecAdqui = FecAdquisicion ? FecAdquisicion.slice(0, 10) : "";
      const FecCalibr = FecCalibracion ? FecCalibracion.slice(0, 10) : "";
      const FecRevi = FecRevision ? FecRevision.slice(0, 10) : "";

      const values = {
        empresa: CodEmpresa?.toString(),
        codigo: CodHerramienta,
        numParte: NumParte,
        descripcion: Nombre,
        tipoHerr: IdTipo?.toString(),
        empleado: CodEmpleado,
        division: CodDivision,
        linea: CodLinea,
        modalidad: CodModalidad,
        serial: Serial,
        ordCompra: OrdCompra,
        fecAdqui: FecAdqui,
        proveedor: CodProveedor?.toString(),
        precio: Precio,
        dimension: Dimension,
        peso: Peso,
        fecCali: FecCalibr,
        valor: PerCalibracion,
        fecRevi: FecRevi,
        periodRevi: PerRevision,
        ubicacion: Ubicacion,
        observa: Observacion,
        activo: Activo,
        nomEmpIng: NomEmpIngr,
        empMod: NomEmpMod,
        fecIng: FecIngreso,
        fecMod: FecModificacion,
        idPer: IdPeriodo,
        zona: CodZona
      };

      handleMultiValsForm(values);

      // setStateHerr(tool);
    }
  }, [tool]);


  // useEefect para cargar la empresa en el formulario
  useEffect(() => {
    if (empPerm && empPerm.length > 0) {
      const empl = {
        empresa: empPerm[0]?.CodEmpresa
      };

      handleMultiValsForm(empl);
    }
  }, [empPerm]);




  return (
    <>
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      {
        // 403 Component
        state.unauthorized && (<Error403View />)
      }
      <div className="container display-data">
        {
          state.dataVisible && (
            <form onSubmit={onHandleUpdate}>
              {/* FILA 1 */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>{t("RequestPartsView.empresa")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="empresa"
                    value={form.empresa || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    {
                      empresa && empresa.map((emp) => (
                        <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-7">
                  <strong>{t("WareHouseView.zone")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="zona"
                    value={form.zona || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    {
                      zonas && zonas.filter((filtZ) => filtZ.CodEmpresa?.toString() === form.empresa || "").map((zon) => (
                        <option key={zon.CodZona} value={zon.CodZona}>{zon.NomZona}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="row-flex">
                {/* CODIGO */}
                <div className="col-7">
                  <strong>{t("ToolsView.code")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="codigo"
                    value={form.codigo || ""}
                    onChange={handleChangeForm}
                    disabled={codHerr} />
                </div>
                {/* NRO. PARTE */}
                <div className="col-7">
                  <strong>{t("ToolsView.partNum")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="numParte"
                    value={form.numParte || ""}
                    onChange={handleChangeForm} />
                </div>
              </div>

              {/* FILA 2 */}
              <div className="row-flex">
                {/* DESCRIPCION */}
                <div className="col-7">
                  <strong>{t("TableParts.descrip")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="descripcion"
                    value={form.descripcion || ""}
                    onChange={handleChangeForm} />
                </div>
                {/* TIPO */}
                <div className="col-7">
                  <strong>{t("ToolsView.tipo")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="tipoHerr"
                    value={form.tipoHerr || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    <option value="0">{t("ToolsView.herramienta")}</option>
                    <option value="1">{t("ToolsView.suministro")}</option>
                    <option value="2">{t("ToolsView.asignada")}</option>
                  </select>
                </div>
              </div>

              { // si la herramienta es asignada
                (form.tipoHerr && form.tipoHerr === "2") && (
                  <div className="row-flex fade-animation">
                    <div className="col-7">
                      <strong>{t("ToolsView.empleado")}</strong>
                    </div>
                    <div className="col-3">
                      <select
                        name="empleado"
                        value={form.empleado || ""}
                        onChange={handleChangeForm}>
                        <option value=""></option>
                        {
                          empleados && empleados.map((empl) => (
                            <option key={empl.CodEmpleado} value={empl.CodEmpleado}>{empl.NomEmpleado}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                )
              }

              { // si la herramienta es suministro
                (form.tipoHerr && form.tipoHerr === "1") && (
                  <div className="row-flex fade-animation">
                    <div className="col-7">
                      <strong>{t("ToolsView.cantidad")}</strong>
                    </div>
                    <div className="col-3">
                      <input
                        className="input-text-control"
                        type="text"
                        name="qty"
                        value={form.qty || ""}
                        onChange={handleChangeForm} />
                    </div>
                    <div className="col-7">
                      <strong>{t("ToolsView.cant_min")}</strong>
                    </div>
                    <div className="col-3">
                      <input
                        className="input-text-control"
                        type="text"
                        name="qtyMin"
                        value={form.qtyMin || ""}
                        onChange={handleChangeForm} />
                    </div>
                  </div>
                )
              }

              {/* FILA 3 */}
              <div className="row-flex">
                {/* DIVISION */}
                <div className="col-7">
                  <strong>{t("ToolsView.division")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="division"
                    value={form.division || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    {
                      division && division.map((divi) => (
                        <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* LINEA */}
                <div className="col-7">
                  <strong>{t("ToolsView.linea")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="linea"
                    value={form.linea || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    {
                      linea && linea.filter((lin) => lin.CodDivision === form.division || "").map((lin) => (
                        <option key={lin.CodLinea} value={lin.CodLinea}>{lin.NomLinea}</option>
                      ))
                    }
                  </select>
                </div>
              </div>

              {/* FILA 4 */}
              <div className="row-flex">
                {/* MODALIDAD */}
                <div className="col-7">
                  <strong>{t("ToolsView.modalidad")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="modalidad"
                    value={form.modalidad || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    {
                      modalidad && modalidad.filter((mod) => mod.CodLinea === form.linea || "").map((mod) => (
                        <option key={mod.CodModalidad} value={mod.CodModalidad}>{mod.NomModa}</option>
                      ))
                    }
                  </select>
                </div>
                {/* SERIAL */}
                <div className="col-7">
                  <strong>Serial</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="serial"
                    value={form.serial || ""}
                    onChange={handleChangeForm} />
                </div>
              </div>

              {/* FILA 5 */}
              <div className="row-flex">
                {/* ORDEN DE COMPRA */}
                <div className="col-7">
                  <strong>{t("ToolsView.orden_compr")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="ordCompra"
                    value={form.ordCompra || ""}
                    onChange={handleChangeForm} />
                </div>
                {/* FECHA DE ADQUISION */}
                <div className="col-7">
                  <strong>{t("ToolsView.fec_adqui")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecAdqui"
                    value={form.fecAdqui || ""}
                    onChange={handleChangeForm} />
                </div>
              </div>

              {/* FILA 6 */}
              <div className="row-flex">
                {/* PROVEEDOR */}
                <div className="col-7">
                  <strong>{t("ToolsView.proveedor")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="proveedor"
                    value={form.proveedor || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    {
                      suplidor && suplidor.map((sup) => (
                        <option key={sup.CodProveedor} value={sup.CodProveedor}>{sup.Nombre}</option>
                      ))
                    }
                  </select>
                </div>
                {/* PRECIO */}
                <div className="col-7">
                  <strong>{t("ToolsView.precio")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="precio"
                    value={form.precio || ""}
                    onChange={handleChangeForm} />
                </div>
              </div>

              {/* FILA 7 */}
              <div className="row-flex">
                {/* DIMENSION */}
                <div className="col-7">
                  <strong>{t("ToolsView.dimen")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="dimension"
                    value={form.dimension || ""}
                    onChange={handleChangeForm} />
                </div>
                {/* PESO KG */}
                <div className="col-7">
                  <strong>{t("ToolsView.peso")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="peso"
                    value={form.peso || ""}
                    onChange={handleChangeForm} /></div>
              </div>

              <div className="row-flex">
                <div className="col-7">
                  <strong>{t("ToolsView.fec_cali")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecCali"
                    value={form.fecCali || ""}
                    onChange={handleChangeForm} />
                </div>
              </div>

              {/* FILA 8 */}
              <div className="row-flex">
                {/* FECHA DE CALIBRACION */}
                <div className="col-7">
                  <strong>{t("ToolsView.period_cali")}</strong>
                </div>
                <div className="col-3">
                  <select
                    name="idPer"
                    value={form.idPer || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    <option value="month">{t("ToolsView.mes")}</option>
                    <option value="year">{t("ToolsView.anio")}</option>
                  </select>
                </div>
                {/* <div className="col-7">
                  <strong>{t("ToolsView.fec_cali")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecCali"
                    value={form.fecCali || ""}
                    onChange={handleChangeForm} />
                </div> */}
                {/* PERIODO DE CALIBRACION */}
                <div className="col-7">
                  <strong>{t("ToolsView.valor")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="valor"
                    value={form.valor || ""}
                    onChange={handleChangeForm} />
                </div>
              </div>

              {/* FILA 9 */}
              <div className="row-flex">
                {/* FECHA DE REVISION */}
                <div className="col-7">
                  <strong>{t("ToolsView.fec_rev")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-date-styles"
                    type="date"
                    name="fecRevi"
                    value={form.fecRevi || ""}
                    onChange={handleChangeForm} />
                </div>
                {/* PERIODO DE REVISION */}
                <div className="col-7"><strong>{t("ToolsView.period_revi")}</strong></div>
                <div className="col-3">
                  <select
                    name="periodRevi"
                    value={form.periodRevi || ""}
                    onChange={handleChangeForm}>
                    <option value=""></option>
                    <option value="1">{t("ToolsView.mensual")}</option>
                    <option value="2">{t("ToolsView.bimestral")}</option>
                    <option value="3">{t("ToolsView.trimestral")}</option>
                    <option value="4">{t("ToolsView.semestral")}</option>
                    <option value="5">{t("ToolsView.anual")}</option>
                  </select>
                </div>
              </div>

              {/* FILA 10 */}
              <div className="row-flex">
                {/* UBICACION */}
                <div className="col-7">
                  <strong>{t("ToolsView.location")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-text-control"
                    type="text"
                    name="ubicacion"
                    value={form.ubicacion || ""}
                    onChange={handleChangeForm}
                    placeholder={lang === "en" ? "Where is the tool location" : "Dónde está ubicada la herramienta?"} />
                </div>
                {/* OBSERVACIONES */}
                <div className="col-7">
                  <strong>{t("ToolsView.observa")}</strong>
                </div>
                <div className="col-3">
                  <textarea
                    className="textarea-description"
                    name="observa"
                    value={form.observa || ""}
                    onChange={handleChangeForm}
                    placeholder={lang === "en" ? "Any observations? write it here.!" : "Tiene alguna observación? Coloquela aquí.!"} />
                </div>
              </div>

              {/* botones de accion */}
              <div className="row-flex m-y-small j-content-space-around">
                <button type="button" className="btn-primary" onClick={handleSave}>{t("ToolsView.guardar")}</button>
                <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
              </div>

              {/* FILA 11 */}
              {
                codHerr && (
                  <>
                    <div className="row-flex">
                      <div className="col-7">
                        <strong>{t("ToolsView.cargar_docs")}</strong>
                      </div>
                      <div className="col-3">
                        <button type="button" className="btn-docs" onClick={handleOpenCloseDocs}>
                          <Folder />
                        </button>
                      </div>
                    </div>
                    <div className="row-flex">
                      {/* FOTO */}
                      <div className="col-7">
                        <strong>{t("ToolsView.foto")}</strong>
                      </div>
                      <div className="col-3">
                        {
                          tool && tool[0]?.Foto && (
                            <button type="button" className="btn-link-tag" onClick={handleOpenClosePhoto}>{t("ToolsView.ver_imag")}</button>
                          )
                        }
                      </div>
                      <div className="col-7">
                        <strong>{t("ToolsView.cert")}</strong>
                      </div>
                      <div className="col-3">
                        <button type="button" className="btn-link-tag" onClick={handleCloseOpenListDocs}>{t("ToolsView.ver_cert")}</button>
                      </div>
                    </div>
                  </>
                )
              }


              {/* FILA 12 */}
              <div className="row-flex">
                <div className="col-7">
                  <strong>{t("ToolsView.activo")}</strong>
                </div>
                <div className="col-3">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    name="activo"
                    checked={form.activo || false}
                    onChange={handleChangeForm} />
                </div>
                {/* HISTORIAL */}
                {
                  codHerr && (
                    <div className="col-9" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <strong>{t("ToolsView.historial")}</strong>
                      <button type="button" style={{ cursor: "pointer", border: "none", outline: "none", backgroundColor: "transparent" }} onClick={() => navigate(`/warehouse/tools-detail/history/${codHerr}`)}>
                        <svg style={{ boxShadow: "var(--box-shadow-bottom)" }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="var(--first-color)" className="bi bi-journal-text" viewBox="0 0 16 16">
                          <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
                          <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                          <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                        </svg>
                      </button>
                    </div>
                  )
                }
                {
                  codHerr && (
                    <div className="flex-grow row-flex align-c gap-small flex-end">
                      <strong>{t("ToolsView.agregar_comp")}</strong>
                      <button type="button" style={{ cursor: "pointer", border: "none", outline: "none", backgroundColor: "transparent" }} onClick={handleOpenCloseAddComp}>
                        <Add fillP="var(--first-color)" />
                      </button>
                    </div>
                  )
                }
              </div>

              {
                codHerr && (
                  <div className="row-flex">
                    <div className="cont-icons width-cont-icons">
                      <div className="counter-not">{countComp && countComp[0]?.Total}</div>
                      <button type="button" onClick={handleOpenCloseListComp}>
                        <ToolIcon />
                      </button>
                      <h5>{t("ToolsView.componentes")}</h5>
                    </div>
                  </div>
                )
              }

              {/* FILA */}
              {
                codHerr && (
                  <div className="row-flex j-content-space-b">
                    {/* ingresado por */}
                    <div className="col-8">
                      <strong>{t("ToolsView.ingre_por")}</strong>
                    </div>
                    <div className="col-3">
                      <span>{form.nomEmpIng}</span>
                    </div>
                    {/* modificado por */}
                    <div className="col-8">
                      <strong>{t("ToolsView.mod_por")}</strong>
                    </div>
                    <div className="col-3">
                      <span>{form.empMod}</span>
                    </div>
                    {/* fecha ingreso */}
                    <div className="col-8">
                      <strong>{t("ToolsView.fec_ingr")}</strong>
                    </div>
                    <div className="col-3">
                      <span>{renderDate(form.fecIng, lang)}</span>
                    </div>
                    {/* fecha modificacion */}
                    <div className="col-8">
                      <strong>{t("ToolsView.fec_mod")}</strong>
                    </div>
                    <div className="col-3">
                      <span>{renderDate(form.fecMod, lang)}</span>
                    </div>
                  </div>
                )
              }

              {
                state.showModalSave && (
                  <ModalConfirm question={state.questionAction} sending={state.sending} onClose={handleOpenClose} />
                )
              }
            </form>
          )
        }

        {
          state.modalComp && (
            <AddCompTool onClose={handleOpenCloseAddComp} lang={lang} CodHerr={codHerr} onReload={handleReloadData} />
          )
        }

        {
          state.modalList && (
            <ListCompTool CodHerr={codHerr} lang={lang} onClose={handleOpenCloseListComp} />
          )
        }
        {
          state.modalPhoto && (
            <ViewPhotoTool Photo={tool[0]?.Foto} onClose={handleOpenClosePhoto} />
          )
        }
        {
          state.modalDocs && (
            <UploadDocsTools onClose={handleOpenCloseDocs} CodHerr={codHerr} onReload={handleReloadData} />
          )
        }
        {
          state.listDocs && (
            <ListDocsTools onClose={handleCloseOpenListDocs} CodHerr={codHerr} lang={lang} />
          )
        }
      </div>
    </>
  );
};