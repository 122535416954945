
import React, { useEffect, useState } from "react";

// COMPONENTS IMPORTATIONS
import { Error403View } from "../error-403-view/Error403View.jsx";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Error500Comp } from "../../components/errors/Error500Comp.jsx";
import { ToastNotification } from "../../components/toast-notification/ToastNotification.jsx";

// REACT ROUTER DOM IMPORTATION
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATION
import { urls } from "../../api-urls/api-urls";

// FUNCTION GLOBAL
import { getTheToken, renderDate } from "../../global-functions/globalFunctions.js";

// REACT translation
import { useTranslation } from "react-i18next";


export const TechnicalApprovalsDetail = () => {

  // language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // React language
  const [t] = useTranslation("global");

  // location pathname
  const location = useLocation();

  // Navigate
  const navigate = useNavigate();

  // useParams
  const { codSolRepuesto } = useParams();


  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data del servidor
  const [partes, setPartes] = useState([]); // estado para cargar las partes y poder modificar su informacion
  const [controller, setController] = useState(null); // estado para cargar el controlador
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false });
  const [notification, setNotification] = useState({ show: false, msg: "", errorNoti: false, great: false, success: false });
  const [stateForm, setStateForm] = useState({ proSuge: "", action: "", comment: "", btn: 0 });
  const [questionAction, setQuestionAction] = useState("");

  // Etapas
  const APRO_TECNI = 11; // Etapa Aprobacion tecnica
  const CULMINADO = 99; // Etapa Culminado


  // DESTRUCTURING DATA SERVER
  const { solRepuesto, detSolRepuesto, employeePermission } = dataServer && dataServer;

  // users allows to see this data
  const userAllows = [7, 3, 6, 1]; // 3 si es gerente


  // Funcion para abrir el modal de cuando se de click en aprobar partes
  const handleApprParts = () => {
    if (stateForm.action === "") { // debe seleccionar una accion
      return setNotification((val) => ({ ...val, show: true, msg: "Debe seleccionar una acción", errorNoti: true }));
    }

    setState((val) => ({ ...val, showModal: true }));
    if (stateForm.action === "0") { // quiere decir que va a cotizacion
      setQuestionAction("Está seguro que desea enviar a aprobación administrativa?");
    } else { // va a orden de compra (aprobacion de soporte)
      setQuestionAction("Está seguro que desea enviar a orden de compra?");
    }
  };


  // useEffect para traer la data del servidor
  useEffect(() => {
    const fetchDataServer = async () => {
      const abortController = new AbortController();

      setController(abortController);

      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.technicalApproval}/${codSolRepuesto}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token },
          signal: abortController.signal
        });

        // console.clear();

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        } else if (response.status === 401) {
          localStorage.setItem("lastNav", location.pathname);
          localStorage.removeItem("userInfo");
          return navigate("/");
        } else if (response.status === 403) {
          setState((val) => ({ ...val, dataVisible: false, unauthorized: true }));
        } else if (response.status === 500) {
          // ocurrio un error en el servidor
          setState((val) => ({ ...val, serverError: true }));
        }
        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    }
  }, [language, codSolRepuesto]);


  // useEffect para cargar la data en el estado de las partes
  useEffect(() => {
    if (detSolRepuesto && detSolRepuesto.length > 0) {
      setPartes(detSolRepuesto);
    }
  }, [detSolRepuesto]);


  // Funcion para cambiar la cantidad de lo que se aprueba
  const handleApproval = (index, newVal) => {
    if (isNaN(newVal)) {
      return;
    }
    const updatePart = [...partes];
    updatePart[index].Cantidad = newVal;

    setPartes(updatePart);
  };

  // Funcion para cambiar la etapa del repuesto
  const onHandleDelete = (indexVal) => {
    const updateIdEtapa = [...partes];
    updateIdEtapa[indexVal].IdEtapa = CULMINADO;

    setPartes(updateIdEtapa);

    setNotification((val) => ({ ...val, show: true, msg: t("RequestPartsView.msg_elimi_repuest"), great: true, success: false }));
  };

  // Funcion para cambiar Devolver
  const onHandleDevolver = (index, newVal) => {
    const updateDevolver = [...partes];
    updateDevolver[index].Devolver = newVal;

    setPartes(updateDevolver);
  };

  // console.log(partes)


  // Boton de aprobación
  const onApprovalSubmit = async (event) => {
    event.preventDefault();

    if (!stateForm.action) {
      return setNotification((val) => ({ ...val, show: true, msg: t("RequestPartsView.sele_accion"), errorNoti: true }));
    }

    const finalObj = { stateForm, partes };

    try {
      const response = await fetch(`${urls.technicalApproval}/${codSolRepuesto}/search?language=${language}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true, success: true }));
        setState((val) => ({ ...val, showModal: false }));
      }

    } catch (error) {
      console.log(error);
    }
  };

  // usEffect para redireccionar a la venta principal de las aprobaciones
  useEffect(() => {
    if (notification.success) {
      const timer = setTimeout(() => {
        navigate("/warehouse/technical-approval");
      }, 6400);

      return () => {
        clearTimeout(timer);
      }
    }
  }, [notification.success]);

  // console.log(partes)


  // UseEffect para limpiar las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", errorNoti: false, great: false, success: false });
    }, 6500);

    return () => {
      clearTimeout(timer);
    }
  }, [notification.show]);

  return (
    <>
      {state.unauthorized && (<Error403View />)}
      {state.serverError && (<Error500Comp />)}
      {state.isLoading && (<LoadingComp />)}
      {/* Componente para notificacion */}
      {notification.show && (<ToastNotification success={notification.great} error={notification.errorNoti} msg={notification.msg} />)}
      {
        state.dataVisible && (
          <>
            <div className="container display-data">
              <form onSubmit={onApprovalSubmit}>
                {/* FILA 1 */}
                <div className="row-flex">
                  {/* SOLICITUD */}
                  <div className="col-8"><strong>{t("RequestPartsView.soli")}</strong></div>
                  <div className="col-7"><h3 style={{ color: 'var(--first-color)' }}>{solRepuesto && solRepuesto[0]?.CodSolRepuesto}</h3></div>
                  {/* ID SERVICIO */}
                  <div className="col-8"><strong>{t("RequestPartsView.id_serv")}</strong></div>
                  <div className="col-8"><Link style={{ fontSize: '1rem' }} className="link-tag" to={`/service/service-call/${solRepuesto && solRepuesto[0]?.CodServicio}`}>{solRepuesto && solRepuesto[0]?.CodServicio}</Link></div>
                  {/* MODELO */}
                  <div className="col-10"><strong>{t("RequestPartsView.modelo")}</strong></div>
                  <div className="col-7"><span>{solRepuesto && solRepuesto[0]?.equipment[0]?.Modelo}</span></div>
                </div>

                {/* FILA 2 */}
                <div className="row-flex">
                  {/* SERIAL */}
                  <div className="col-8"><strong>Serial</strong></div>
                  <div className="col-7"><span>{solRepuesto && solRepuesto[0]?.equipment[0]?.Serial}</span></div>
                  {/* FECHA */}
                  <div className="col-8"><strong>{t("RequestPartsView.fecha")}</strong></div>
                  <div className="col-8"><span>{solRepuesto && renderDate(solRepuesto[0]?.FecIngreso, language)}</span></div>
                  {/* INSTALACION */}
                  <div className="col-10"><strong>{t("RequestPartsView.instala")}</strong></div>
                  <div className="col-7"><span>{solRepuesto && renderDate(solRepuesto[0]?.equipmentExtraInfo[0]?.FecInstalacion, language)}</span></div>
                </div>

                {/* FILA 3 */}
                <div className="row-flex">
                  {/* CLIENTE */}
                  <div className="col-8"><strong>{t("RequestPartsView.cliente")}</strong></div>
                  <div className="col-49-17">
                    <Link className="link-tag" to={`/customers/customer-detail/${solRepuesto && solRepuesto[0]?.CodCliente}`}>{solRepuesto && solRepuesto[0]?.nomCliente}</Link>
                  </div>
                  {/* GON */}
                  <div className="col-7"><strong>GON</strong></div>
                  <div className="col-8"><span>{solRepuesto && solRepuesto[0]?.equipmentExtraInfo[0]?.FDO}</span></div>
                </div>

                {/* FILA 4 */}
                <div className="row-flex">
                  {/* PROVEEDOR */}
                  <div className="col-8"><strong>{t("RequestPartsView.supli")}</strong></div>
                  <div className="col-49-17"><span>{solRepuesto && solRepuesto[0]?.nomProveedor}</span></div>
                  {/* O/C */}
                  <div className="col-7"><strong>O/C</strong></div>
                  <div className="col-8"><span></span></div>
                </div>

                {/* FILA 5 */}
                <div className="row-flex">
                  {/* EQUIPO */}
                  <div className="col-8"><strong>{t("RequestPartsView.equip")}</strong></div>
                  <div className="col-49-17" style={{ overflowX: 'auto' }}><Link className="link-tag" to={`/equipments/equipment-detail/${solRepuesto && solRepuesto[0]?.CodEquipo}`}>{solRepuesto && solRepuesto[0]?.equipment[0]?.Linea} - {solRepuesto && solRepuesto[0]?.equipment[0]?.Modalidad} - {solRepuesto && solRepuesto[0]?.equipment[0]?.TipEquipo} - {solRepuesto && solRepuesto[0]?.equipment[0]?.Modelo} - {solRepuesto && solRepuesto[0]?.equipment[0]?.Serial} - {solRepuesto && solRepuesto[0]?.equipment[0]?.Marca} - {solRepuesto && solRepuesto[0]?.equipment[0]?.NumSistema}</Link></div>
                  {/* CONTRATO */}
                  <div className="col-7"><strong>{t("RequestPartsView.contr")}</strong></div>
                </div>

                {/* FILA 6 */}
                <div className="row-flex">
                  {/* TECNICO */}
                  <div className="col-8"><strong>{t("RequestPartsView.ing_tec")}</strong></div>
                  <div className="col-4"><span>{solRepuesto && solRepuesto[0]?.nomEmployee}</span></div>
                  {/* FALLA */}
                  <div className="col-8"><strong>{t("RequestPartsView.falla")}</strong></div>
                  <div className="col-3" style={{ overflowX: 'auto' }}><span style={{ textDecoration: 'underline', color: 'var(--danger-color)', textTransform: 'capitalize' }}>{solRepuesto && solRepuesto[0]?.Falla}</span></div>
                </div>

                {/* FILA 7 */}
                <div className="row-flex">
                  {/* ULTIMA ACCION */}
                  <div className="col-8"><strong>{t("RequestPartsView.ult_accion")}</strong></div>
                  <div className="col-87"><span style={{ textTransform: 'capitalize', textDecoration: 'underline', color: 'var(--green-color)', fontWeight: 'bold' }}>{solRepuesto && solRepuesto[0]?.lastComment}</span></div>
                </div>

                {/* FILA 8 */}
                <div className="row-flex">
                  {/* PROVEEDOR SUGERIDO */}
                  <div className="col-8"><strong>{t("RequestPartsView.prov_suge")}</strong></div>
                  <div className="flex-auto">
                    <textarea
                      className="textarea-description"
                      name="proveedorSugerido"
                      value={stateForm.proSuge}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, proSuge: target.value }))}
                      placeholder={language === "en" ? "If you have a supplier write it here..." : "Si tiene un proveedor escribalo aquí..."} />
                  </div>
                </div>

                {/* FILA 9 */}
                {
                  employeePermission && employeePermission[0]?.CodPerfil === 1 && employeePermission[0]?.Coordinador && (
                    <div className="row-flex">
                      {/* ACCION */}
                      <div className="col-8"><strong>{t("RequestPartsView.accion")}</strong></div>
                      <div className="col-4">
                        <select
                          name="accion"
                          value={stateForm.action}
                          onChange={({ target }) => setStateForm((val) => ({ ...val, action: target.value }))}>
                          <option value=""></option>
                          <option value="0">{t("RequestPartsView.coti")}</option>
                          <option value="1">{t("RequestPartsView.orden_compra")}</option>
                        </select>
                      </div>
                    </div>
                  )
                }

                {/* FILA 10 */}
                <div className="row-flex m-y-small">
                  {/* COMENTARIO */}
                  <div className="col-8"><strong>{t("RequestPartsView.comentario")}</strong></div>
                  <div className="flex-auto">
                    <textarea
                      className="textarea-description"
                      name="comentario"
                      value={stateForm.comment}
                      onChange={({ target }) => setStateForm((val) => ({ ...val, comment: target.value }))}
                      placeholder={language === "en" ? "Comments..." : "Comentarios..."} />
                  </div>
                </div>

                {/* TABLA PARA MOSTRAR LAS PARTES */}
                <div className="table-container-mobile">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>Act</th>
                        <th>#</th>
                        <th>{t("TableParts.canti")}</th>
                        <th>{t("TableParts.existe")}</th>
                        <th>{t("TableParts.parte")}</th>
                        <th>{t("TableParts.descrip")}</th>
                        <th className="col-9">{t("TableParts.cant_apro")}</th>
                        <th className="col-9">{t("TableParts.devolver_almac")}</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        partes && partes.map((part, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: 'center' }}>
                              <button type="button" className={`btn-del ${part.IdEtapa === 99 ? "disabled-class" : ""}`} onClick={() => onHandleDelete(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                </svg>
                              </button>
                            </td>
                            <td style={{ textAlign: 'center' }}>{part.CodDetSolRepuesto}</td>
                            <td style={{ textAlign: 'center' }}>{part.Cantidad}</td>
                            <td></td>
                            <td><Link className="link-tag">{part.NumParte}</Link></td>
                            <td>
                              <span className={part.IdEtapa === 99 ? "text-deco-through" : ""}>{part.Descripcion}</span>
                            </td>
                            <td>
                              <input
                                style={{ textAlign: 'center' }}
                                className="input-text-control"
                                type="text"
                                name={`cantAprob-${part.CodDetSolRepuesto}`}
                                value={part.Cantidad}
                                onChange={({ target }) => handleApproval(index, target.value)} />
                            </td>
                            <td>
                              <select name={`devol-${part.CodDetSolRepuesto}`} value={part.Devolver} onChange={({ target }) => onHandleDevolver(index, target.value)}>
                                <option value="1">{t("RequestPartsView.si")}</option>
                                <option value="0">No</option>
                              </select>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Botones de accion */}
                <div className="row-flex m-y-medium j-content-space-around">
                  {
                    employeePermission && employeePermission[0]?.CodPerfil === 1 && employeePermission[0]?.Coordinador && (
                      <>
                        <button type="button" className="btn-primary" onClick={handleApprParts}>{t("RequestPartsView.aprobar")}</button>
                        <button className="btn-warning">{t("RequestPartsView.devol")}</button>
                        <button className="btn-danger">{t("RequestPartsView.elimi")}</button>
                      </>
                    )
                  }
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
                </div>

                {/* Modal para confirmar si está seguro de que quiere enviar el formulario */}
                {
                  state.showModal && (
                    <div className="background-modal" onClick={() => setState((val) => ({ ...val, showModal: false }))}>
                      <div className="container-modal-confirm" onClick={(event) => event.stopPropagation()}>
                        {/* Question mark */}
                        <div className="row-flex m-y-small align-c justify-center">
                          <div className="question-mark-container">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z" />
                            </svg>
                          </div>
                        </div>
                        <h5 style={{ textAlign: 'center', color: 'var(--first-color)' }}>{questionAction}</h5>
                        {/* Botones de accion */}
                        <div className="row-flex m-y-medium j-content-space-around">
                          <button type="submit" className="btn-success">{t("RequestPartsView.si")}</button>
                          <button type="button" className="btn-danger" onClick={() => setState((val) => ({ ...val, showModal: false }))}>No</button>
                        </div>
                      </div>
                    </div>
                  )
                }

              </form>

            </div>
          </>
        )
      }
    </>
  );
};