// componente para seleccionar los equipos del fmi

import { useState, useEffect, useContext } from "react";

// components importation
import { Close } from "../icons/AppIcons.jsx";
import { getTheToken } from "../../global-functions/globalFunctions.js";

// react router dom
import { useNavigate } from "react-router-dom";


// context importation
import { NotificationContext } from "../../context/NotificationContext.jsx";




export const SelectFMIEquipment = ({ onClose, UrlGet = "", UrlPost = "" }) => {


  // state definition
  const [controller, setController] = useState(null);
  const [equipment, setEquipment] = useState([]);
  const [form, setForm] = useState({ customer: "", sid: "", model: "", sid: "" });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // filtro para la busqueda
  const EquipmentFilter = equipment && equipment.filter((eqp) => {
    return (
      eqp.NomCliente?.toLowerCase().includes(form.customer?.toLowerCase() || "") &&
      eqp.Modelo?.toLowerCase().includes(form.model?.toLowerCase() || "") &&
      (eqp.NumSistema?.toLowerCase().includes(form.sid?.toLowerCase() || "") || eqp.Serial?.toLowerCase().includes(form.sid?.toLowerCase() || ""))
    );
  });

  // funcion para cambiar el valor de select
  const handleChangeSelected = (CodEqp = 0, newVal = false) => {
    setEquipment((val) => {
      return val.map((eqp) => {
        if (eqp.CodEquipo === CodEqp) {
          return {
            ...eqp,
            Selected: newVal
          }
        }
        return eqp;
      });
    });
  };

  // funcion para manejar el formulario
  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setForm((val) => ({ ...val, [name]: value }));
  };


  // funcion para enviar el formulario
  const handleSubmitForm = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(UrlPost, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(equipment)
      });

      const data = await response.json();

      if (response.status === 201) {
        handleNotify(true, data?.msg, false, true);
        onClose("reload", true); // recargar el fmi
        onClose("modalEqp", false);
      }

    } catch (err) {
      console.log(err);
    }
  };


  // funcion para realizar el fetch
  const fetchDataServ = async () => {

    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setIsLoading(true);
      const response = await fetch(UrlGet, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setEquipment(data?.equipments);
      } else if (response.status === 401) {
        return navigate("/");
      }

      setIsLoading(false);

    } catch (err) {
      console.log(err);
    }
  };

  // useEffect para realizar la peticioon
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);




  return (
    <div className="background-modal">
      <form onSubmit={handleSubmitForm} className="container-select-customer">
        <div className="modal-header">
          <h4>Seleccionar equipos para el FMI</h4>
          <button type="reset" className="btn-close" onClick={() => onClose("modalEqp", false)}>
            <Close />
          </button>
        </div>
        <div className="modal-body">

          <div className="row-flex">
            <div className="col-7">
              <strong>Cliente</strong>
            </div>
            <div className="col-80 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="customer"
                value={form.customer}
                onChange={handleChangeForm} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>Modelo</strong>
            </div>
            <div className="col-80 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="model"
                value={form.model}
                onChange={handleChangeForm} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-7">
              <strong>SID/Serial</strong>
            </div>
            <div className="col-80 flex-grow">
              <input
                className="input-text-control"
                type="text"
                name="sid"
                value={form.sid}
                onChange={handleChangeForm} />
            </div>
          </div>

          {
            isLoading && (
              <div className="row-flex">
                <div className="col-80">
                  <strong>Cargando...</strong>
                </div>
              </div>
            )
          }

          {
            EquipmentFilter && EquipmentFilter.map((eqp) => (
              <div key={eqp.CodEquipo} className="row-flex border-bottom hover-class">
                <div className="col-80">
                  <p>
                    <strong>{eqp.NomCliente}: </strong> <span>{eqp.NomEquipo}</span>
                  </p>
                </div>
                <div className="col-5 row-flex flex-end">
                  <input
                    className="input-control-checkbox"
                    type="checkbox"
                    checked={eqp.Selected}
                    onChange={({ target }) => handleChangeSelected(eqp.CodEquipo, target.checked)} />
                </div>
              </div>
            ))
          }

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-primary">Guardar</button>
          <button type="reset" className="btn-secondary" onClick={() => onClose("modalEqp", false)}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};